// @flow
import React, { Component } from "react";
//Style
import "./DistributionCenterDropDown.css";

type Props = {
  distributionCenter: string,
  disabled?: boolean,
  handleUpdateFulfillment: (distributionCenter: string) => void,
  defaultDistributionCenter: String
};

class DistributionCenterDropDown extends Component {
  props: Props;

  componentDidMount() {
    if (
      this.props.defaultDistributionCenter &&
      !this.props.distributionCenter
    ) {
      this.props.handleUpdateFulfillment({
        distributionCenter: this.props.defaultDistributionCenter
      });
    }
  }

  render() {
    return (
      <div className="drop-down-distribution-center">
        <div>Centro</div>
        <select
          disabled={this.props.disabled}
          value={
            this.props.distributionCenter ||
            this.props.defaultDistributionCenter
          }
          onChange={e => {
            if (
              e.target.value
                ? window.confirm(
                    `Tem certeza que gostaria de selecionar o centro ${e.target.value.toUpperCase()}?`
                  )
                : window.confirm(
                    `Tem certeza que deseja remover o centro ${this.props.distributionCenter.toUpperCase()}?`
                  )
            ) {
              this.props.handleUpdateFulfillment({
                distributionCenter: e.target.value
              });
            }
          }}
        >
          <option value="">--</option>
          <option value="portao">Portão</option>
          <option value="pituba">Pituba</option>
        </select>
      </div>
    );
  }
}

export default DistributionCenterDropDown;
