// @flow
import React from "react";
// Components
import { Button } from "../../common";
// Types

//Style
import "./EditOrResetInput.css";

type Props = {
  loading: boolean,
  onSubmit: (value: string) => void,
  onReset: () => void
};

type State = {
  editing: boolean,
  value: string
};

const initialState = {
  editing: false,
  value: ""
};

class EditOrResetInput extends React.Component {
  state: State;
  handleConfirm: (onConfirm: () => void) => void;

  constructor(props: Props) {
    super(props);

    this.state = initialState;
  }

  handleConfirm(onConfirm: () => void) {
    const decision = window.confirm(
      "Tem certeza que deseja confirmar essa ação?"
    );

    if (decision) {
      onConfirm();
    }
  }

  render() {
    if (this.state.editing) {
      return (
        <div className="edit-or-reset-main-form">
          <form
            onSubmit={e => {
              e.preventDefault();
              this.props.onSubmit(this.state.value);
            }}
          >
            <input
              type="text"
              className=""
              placeholder="Ex: 2500"
              value={this.state.value}
              onChange={e => this.setState({ value: e.target.value })}
            />
            <Button
              icon="fa fa-floppy-o"
              text=""
              type="submit"
              buttonType="button-tertiary"
              loading={this.props.loading}
            />
          </form>

          <Button
            icon="fa fa-times"
            text=""
            buttonType="button-tertiary"
            onClick={() => this.setState({ ...initialState })}
          />
        </div>
      );
    }
    return (
      <div className="edit-or-reset-main">
        <Button
          icon="fa fa-pencil-square-o"
          text=""
          buttonType="button-tertiary"
          onClick={() => this.setState({ editing: true })}
        />
        <Button
          icon="fa fa-undo"
          text=""
          buttonType="button-secondary"
          loading={this.props.loading}
          onClick={() => this.handleConfirm(this.props.onReset)}
        />
      </div>
    );
  }
}

export default EditOrResetInput;
