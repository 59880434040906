import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
// Components
import VisitItem from "../containers/VisitItem";
import { Droppable } from "react-beautiful-dnd";
import { scroller } from "react-scroll";

//Style
import "./VisitList.css";

const VisitItemMemo = React.memo(VisitItem);

const VisitsList = ({
  unroutedVisits,
  visitLoading,
  selectedVisitId,
  selectedVisitsIds,
  timeWindowById,
  handleVisitClick,
  autoRoutingInProgress,
}) => {
  useEffect(() => {
    if (
      unroutedVisits
        .map((visit) => visit.id)
        .findIndex((id) => id === selectedVisitId) > -1
    ) {
      scroller.scrollTo(`visit-scroll-${selectedVisitId}`, {
        duration: 500,
        smooth: "easeInOutQuart",
        containerId: "scrollable-visit-list",
        offset: -150,
        isDynamic: true,
      });
    }
  }, [selectedVisitId, unroutedVisits]);

  const sortedVisits = React.useMemo(() => {
    return _.sortBy(unroutedVisits, (visit) =>
      visit.timeWindow
        ? timeWindowById(visit.timeWindow).endTime
        : moment().format()
    );
  });

  return (
    <>
      <div className="visit-list-main" id="scrollable-visit-list">
        <Droppable droppableId="visit-list">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} style={{ minHeight: "200px" }}>
              {unroutedVisits.length === 0 ? (
                <p className="visit-list-alert-no-unrouted-visit">
                  Nenhuma visita para roterizar
                </p>
              ) : (
                sortedVisits.map((visit, index) => (
                  <VisitItemMemo
                    key={visit.id}
                    index={index}
                    visitId={visit.id}
                    selectedVisitId={selectedVisitId}
                    isAvailableUpdate={true}
                    isSelectedToVirtualVisit={
                      selectedVisitsIds && selectedVisitsIds.includes(visit.id)
                    }
                    onVisitClick={() => handleVisitClick([visit.id])}
                    autoRoutingInProgress={autoRoutingInProgress}
                  />
                ))
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </>
  );
};

VisitsList.propTypes = {
  unroutedVisits: PropTypes.array,
  visitLoading: PropTypes.bool,
  selectedVisitId: PropTypes.number,
  timeWindowById: PropTypes.func.isRequired,
};

export default VisitsList;
