// @flow
import React from "react";
// Components
import FulfillmentSortItem from "./FulfillmentSortItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

// Types
import type { Fulfillment } from "../../types";
import type { DropResult } from "react-beautiful-dnd";
//Styles
import "./FulfillmentSortList.css";

type Props = {
  fulfillments: Fulfillment[],
  loading: boolean,
  updatingLoading: boolean,
  handleUpdate: (orderNumber: string, desiredPosition: number) => void
};

class FulfillmentSortList extends React.PureComponent {
  constructor(props: Props) {
    super(props);

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // If the position has not changed no need to send to server
    if (result.destination.index === result.source.index) {
      return;
    }

    // We should compute the new position and send that over to the server
    const order = this.props.fulfillments[result.source.index];

    this.props.handleUpdate(order.number, result.destination.index + 1);
  };

  render() {
    if (this.props.loading) {
      return (
        <div className="fulfillment-sort-list-loader">
          <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          <div>Carregando...</div>
        </div>
      );
    }
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className="fulfillment-sort-list-main">
          {/* Update loader */}
          {this.props.updatingLoading ? (
            <div className="fulfillment-sort-list-blocking-loader-main">
              <div className="fulfillment-sort-list-blocking-loader-sub">
                <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                <div>Atualizando ordem...</div>
              </div>
            </div>
          ) : null}
          {/* If we have no fulfillments */}
          {this.props.fulfillments.length === 0 ? (
            <div>Sem pedidos na fila de preparação...</div>
          ) : null}
          <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
              <div ref={provided.innerRef}>
                {this.props.fulfillments.map((f, index) => (
                  <FulfillmentSortItem
                    key={f.number}
                    index={index}
                    fulfillment={f}
                    isDragDisabled={this.props.updatingLoading}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    );
  }
}

export default FulfillmentSortList;
