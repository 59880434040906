// @flow
import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import _ from 'lodash';
// Components
import { Button } from '../../common';
import LineItemList from '../components/LineItemList';
import FulfillmentEditor from '../components/FulfillmentEditor';
import FulfillmentVersions from './FulfillmentVersions';
import AddressItem from '../components/AddressItem';
import { IssueSourcesGetter } from '../../issue-sources';
import { UserFraud, OrderFraud } from '../../fraud';
import ObservationItem from '../components/ObservationItem';
import UserNotes from '../containers/UserNotes';
import FulfillmentStatistics from '../components/FulfillmentStatistics';
import { UnauthenticatedRedirect } from '../../employee';
// Composers
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// Import Actions
import {
  findFulfillment,
  findTracker,
  editFulfillment,
  createRouterVisit,
  downloadSalesXmlRequest,
  downloadSalesReceiptRequest,
  editOrderObservation,
  editAddress,
} from '../actions';
// Selectors
import {
  lineItemById,
  mixByIdWithLineItems,
  fulfillmentByIdSelector,
  updatingfulfillmentLoadingSelector,
  creatingRouterVisitUpdatingSelector,
  fulfillmentAddressByFulfillmentId,
  editingOrderObservationLoadingSelector,
  editingAddressLoadingSelector,
  fulfillmentByIdAndIndexShiftSelector,
  trackerSelector,
} from '../selectors';
// Types
import type {
  Fulfillment,
  UpdatableFulfillment,
  UpdatableAddress,
  LineItem,
  Mix,
} from '../types';
import type { Dispatch } from '../../common';
// Styles
import './FulfillmentPage.css';

type Props = {
  fulfillment: Fulfillment,
  address: Address,
  handleFindFulfillment: () => void,
  handleUpdateFulfillment: (updatableParameters: UpdatableFulfillment) => void,
  handleDownloadSalesXmlRequest: () => void,
  handleDownloadSalesReceiptRequest: () => void,
  handleFindTracker: () => void,
  lineItemById: (lineItemId: number) => LineItem,
  mixById: (mixId: number) => Mix,
  // From router
  match: { params: { id: string } },
};

class FulfillmentPage extends React.Component {
  constructor(props: Props) {
    super(props);

    // We should dispatch the action to get fulfillments
    props.handleFindFulfillment();
    props.handleFindTracker();
  }

  // If the ID of the ticket has changed we have to request the new fulfillment from the server
  componentDidUpdate(prevProps) {
    const currentSlug = this.props.match.params.id;
    const prevSlug = prevProps.match.params.id;
    if (currentSlug !== prevSlug) {
      this.props.handleFindFulfillment();
    }
  }

  render() {
    const fulfillment = this.props.fulfillment;
    // If we do not have a fulfillment
    if (!fulfillment) {
      return (
        <UnauthenticatedRedirect>
          <div> Sem pedido </div>
        </UnauthenticatedRedirect>
      );
    }

    const lineItems = fulfillment.lineItems
      ? fulfillment.lineItems.map(lineItemId =>
          this.props.lineItemById(lineItemId),
        )
      : [];

    const mixes = fulfillment.mixes
      ? fulfillment.mixes.map(mixId => this.props.mixById(mixId))
      : [];

    return (
      <div className="fulfillment-page-main">
        <IssueSourcesGetter />
        <h3>Pagina de Fulfillment do Pedido #{this.props.match.params.id}</h3>
        <div className="fulfillment-details">
          <div className="fulfillment-details-time-information">
            {fulfillment.startTime && fulfillment.endTime ? (
              <p>
                Limites de entrega:{' '}
                {moment(fulfillment.startTime)
                  .utcOffset('-0300')
                  .format('dddd, D [de] MMM [das] HH:mm')}{' '}
                às {moment(fulfillment.endTime).utcOffset('-0300').format('LT')}
                {'.'}
              </p>
            ) : (
              <p>
                Limite de entrega:{' '}
                {moment(fulfillment.deadline).utcOffset('-0300').fromNow()} (
                {moment(fulfillment.deadline).format('LLLL')})
              </p>
            )}
            {this.props.tracker && this.props.tracker.deliveredAt ? (
              <p
                className={`${
                  this.props.tracker.deliveredAt > fulfillment.endTime
                    ? 'fulfillment-page-red-color'
                    : ''
                }`}
              >{`Entregue: ${moment(this.props.tracker.deliveredAt)
                .utcOffset('-0300')
                .format('LLLL')}.`}</p>
            ) : null}
          </div>

          <div className="fulfillment-page-header-buttons">
            <div className="fulfillment-page-header-buttons-information">
              {this.props.fulfillment.saleReceiptUrl && (
                <Button
                  text="Baixar NF-e"
                  buttonType="button-tertiary fullfilment-page-header-button"
                  loading={false}
                  onClick={() =>
                    this.props.handleDownloadSalesReceiptRequest(
                      this.props.fulfillment.saleReceiptUrl,
                    )
                  }
                ></Button>
              )}
              <Button
                text="Baixar XML"
                buttonType="button-tertiary fullfilment-page-header-button"
                loading={false}
                onClick={() => this.props.handleDownloadSalesXmlRequest()}
              />
            </div>

            <div className="fulfillment-page-arrow-container">
              {this.props.fulfillmentByIdAndIndexShift(-1) ? (
                <Link
                  to={`/fulfillments/${this.props.fulfillmentByIdAndIndexShift(
                    -1,
                  )}`}
                >
                  <i
                    className="fa fa-arrow-left fa-lg fulfillment-page-arrows"
                    title="Pedido anterior na lista."
                  />
                </Link>
              ) : (
                <i
                  className="fa fa-arrow-left fa-lg fulfillment-page-arrows fulfillment-page-arrows-disabled"
                  title="Pedido anterior na lista."
                />
              )}

              {this.props.fulfillmentByIdAndIndexShift(1) ? (
                <Link
                  to={`/fulfillments/${this.props.fulfillmentByIdAndIndexShift(
                    1,
                  )}`}
                >
                  <i
                    className="fa fa-arrow-right fa-lg fulfillment-page-arrows"
                    title="Pedido anterior na lista."
                  />
                </Link>
              ) : (
                <i
                  className="fa fa-arrow-right fa-lg fulfillment-page-arrows fulfillment-page-arrows-disabled"
                  title="Pedido anterior na lista."
                />
              )}
            </div>
          </div>
        </div>

        <FulfillmentEditor
          fulfillment={fulfillment}
          handleUpdateFulfillment={this.props.handleUpdateFulfillment}
          handleResendToRouter={this.props.handleResendToRouter}
          updatingFulfillmentLoading={this.props.updatingFulfillmentLoading}
          creatingRouterVisitLoading={this.props.creatingRouterVisitLoading}
        />

        <LineItemList
          lineItems={lineItems}
          mixes={mixes}
          weightLimited={fulfillment.weightLimited}
          shouldPrintSaleReceipt={fulfillment.shouldPrintSaleReceipt}
        />

        <AddressItem
          address={this.props.address}
          orderNumber={this.props.match.params.id}
          onConfirmOrRejectPosition={updatableAddres =>
            this.props.editAddress(updatableAddres, this.props.address.id)
          }
          editingAddressLoading={this.props.editingAddressLoading}
        />

        <ObservationItem
          observation={this.props.fulfillment.observation}
          //order={this.props.fulfillment}
          loading={this.props.observationEditLoading}
          onSubmit={this.props.editOrderObservation}
        />

        <UserNotes userId={fulfillment.userId} />

        {fulfillment.state === 'payment_denied' ||
        fulfillment.state === 'payment_evaluating' ||
        fulfillment.state === 'error' ||
        fulfillment.state === 'canceled' ? (
          <div>
            <UserFraud orderNumber={fulfillment.number} />
            <OrderFraud orderNumber={fulfillment.number} />
          </div>
        ) : null}

        <FulfillmentStatistics fulfillment={fulfillment} />

        <FulfillmentVersions fulfillmentId={this.props.match.params.id} />
      </div>
    );
  }
}

const mapStateToProps = (state, props: Props) => {
  return {
    fulfillment: fulfillmentByIdSelector(state, props.match.params.id),
    updatingFulfillmentLoading: updatingfulfillmentLoadingSelector(state),
    creatingRouterVisitLoading: creatingRouterVisitUpdatingSelector(state),
    address: fulfillmentAddressByFulfillmentId(state, props.match.params.id),
    tracker: trackerSelector(state, props.match.params.id),
    observationEditLoading: editingOrderObservationLoadingSelector(state),
    editingAddressLoading: editingAddressLoadingSelector(state),
    lineItemById: lineItemId => lineItemById(state, lineItemId),
    mixById: mixId => mixByIdWithLineItems(state, mixId),
    fulfillmentByIdAndIndexShift: positionsToMove =>
      fulfillmentByIdAndIndexShiftSelector(
        state,
        props.match.params.id,
        positionsToMove,
      ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    handleFindFulfillment: () => {
      dispatch(findFulfillment(stateProps.match.params.id));
    },
    handleFindTracker: () => {
      dispatch(findTracker(stateProps.match.params.id));
    },
    handleUpdateFulfillment: (updatableParameters: UpdatableFulfillment) => {
      dispatch(
        editFulfillment(stateProps.match.params.id, updatableParameters),
      );
    },
    handleResendToRouter: (orderNumber: string) => {
      dispatch(createRouterVisit(orderNumber));
    },
    handleDownloadSalesXmlRequest: () => {
      dispatch(downloadSalesXmlRequest(stateProps.match.params.id));
    },
    handleDownloadSalesReceiptRequest: receiptUrl => {
      dispatch(
        downloadSalesReceiptRequest(stateProps.match.params.id, receiptUrl),
      );
    },
    editOrderObservation: (observation: string) => {
      dispatch(editOrderObservation(stateProps.match.params.id, observation));
    },
    editAddress: (updatableParameters: UpdatableAddress, id: number) => {
      dispatch(
        editAddress(stateProps.match.params.id, id, updatableParameters),
      );
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FulfillmentPage),
);
