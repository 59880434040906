// @flow
import React from "react";
// Components
import Login from "../components/Login";
import { Redirect } from "react-router-dom";
// Composers
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// Import Actions
import { login } from "../actions";
// Selectors
import {
  loggedInSelector,
  loggingInLoadingSelector,
  loginErrorsSelector
} from "../selectors";
// Types
import type { ContextRouter } from "react-router-dom";
import type { Dispatch } from "../../common";

type RouterProps = ContextRouter;
type Props = {
  loggedIn: boolean,
  isLoggingIn: boolean,
  loginErrors: {
    badUsername: boolean,
    badPassword: boolean
  },
  submitLogin: () => void
} & RouterProps;

class LoginPage extends React.Component {
  props: Props;
  render() {
    if (this.props.loggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/fulfillments",
            state: { message: "Você ja esta autenticado..." }
          }}
        />
      );
    }
    return (
      <Login
        submitLogin={this.props.submitLogin}
        isLoggingIn={this.props.isLoggingIn}
        loginErrors={this.props.loginErrors}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: loggedInSelector(state),
    isLoggingIn: loggingInLoadingSelector(state),
    loginErrors: loginErrorsSelector(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    submitLogin: (username, password) => {
      dispatch(login(username, password));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
