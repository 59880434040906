import { authGet } from "../../common/api";
import humps from "humps";
import { normalize } from "normalizr";
import * as schema from "./schema";

const IssueSources = {
  async getIndex() {
    const response = await authGet(`/issue_sources`);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.issueSources);
    return normalized;
  }
};

export default IssueSources;
