import React from "react";
import Slider from "react-slick";

import "./PlacementPhotoScroller.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PlacementPhotoScroller = ({ placementPhotos }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    arrows: true,
    swipe: true,
    vertical: false,
    lazyLoad: true,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  if (placementPhotos && placementPhotos.length) {
    return (
      <div className={"placement-photo-scroller-main"}>
        <Slider {...settings}>
          {placementPhotos.map((placementPhoto) => (
            <div
              key={placementPhoto.id}
              className={"placement-photo-scroller-element"}
            >
              <img src={placementPhoto.url} />
            </div>
          ))}
        </Slider>
      </div>
    );
  } else {
    return null;
  }
};

export default PlacementPhotoScroller;
