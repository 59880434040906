// @flow
import React from "react";
import moment from "moment";
import _ from "lodash";
// Components
import { LinePlot } from "../../common";
// Composers
import { connect } from "react-redux";
// Import Actions
import * as FraudAction from "../actions";
// Selectors
import * as FraudSelectors from "../selectors";
// Types
import type { Dispatch } from "../../common";
//Styles
import "./UserFraud.css";

type Props = {
  orderNumber: string,
  // From connector
  user: User,
  getUser: (orderNumber: string) => void
};

class UserFraud extends React.Component {
  constructor(props: Props) {
    super(props);

    if (props.orderNumber.length > 0) {
      props.getUser(props.orderNumber);
    }
    this.formatXAxis.bind(this);
    this.formatYAxis.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.orderNumber !== this.props.orderNumber) {
      this.props.getUser(nextProps.orderNumber);
    }
  }

  formatXAxis(tickItem) {
    return moment(tickItem)
      .utcOffset("-0300")
      .format("DD/MM/YY");
  }

  formatYAxis(tickItem) {
    return tickItem.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL"
    });
  }

  render() {
    const user = this.props.user;
    if (_.isEmpty(user)) return null;

    const firstOrder = _.first(this.props.completedOrders);

    const data = [];
    if (this.props.completedOrders) {
      const recentOrders = _.takeRight(this.props.completedOrders, 30);
      const dates = recentOrders.map(o =>
        moment(o.date)
          .utcOffset("-0300")
          .startOf("day")
      );

      // Get the oldest order
      const tempDate = _.first(dates);

      // Given that there is more than one order
      while (dates.length > 1 && tempDate.diff(_.last(dates), "days") <= 0) {
        // For orders in the past or current date
        // Get all orders that are in todays date
        const tempOrders = _.filter(
          recentOrders,
          o =>
            moment(o.date)
              .startOf("day")
              .diff(tempDate, "days") === 0
        );

        // Sum up the price of all orders
        const price = tempOrders.reduce(
          (previousValue, currentValue) =>
            previousValue + parseFloat(currentValue.value),
          0
        );

        data.push({ date: tempDate.format(), price });
        // Increment by 1 day
        tempDate.add(1, "day");
      }
    }

    let noGraphData = "";
    if (data.length === 0) {
      noGraphData = "user-fraud-no-graph";
    }

    return (
      <div className="user-fraud-main">
        <div className="user-fraud-content">
          <div className={`user-fraud-main-container ${noGraphData}`}>
            <h3>Estatísticas do Cliente</h3>

            <div className="address-item-line">
              <div>
                <b>Nome do cliente: </b> {user.firstName} {user.lastName}
              </div>

              <div>
                <b>Email do cliente: </b> {user.email}
              </div>

              <div>
                <b>Convidado por: </b> {user.invitedBy}
              </div>

              <div>
                <b>Conta criada: </b>
                {moment(user.createdAt)
                  .utcOffset("-0300")
                  .format("LLLL")}
              </div>

              <div>
                <b>CPF: </b> {user.cpf.value} foi usado por{" "}
                {user.cpf.otherUsers.length} outros usuários
              </div>

              <div>
                <b>Número de endereços cadastrados: </b> {user.addresses.length}
              </div>

              <div>
                <b>Número de pedidos completos: </b>{" "}
                {this.props.completedOrders.length}
              </div>

              <div>
                <b>Número de pedidos cancelados: </b>{" "}
                {this.props.canceledOrders.length}
              </div>

              <div>
                <b>Valor Mínimo: </b>{" "}
                {Math.min(...this.props.completedOrdersPrice).toLocaleString(
                  "pt-br",
                  {
                    style: "currency",
                    currency: "BRL"
                  }
                )}{" "}
                <b>Valor Máximo: </b>{" "}
                {Math.max(...this.props.completedOrdersPrice).toLocaleString(
                  "pt-br",
                  {
                    style: "currency",
                    currency: "BRL"
                  }
                )}
              </div>

              {firstOrder !== undefined ? (
                <div>
                  <b>Data do primeiro pedido: </b>{" "}
                  {moment(firstOrder.date)
                    .utcOffset("-0300")
                    .format("LLLL")}
                </div>
              ) : (
                <div>Primeiro pedido realizado.</div>
              )}
            </div>
          </div>

          {noGraphData !== "" ? null : (
            <div className="chart-container">
              <h3>Total de Compras por Dia</h3>
              <LinePlot
                data={data}
                color={["#eb5b1e"]}
                showToolTipText={false}
                yFormatter={this.formatYAxis}
                xFormatter={this.formatXAxis}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: FraudSelectors.userSelector(state),
    completedOrders: FraudSelectors.userCompletedOrdersSelector(state),
    completedOrdersPrice: FraudSelectors.userCompletedOrdersPriceSelector(
      state
    ),
    canceledOrders: FraudSelectors.userCanceledOrdersSelector(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    getUser: (orderNumber: string) => {
      dispatch(FraudAction.getUser(orderNumber));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFraud);
