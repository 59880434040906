// @flow
import { schema } from "normalizr";

////// RELATED TO ORDER STATISTICS
export const ordersStatistic = new schema.Entity(
  "ordersStatistics",
  {},
  { idAttribute: "date" }
);

export const ordersStatistics = new schema.Array(ordersStatistic);

////// RELATED TO PRODUCTS STATISTICS
const productStatistic = new schema.Entity(
  "productStatistics",
  {},
  { idAttribute: "name" }
);

export const productsStatistics = new schema.Entity(
  "productsStatistics",
  {
    statistics: [productStatistic],
  },
  { idAttribute: "startDate" }
);

////// RELATED TO PREPARATION STATISTICS
export const preparationStatistic = new schema.Entity(
  "preparationStatistics",
  {},
  { idAttribute: "employeeId" }
);

export const preparationStatistics = new schema.Entity(
  "preparationsStatistics",
  { statistics: [preparationStatistic] },
  { idAttribute: "startDate" }
);


////// RELATED TO TASK STATE STATISTICS

export const taskStateStatistic = new schema.Entity(
  "taskStateStatistics",
  {},
  { idAttribute: "date" }
);
export const taskStateStatistics = new schema.Array(taskStateStatistic);

////// RELATED TO ROUTE TRAVEL STATISTICS
export const routeTravelStatistic = new schema.Entity(
  "routeTravelStatistics",
  {},
  { idAttribute: "driverId" }
);

export const routeTravelStatistics = new schema.Entity(
  "routeTravelsStatistics",
  { statistics: [routeTravelStatistic] },
  { idAttribute: "startDate" }
);
