// @flow
import { handleActions, combineActions } from "redux-actions";
import { combineReducers } from "redux";
// Actions
import { fetchIssueSourcesRequest, fetchIssueSources } from "../actions";
// Types
import type { Reducer } from "redux";
import type { Action } from "../../common";

// The issueSources that were retrieved in the last API request
const currentList: Reducer<string, Action> = handleActions(
  {
    [fetchIssueSources]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state
    }
  },
  []
);

// All issueSouces saves as a Map<>
const issueSources: Reducer<{ [string]: any }, Action> = handleActions(
  {
    [combineActions(fetchIssueSources)]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.issueSources
      }),
      throw: (state, action) => state
    }
  },
  {}
);

// Loading
const initialLoadingState = {
  gettingIssueSources: false
};
const loading = handleActions(
  {
    [fetchIssueSourcesRequest]: (state, action) => ({
      ...state,
      gettingIssueSources: true
    }),
    [fetchIssueSources]: (state, action) => ({
      ...state,
      gettingIssueSources: false
    })
  },
  initialLoadingState
);

// Errors
// An array with the newest API errors
const errors = handleActions(
  {
    [combineActions(fetchIssueSources)]: {
      throw: (state, action) => [...state, action]
    }
    // [clearOrderErrors]: (state, action) => [],
  },
  []
);

const reducers = combineReducers({
  currentList,
  issueSources,

  loading,
  errors
});

export default reducers;
