// @flow
import React from "react";
// Composers
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// Components
import { Redirect } from "react-router-dom";
// Selectors
import { loggedInSelector } from "../selectors";
// Types
import type { ContextRouter } from "react-router-dom";

type Props = {
  ref: string,
  children: React.Element<*> | React.Element<*>[] | Component | React.Component,
  loggedIn: boolean,
} & ContextRouter;

const UnauthenticatedRedirect = (props: Props) =>
  props.loggedIn ? (
    props.children
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: {
          to: props.location.pathname,
          message: "Por favor entre ou crie sua conta para continuar",
        },
      }}
    />
  );

const mapStateToProps = (state, ownProps) => {
  return {
    loggedIn: loggedInSelector(state),
  };
};

export default withRouter(connect(mapStateToProps)(UnauthenticatedRedirect));
