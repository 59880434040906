// @flow
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
// Components
import DatePicker from 'react-datepicker';
// Types

//Styles
import './StatisticsFilter.css';
import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  loading: boolean,
  defaultStartDate: ?any,
  defaultEndDate: ?any,
  showDateGroupFilter: ?boolean,
  showProductFilter: ?boolean,
  showDriverFilter: ?boolean,
  showTimeFilter: ?boolean,
  defaultStartTime: ?any,
  defaultEndTime: ?any,
  drivers: ?any,
  handleSearch: () => void,
};

type State = {
  startDate: any,
  endDate: any,
  startTime: any,
  endTime: any,
  byDistributionCenter: string,
  byDateGroup: string,
};

class StatisticsFilter extends React.Component {
  state: State;

  constructor(props: Props) {
    super(props);

    // The the initial state
    this.state = {
      byDate: {
        startedAt: '',
        endedAt: '',
      },
      byTime: {
        startedAt: '',
        endedAt: '',
      },
      startTime: props.defaultStartTime || moment(),
      endTime: props.defaultEndTime || moment(),
      startDate: props.defaultStartDate || moment(),
      endDate: props.defaultEndDate || moment(),
      byDistributionCenter: '',
      byDateGroup: 'shipping_date',
      product: '',
      driver: '',
    };

    this.updateFilters({});
  }

  updateFilters = searchParams => {
    this.setState({ ...searchParams });

    const searchScope = {
      byDate: {
        startedAt: (searchParams.startDate || this.state.startDate).format(
          'YYYYMMDD',
        ),
        endedAt: (searchParams.endDate || this.state.endDate).format(
          'YYYYMMDD',
        ),
      },
      byTime: {
        startedAt: (searchParams.startTime || this.state.startTime)
          .utcOffset('-0300')
          .format('HH:mm'),
        endedAt: (searchParams.endTime || this.state.endTime)
          .utcOffset('-0300')
          .format('HH:mm'),
      },
      byDistributionCenter:
        searchParams.byDistributionCenter === ''
          ? ''
          : searchParams.byDistributionCenter ||
            this.state.byDistributionCenter,
      byDateGroup: searchParams.byDateGroup || this.state.byDateGroup,
      byProduct: searchParams.product || this.state.product,
      byDriver: searchParams.driver || this.state.driver,
    };
    if (!this.props.showTimeFilter) {
      delete searchScope.byTime;
    }

    this.props.handleSearch(_.omitBy(searchScope, v => v == null));
  };

  render() {
    return (
      <div className="statistics-filter-main">
        <div className="statistics-filter-container">
          <p>de</p>
          <DatePicker
            selected={this.state.startDate}
            onChange={date =>
              this.updateFilters({
                startDate: date,
              })
            }
            popperPlacement="bottom-start"
          />
          <p>até</p>
          <DatePicker
            selected={this.state.endDate}
            onChange={date =>
              this.updateFilters({
                endDate: date,
              })
            }
            popperPlacement="bottom-end"
          />
        </div>
        {(this.props.showTimeFilter && (
          <div className="statistics-filter-container">
            <p>de</p>
            <DatePicker
              selected={this.state.startTime}
              onChange={time => {
                this.updateFilters({
                  startTime: moment(time, 'HH:mm'),
                });
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Início"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
            />
            <p>até</p>
            <DatePicker
              selected={this.state.endTime}
              onChange={time =>
                this.updateFilters({
                  endTime: moment(time, 'HH:mm'),
                })
              }
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Fim"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
            />
          </div>
        )) ||
          null}
        <div className="statistics-center-filter-container">
          <div>
            <p>Centro:</p>
            <select
              value={this.state.byDistributionCenter}
              onChange={e => {
                const value = e.target.value;
                this.updateFilters({
                  byDistributionCenter: value,
                });
              }}
            >
              <option value="">Todos</option>
              <option value="portao">Portão</option>
              <option value="pituba">Pituba</option>
            </select>
          </div>
          <div>
            {this.props.showDateGroup && (
              <>
                <p>Estatisticas por:</p>
                <select
                  value={this.state.byDateGroup}
                  onChange={e => {
                    const value = e.target.value;
                    this.updateFilters({
                      byDateGroup: value,
                    });
                  }}
                >
                  <option value="shipping_date">Dia de entrega</option>
                  <option value="completed_at">Dia de criação</option>
                </select>
              </>
            )}
          </div>
          <div>
            {this.props.showProductFilter && (
              <>
                <p>Produto: </p>
                <input
                  value={this.state.product}
                  onChange={e => {
                    this.setState({ product: e.target.value });
                  }}
                  onBlur={() => this.updateFilters({})}
                />
              </>
            )}
          </div>

          <div>
            {this.props.showDriverFilter && (
              <>
                <p>Motorista: </p>
                <select
                  onChange={e => {
                    this.setState({ driver: e.target.value });
                    this.updateFilters({ driver: e.target.value });
                  }}
                  value={this.state.driver}
                >
                  <option value={''}>{'Todos'}</option>
                  {this.props.drivers.map((d, i) => {
                    return (
                      <option key={i} value={d.id}>
                        {d.name}
                      </option>
                    );
                  })}
                </select>
              </>
            )}
          </div>
        </div>
        {this.props.loading ? (
          <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

StatisticsFilter.defaultProps = {
  showProductFilter: false,
  showDriverFilter: false,
  showTimeFilter: false,
  showDateGroup: false,
};

export default StatisticsFilter;
