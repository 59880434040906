// @flow
import { handleActions, combineActions } from "redux-actions";
import _ from "lodash";
import { combineReducers } from "redux";
import moment from "moment";
// Actions
import * as Actions from "../actions";
// Types
import type { Reducer } from "redux";
import type { Action } from "../../common";

//////////////////////////////////////////////////////////////////
//////////// ROUTE RELATED

// Filters
const routeFilters: Reducer<Array, Action> = handleActions(
  {
    [combineActions(Actions.editRouteFilters)]: {
      next: (state, action) => action.payload,
    },
  },
  { byDate: moment().format("YYYYMMDD") }
);

// The orders that are currently shown in search
const currentRouteList: Reducer<Array, Action> = handleActions(
  {
    [combineActions(Actions.fetchRoutes)]: {
      next: (state, action) => [...action.payload.result],
    },
    [combineActions(Actions.createRoute)]: {
      next: (state, action) => [...state, action.payload.result],
    },
    [combineActions(Actions.createManyRoutes)]: {
      next: (state, action) => [...state, ...action.payload.result],
    },
    [combineActions(Actions.destroyRoute)]: {
      next: (state, action) =>
        _.filter(state, (id) => id !== action.payload.routeId),
    },
  },
  []
);

// All virtual routes saves as a Map<>
const virtualRoutes: Reducer<{ [string]: any }, Action> = handleActions(
  {
    [combineActions(Actions.generateVirtualRoutes)]: {
      next: (state, action) => ({
        ...action.payload.entities.virtualRoutes,
      }),
    },
    [combineActions(Actions.clearVirtualRoutes)]: {
      next: (state, action) => ({}),
    },
    [combineActions(Actions.addVirtualRoute)]: {
      next: (state, action) => {
        const newVirtualRoutes = _.values(state);

        newVirtualRoutes.push({
          id:
            (newVirtualRoutes &&
              Math.max.apply(
                null,
                newVirtualRoutes.map((vr) => vr.id)
              ) + 1) ||
            0,
          visits: [],
        });

        return Object.assign({}, newVirtualRoutes);
      },
    },
    [combineActions(Actions.moveVirtualVisit)]: {
      next: (state, action) => {
        const newVirtualRoutes = {
          ...state,
        };
        const newVirtualRoutesIds = Object.values(newVirtualRoutes).map(
          (v) => v.id
        );
        const srcVirtualRouteIndex = newVirtualRoutesIds.findIndex(
          (vrId) => vrId == action.payload.srcVirtualRouteId
        );
        const destVirtualRouteIndex = newVirtualRoutesIds.findIndex(
          (vrId) => vrId == action.payload.destVirtualRouteId
        );

        const srcVirtualVisitIndex = newVirtualRoutes[
          srcVirtualRouteIndex
        ].visits.findIndex((v) => v == action.payload.visitId);

        // Remove visit from the old virtual route
        newVirtualRoutes[srcVirtualRouteIndex].visits.splice(
          srcVirtualVisitIndex,
          1
        );

        // And add to the new one
        newVirtualRoutes[destVirtualRouteIndex].visits.push(
          Number(action.payload.visitId)
        );

        return newVirtualRoutes;
      },
    },
    [combineActions(Actions.removeVirtualRoute)]: {
      next: (state, action) =>
        Object.assign(
          {},
          _.filter(
            state,
            (virtualRoute) => virtualRoute.id !== action.payload.virtualRouteId
          )
        ),
    },
  },
  {}
);

const initialAutoRoutingVisitsState = {
  isAutoRouting: false,
};

const autoRoutingVisits = handleActions(
  {
    [combineActions(Actions.startAutoRoutingVisits)]: (state) => ({
      isAutoRouting: true,
    }),
    [combineActions(Actions.stopAutoRoutingVisits)]: (state) => ({
      isAutoRouting: false,
    }),
  },
  initialAutoRoutingVisitsState
);

// All routes saves as a Map<>
const routes: Reducer<{ [string]: any }, Action> = handleActions(
  {
    [combineActions(
      Actions.fetchRoutes,
      Actions.createRoute,
      Actions.createThirdPartyDeliveryOrder,
      Actions.estimateThirdPartyDeliveryOrder,
      // Actions.redoThirdPartyDeliveryOrder,
      Actions.refreshThirdPartyDeliveryOrder,
      Actions.cancelThirdPartyDeliveryOrder,
      Actions.createManyRoutes,
      Actions.updateRoute,
      Actions.reorderVisitsInRouteResponse
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.routes,
      }),
    },
    // When sorting a route we should update the position of every route.
    // However, to speed up date transfer from server the object sent is not a full route object
    // So we cannot simply replace the current route objects
    [combineActions(Actions.moveRoute)]: {
      next: (state, action) => {
        const routes = { ...state };
        for (const routeId of action.payload.result) {
          routes[routeId].position =
            action.payload.entities.routes[routeId].position;
        }
        return routes;
      },
    },
    [combineActions(Actions.destroyVisit)]: {
      next: (state, action) => {
        const routeId = action.payload.routeId;
        if (!routeId) return state;

        const newState = { ...state };
        state[routeId].visits = _.filter(
          state[routeId].visits,
          (o) => o !== action.payload.id
        );
        delete newState[action.payload.id];

        return newState;
      },
    },
    // PREEMPTIVE LOADING
    // NOTE: This is not required but improves user experience when using the app
    // A visit update may include adding it to a route. In this case, we should update the route accordingly here
    // TODO: Should be able to remove visit from route or move a visit between routes
    [combineActions(Actions.updateVisitRequest)]: {
      next: (state, action) => {
        if (
          "routeId" in action.payload.params &&
          action.payload.prevVisit.routeId !== action.payload.params.routeId
        ) {
          // Remove from current (if any)
          const nextState = { ...state };
          if (action.payload.prevVisit.routeId) {
            nextState[action.payload.prevVisit.routeId] = {
              ...nextState[action.payload.prevVisit.routeId],
              visits: _.filter(
                nextState[action.payload.prevVisit.routeId].visits,
                (id) => id !== action.payload.prevVisit.id
              ),
            };
          }
          // Place visit in the correct route
          if (action.payload.params.routeId) {
            nextState[action.payload.params.routeId] = {
              ...nextState[action.payload.params.routeId],
              visits: [
                ...nextState[action.payload.params.routeId].visits,
                action.payload.prevVisit.id,
              ],
            };
          }

          return nextState;
        }
        return state;
      },
    },
  },
  {}
);

//////////////////////////////////////////////////////////////////
//////////// VISIT RELATED

// All visits saves as a Map<>
const visits: Reducer<{ [string]: any }, Action> = handleActions(
  {
    [combineActions(
      Actions.fetchVisits,
      Actions.fetchRoutes,
      Actions.estimateThirdPartyDeliveryOrder,
      Actions.createThirdPartyDeliveryOrder,
      // Actions.redoThirdPartyDeliveryOrder,
      Actions.refreshThirdPartyDeliveryOrder,
      Actions.cancelThirdPartyDeliveryOrder,
      Actions.createManyRoutes,
      Actions.createVisit,
      Actions.updateVisit,
      Actions.reorderVisitsInRouteResponse
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.visits,
      }),
    },
    // When we create a new task we need to add its ID to the visit to which it belongs
    [combineActions(Actions.createTask)]: {
      next: (state, action) => {
        const taskVisitId =
          action.payload.entities.tasks[action.payload.result].visitId;
        if (!taskVisitId) return state;

        const visit = { ...state[taskVisitId] };
        visit.tasks = [...visit.tasks, action.payload.result];

        return { ...state, [visit.id]: visit };
      },
    },
    [combineActions(Actions.destroyVisit)]: {
      next: (state, action) => {
        const newState = { ...state };
        delete newState[action.payload.id];

        return newState;
      },
    },
    [combineActions(Actions.destroyTask)]: {
      next: (state, action) => {
        const newState = { ...state };
        newState[action.payload.visitId].tasks = _.filter(
          newState[action.payload.visitId].tasks,
          (taskId) => taskId !== action.payload.taskId
        );

        return newState;
      },
    },
    // PREEMPTIVE LOADING
    // Related to changing route or position
    [combineActions(Actions.updateVisitRequest)]: {
      next: (state, action) => {
        if ("routeId" in action.payload.params) {
          return {
            ...state,
            [action.payload.prevVisit.id]: {
              ...state[action.payload.prevVisit.id],
              routeId: action.payload.params.routeId,
            },
          };
        }

        return state;
      },
    },
  },
  {}
);

// All tasks saves as a Map<>
const tasks: Reducer<{ [string]: any }, Action> = handleActions(
  {
    [combineActions(
      Actions.fetchRoutes,
      Actions.estimateThirdPartyDeliveryOrder,
      Actions.createThirdPartyDeliveryOrder,
      // Actions.redoThirdPartyDeliveryOrder,
      Actions.refreshThirdPartyDeliveryOrder,
      Actions.cancelThirdPartyDeliveryOrder,
      Actions.fetchVisits,
      Actions.updateVisit,
      Actions.createTask,
      Actions.updateTask
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.tasks,
      }),
    },
  },
  {}
);

// All drivers saved as a Map<>
const drivers: Reducer<{ [number]: any }, Action> = handleActions(
  {
    [combineActions(Actions.fetchDrivers, Actions.fetchRoutes)]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.drivers,
      }),
    },
  },
  {}
);

//All Vehicles saved as a Map<>
const vehicles: Reducer<{ [number]: any }, Action> = handleActions(
  {
    [combineActions(Actions.fetchVehicles, Actions.fetchRoutes)]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.vehicles,
      }),
    },
  },
  {}
);

// All TimeWindows saved as a Map<>
const timeWindow: Reducer<{ [number]: any }, Action> = handleActions(
  {
    [combineActions(
      Actions.updateTimeWindow,
      Actions.fetchVisits,
      Actions.fetchRoutes,
      Actions.createVisit,
      Actions.updateVisit
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.timeWindows,
      }),
    },
    [combineActions(Actions.updateShippingRate)]: {
      next: (state, {payload}) => 
        ({...state, 
          [payload.timeWindowId]: {
            ...state[payload.timeWindowId],
            endTime: payload.entities.shippingRates[payload.result].deliveryWindowEnd,
            startTime:payload.entities.shippingRates[payload.result].deliveryWindowStart,
          }
        }),
    }
  },
  {}
);

// All Addresses saved as a Map<>
const addresses: Reducer<{ [number]: any }, Action> = handleActions(
  {
    [combineActions(
      Actions.createAddress,
      Actions.fetchVisits,
      Actions.fetchVisit,
      Actions.fetchRoutes,
      Actions.createVisit
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.addresses,
      }),
    },
  },
  {}
);

// All Addresses saved as a Map<>
const locations: Reducer<{ [number]: any }, Action> = handleActions(
  {
    [combineActions(
      Actions.getMostRecentLocationsForAllDriversResponse,
      Actions.getLocationsByVisitResponse
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.locations,
      }),
    },
  },
  {}
);

// Loading
const initialLoadingState = {
  gettingVirtualRoutes: false,
  gettingRoutes: false,
  creatingVisit: false,
  gettingVisits: false,
  deletingVisit: false,
  editingTimeWindow: false,
  creatingTask: false,
  updatingTask: false,
  deletingTask: false,
  gettingDrivers: false,
  gettingVehicles: false,
  creatingOrEditingAddress: false,
  creatingRoute: false,
  updatingRoute: false,
  deletingRoute: false,
  gettingLocations: false,
};
const loading = handleActions(
  {
    [combineActions(Actions.generateVirtualRoutesRequest)]: (state) => ({
      ...state,
      gettingVirtualRoutes: true,
    }),
    [combineActions(Actions.generateVirtualRoutes)]: (state) => ({
      ...state,
      gettingVirtualRoutes: false,
    }),
    [combineActions(
      Actions.fetchRoutesRequest,
      Actions.sortPreparationQueueBasedOnRoutesRequest
    )]: (state) => ({
      ...state,
      gettingRoutes: true,
    }),
    [combineActions(
      Actions.fetchRoutes,
      Actions.sortPreparationQueueBasedOnRoutesResponse
    )]: (state) => ({
      ...state,
      gettingRoutes: false,
    }),

    [Actions.fetchVisitsRequest]: (state) => ({
      ...state,
      gettingVisits: true,
    }),
    [Actions.fetchVisits]: (state) => ({
      ...state,
      gettingVisits: false,
    }),
    [Actions.createVisitRequest]: (state) => ({
      ...state,
      creatingVisit: true,
    }),
    [Actions.createVisit]: (state) => ({
      ...state,
      creatingVisit: false,
    }),
    [Actions.destroyVisitRequest]: (state) => ({
      ...state,
      deletingVisit: true,
    }),
    [Actions.destroyVisit]: (state) => ({
      ...state,
      deletingVisit: false,
    }),
    [Actions.updateTimeWindowRequest]: (state) => ({
      ...state,
      editingTimeWindow: true,
    }),
    [Actions.updateTimeWindow]: (state) => ({
      ...state,
      editingTimeWindow: false,
    }),
    [Actions.createTaskRequest]: (state) => ({
      ...state,
      creatingTask: true,
    }),
    [Actions.createTask]: (state) => ({
      ...state,
      creatingTask: false,
    }),
    [Actions.updateTaskRequest]: (state) => ({
      ...state,
      updatingTask: true,
    }),
    [Actions.updateTask]: (state) => ({
      ...state,
      updatingTask: false,
    }),
    [Actions.destroyTaskRequest]: (state) => ({
      ...state,
      deletingTask: true,
    }),
    [Actions.destroyTask]: (state) => ({
      ...state,
      deletingTask: false,
    }),
    [Actions.fetchDriversRequest]: (state) => ({
      ...state,
      gettingDrivers: true,
    }),
    [Actions.fetchDrivers]: (state) => ({
      ...state,
      gettingDrivers: false,
    }),
    [Actions.fetchVehiclesRequest]: (state) => ({
      ...state,
      gettingVehicles: true,
    }),
    [Actions.fetchVehicles]: (state) => ({
      ...state,
      gettingVehicles: false,
    }),
    [Actions.createAddressRequest]: (state) => ({
      ...state,
      creatingOrEditingAddress: true,
    }),
    [Actions.createAddress]: (state) => ({
      ...state,
      creatingOrEditingAddress: false,
    }),
    [Actions.createRouteRequest]: (state) => ({
      ...state,
      creatingRoute: true,
    }),
    [Actions.createManyRoutesRequest]: (state) => ({
      ...state,
      creatingRoute: true,
    }),
    [Actions.createRoute]: (state) => ({
      ...state,
      creatingRoute: false,
    }),
    [Actions.createManyRoutes]: (state) => ({
      ...state,
      creatingRoute: false,
    }),
    [combineActions(Actions.estimateThirdPartyDeliveryOrderRequest)]: (state) => ({
      ...state,
      estimatingThirdPartyDeliveryRoute: true,
    }),
    [combineActions(Actions.estimateThirdPartyDeliveryOrder)]: (state) => ({
      ...state,
      estimatingThirdPartyDeliveryRoute: false,
    }),
    [combineActions(Actions.createThirdPartyDeliveryOrderRequest)]: (state) => ({
      ...state,
      creatingLoggiRoute: true,
    }),
    [combineActions(Actions.createThirdPartyDeliveryOrder)]: (state) => ({
      ...state,
      creatingLoggiRoute: false,
    }),
    [combineActions(
      // Actions.redoThirdPartyDeliveryOrderRequest,
      Actions.refreshThirdPartyDeliveryOrderRequest,
      Actions.cancelThirdPartyDeliveryOrderRequest
    )]: (state) => ({
      ...state,
      updatingLoggiRoute: true,
    }),
    [combineActions(
      // Actions.redoThirdPartyDeliveryOrder,
      Actions.refreshThirdPartyDeliveryOrder,
      Actions.cancelThirdPartyDeliveryOrder
    )]: (state) => ({
      ...state,
      updatingLoggiRoute: false,
    }),
    [combineActions(
      Actions.updateRouteRequest,
      Actions.createThirdPartyDeliveryOrderRequest,
      Actions.reorderVisitsInRouteRequest,
      Actions.refreshThirdPartyDeliveryOrderRequest,
      Actions.cancelThirdPartyDeliveryOrderRequest
    )]: (state) => ({
      ...state,
      updatingRoute: true,
    }),
    [combineActions(
      Actions.updateRoute,
      Actions.createThirdPartyDeliveryOrder,
      Actions.reorderVisitsInRouteResponse,
      Actions.refreshThirdPartyDeliveryOrder,
      Actions.cancelThirdPartyDeliveryOrder
    )]: (state) => ({
      ...state,
      updatingRoute: false,
    }),
    [Actions.destroyRouteRequest]: (state) => ({
      ...state,
      deletingRoute: true,
    }),
    [Actions.destroyRoute]: (state) => ({
      ...state,
      deletingRoute: false,
    }),
    [combineActions(
      Actions.getMostRecentLocationsForAllDriversRequest,
      Actions.getLocationsByVisitRequest
    )]: (state) => ({
      ...state,
      gettingLocations: true,
    }),
    [combineActions(
      Actions.getMostRecentLocationsForAllDriversResponse,
      Actions.getLocationsByVisitResponse
    )]: (state) => ({
      ...state,
      gettingLocations: false,
    }),
  },
  initialLoadingState
);

const reducers = combineReducers({
  currentRouteList,

  routeFilters,

  autoRoutingVisits,
  virtualRoutes,
  routes,
  visits,
  tasks,
  drivers,
  vehicles,
  timeWindow,
  addresses,
  locations,
  loading,
});

export default reducers;
