// @flow
import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
//Style
import "./RoutePage.css";
// Composers
import { connect } from "react-redux";
//Components
import { UnauthenticatedRedirect } from "../../employee";
import { DragDropContext } from "react-beautiful-dnd";
import randomColor from "randomcolor";
import RouteList from "../components/RoutesList";
import VisitsList from "../components/VisitsList";
import MapColumn from "../components/MapColumn";
import DatePicker from "react-datepicker";
import PeriodicGetter from "../../fulfillments/components/PeriodicGetter";
import ReactTooltip from "react-tooltip";
//Actions
import * as Actions from "../actions";
//Selectors
import * as Selectors from "../selectors";
//Styles
import "./RoutePage.css";

type Props = {
  handleSearchVisits: () => void,
  handleSearchRoutes: () => void,
  handleGenerateVirtualRoutes: () => void,
  handleClearVirtualRoutes: () => void,
};

const VisitsListMemo = React.memo(VisitsList);
const RouteListMemo = React.memo(RouteList);
const MapColumnMemo = React.memo(MapColumn);

const RoutePage = ({
  routes,
  unroutedVisits,
  virtualRoutes,
  routeFilters,
  allDrivers,
  allVehicles,
  timeWindowById,
  addressById,
  locationsByDriverId,
  vehicleById,
  visitById,
  visitsOfCurrentRoutes,
  numberOfImmutableVisitsInRoute,
  autoRoutingInProgress,
  visitLoading,
  routeLoading,
  virtualRouteLoading,
  locationsLoading,
  handleGenerateVirtualRoutes,
  handleStartAutoRoutingVisits,
  handleStopAutoRoutingVisits,
  handleAddVirtualRoute,
  handleSearchVisits,
  handleSearchVehicles,
  handleCreateVisit,
  handleEditVisit,
  handleMoveVirtualVisit,
  handleEditRouteFilters,
  handleSearchRoutes,
  handleCreateRoute,
  handleCreateManyRoutes,
  handleClearVirtualRoutes,
  handleSortPreparationQueueBasedOnRoutes,
  handleGetMostRecentLocationsForAllDrivers,
}) => {
  const [driversShow, setDriversShow] = useState(false);
  const [selectedVisitId, setSelectedVisitId] = useState(null);
  const [selectedRouteId, setSelectedRouteId] = useState(null);
  const [selectedVisitsId, setSelectedVisitsId] = useState([]);
  const [colors, setColors] = useState(
    randomColor({
      count: routes.length,
      seed: 2,
      // luminosity: "random"
      hue: "random",
    })
  );
  const [virtualRoutesColors, setVirtualRoutesColors] = useState(
    randomColor({
      count: virtualRoutes.length,
      seed: 2,
      hue: "random",
    })
  );
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [isUnsettedFilters, setIsUnsettedFilters] = useState(false);

  useEffect(() => {
    if (!autoRoutingInProgress) {
      setSelectedVisitsId([]);
      if (virtualRoutes.length !== 0) {
        handleClearVirtualRoutes();
      }
    } else {
      handleSearchVehicles();
    }
  }, [autoRoutingInProgress]);

  useEffect(() => {
    setColors(
      randomColor({
        count: routes.length,
        seed: 2,
        // luminosity: "random"
        hue: "random",
      })
    );
  }, [routes]);

  useEffect(() => {
    setVirtualRoutesColors(
      randomColor({
        count: virtualRoutes.length,
        seed: 2,
        hue: "random",
      })
    );
  }, [virtualRoutes]);

  const onDragEnd = React.useCallback(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      // If we are dealing with a VISIT droppable
      const splitDragabbleId = result.draggableId.split("-");
      if (splitDragabbleId[0] === "visit") {
        const sourceId = splitDragabbleId[splitDragabbleId.length - 1];

        // Remove from routes (back to visit list)
        if (result.destination.droppableId === "visit-list") {
          handleEditVisit(sourceId, {
            routeId: null,
            position: null,
          });
        }

        // If our destination is a route-list-<ROUTE_ID> we should add our visit to that route at the index position
        const parsedDestinationId = result.destination.droppableId.split("-");
        if (parsedDestinationId[0] !== "route") {
          return;
        }

        handleEditVisit(sourceId, {
          routeId: parsedDestinationId[2],
          position: Math.max(
            result.destination.index + 1,
            numberOfImmutableVisitsInRoute(parsedDestinationId[2]) + 1
          ), // Note: We cannot add a visit before an immutable visit
        });
      } else if (splitDragabbleId[0] === "virtual") {
        const parsedDestinationId = result.destination.droppableId.split("-");
        if (
          result.destination.droppableId === result.source.droppableId ||
          (parsedDestinationId[0] !== "virtual" &&
            parsedDestinationId[1] !== "route")
        ) {
          return;
        }

        const sourceId = splitDragabbleId[splitDragabbleId.length - 1];
        const sourceVirtualRouteId = result.source.droppableId.split("-");

        handleMoveVirtualVisit(
          sourceId,
          sourceVirtualRouteId[3],
          parsedDestinationId[3]
        );
      }
    },
    [handleEditVisit, handleMoveVirtualVisit, numberOfImmutableVisitsInRoute]
  );

  const handleClickVisit = React.useCallback(
    (ids) => {
      //unhighlight one visit that are highlight
      if (
        _.includes(selectedVisitsId, ...ids) &&
        ids.length === 1 &&
        selectedVisitsId.length === 1
      ) {
        setSelectedVisitsId([]);
        return;
      }
      //unhighlight a especific visit on a highlight route
      if (_.includes(selectedVisitsId, ...ids) && ids.length === 1) {
        if (autoRoutingInProgress) {
          const newSelectedVisitsId = [...selectedVisitsId];
          _.remove(newSelectedVisitsId, (sId) => sId === ids[0]);

          setSelectedVisitsId(newSelectedVisitsId);
        } else {
          setSelectedVisitsId([...ids]);
        }

        return;
      }
      //unhighlight anything that are highlight (general case)
      if (_.includes(selectedVisitsId, ...ids)) {
        setSelectedVisitsId([]);
        return;
      }
      //highlight anything (general case)
      setSelectedVisitsId(
        autoRoutingInProgress ? [...selectedVisitsId, ...ids] : [...ids]
      );
    },
    [selectedVisitsId, autoRoutingInProgress]
  );

  const handleClickRoute = React.useCallback(
    (id) => {
      if (selectedRouteId === id) {
        setSelectedRouteId(null);
      } else {
        setSelectedRouteId(id);
      }
    },
    [selectedRouteId]
  );

  const isValidVisit = (visit) => {
    return !(
      visit.address == null ||
      visit.tasks.length === 0 ||
      visit.state ||
      visit.state === "pending"
    );
  };

  const isAvalableToCreateRoute =
    moment(routeFilters.byDate).diff(moment(), "days") >= 0;

  return (
    <>
      <UnauthenticatedRedirect>
        <p className="route-page-alert-size-of-window">
          Essa página não é visível para dispositivos com baixa resolução de
          tela
        </p>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="route-container-flex">
            <div className="route-internal-visits-content">
              <div className="route-internal-content-menu">
                <h1 className="route-internal-title-content">Visitas</h1>
                <PeriodicGetter
                  countdownStartValue={30}
                  handleGet={() => handleSearchVisits({ unrouted: true })}
                  loading={visitLoading}
                />
                <div className="route-page-visits-buttons-container">
                  {autoRoutingInProgress ? (
                    <button
                      className={`route-page-select-all-button ${
                        visitLoading ? "route-page-disabled-menu-button" : ""
                      }`}
                      disabled={visitLoading}
                      data-for="route-page-tooltip-visits-router"
                      data-tip=""
                    >
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          e.target.checked
                            ? setSelectedVisitsId(
                                _.map(unroutedVisits, (visit) => {
                                  if (isValidVisit(visit)) {
                                    return visit.id;
                                  } else {
                                    return null;
                                  }
                                }).filter((visitId) => visitId != null)
                              )
                            : setSelectedVisitsId([]);
                        }}
                      />
                      <ReactTooltip id="route-page-tooltip-visits-router">
                        <p>Selecionar Tudo</p>
                      </ReactTooltip>
                    </button>
                  ) : (
                    ""
                  )}

                  <button
                    onClick={() => {
                      autoRoutingInProgress
                        ? handleStopAutoRoutingVisits()
                        : handleStartAutoRoutingVisits();
                    }}
                    className={`route-page-router-button${
                      autoRoutingInProgress ? "-selected" : ""
                    } ${visitLoading ? "route-page-disabled-menu-button" : ""}`}
                    disabled={visitLoading}
                    data-for="route-page-tooltip-visits-router"
                    data-tip=""
                  >
                    <i className="fa fa-map" aria-hidden="true"></i>
                    <ReactTooltip id="route-page-tooltip-visits-router">
                      <p>Roteirizar Visitas</p>
                    </ReactTooltip>
                  </button>
                  <button
                    onClick={() =>
                      handleCreateVisit({
                        date: moment(routeFilters.byDate).format(),
                      })
                    }
                    className={`route-page-menu-button ${
                      visitLoading ? "route-page-disabled-menu-button" : ""
                    }`}
                    disabled={visitLoading}
                    data-for="route-page-tooltip-create-visit"
                    data-tip=""
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    <ReactTooltip id="route-page-tooltip-create-visit">
                      <p>Criar Visita</p>
                    </ReactTooltip>
                  </button>
                </div>
              </div>
              <div className="route-internal-list">
                <VisitsListMemo
                  unroutedVisits={unroutedVisits}
                  visitLoading={visitLoading}
                  timeWindowById={timeWindowById}
                  selectedVisitId={selectedVisitId}
                  selectedVisitsIds={selectedVisitsId}
                  handleVisitClick={handleClickVisit}
                  autoRoutingInProgress={autoRoutingInProgress}
                />
              </div>
            </div>
            <div className="route-internal-routes-content">
              <div className="route-internal-content-menu">
                <div className="route-internal-name-calendar-content">
                  <h1 className="route-internal-title-content">Rotas</h1>
                  <div className="route-internal-calendar-content">
                    <DatePicker
                      selected={moment(routeFilters.byDate)}
                      onChange={(date) => {
                        handleEditRouteFilters({
                          ...routeFilters,
                          byDate: date.format("YYYYMMDD"),
                        });
                        handleSearchRoutes({
                          byDate: date.format("YYYYMMDD"),
                        });
                      }}
                    />
                  </div>
                  <PeriodicGetter
                    countdownStartValue={60}
                    handleGet={() =>
                      handleSearchRoutes({
                        byDate: routeFilters.byDate,
                      })
                    }
                    loading={routeLoading}
                  />
                </div>
                <div
                  className="route-page-add-visit-and-order-button"
                  data-tip=""
                  data-for="route-page-tooltip-date-alert-for-route"
                >
                  <button
                    onClick={() =>
                      handleCreateRoute({
                        date: moment(routeFilters.byDate).format(),
                      })
                    }
                    className={`route-page-menu-button ${
                      !isAvalableToCreateRoute || routeLoading
                        ? "route-page-disabled-menu-button"
                        : ""
                    }`}
                    disabled={!isAvalableToCreateRoute || routeLoading}
                  >
                    {!isAvalableToCreateRoute ? (
                      <ReactTooltip id="route-page-tooltip-date-alert-for-route">
                        <p>
                          Não é possível criar uma rota para data anterior a
                          atual
                        </p>
                      </ReactTooltip>
                    ) : null}

                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <div
                    data-tip=""
                    data-for="route-page-tooltip-info-re-order-button"
                  >
                    <ReactTooltip id="route-page-tooltip-info-re-order-button">
                      <p>Altera a ordem dos pedidos na fila de preparação.</p>
                    </ReactTooltip>
                    <button
                      onClick={() => handleSortPreparationQueueBasedOnRoutes()}
                      className="route-page-menu-button"
                      disabled={routeLoading}
                    >
                      <i
                        className="fa fa-sort-numeric-asc"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="route-internal-list">
                <RouteListMemo
                  routes={routes}
                  virtualRoutes={virtualRoutes}
                  vehicles={allVehicles}
                  vehicleById={vehicleById}
                  visitById={visitById}
                  colors={colors}
                  virtualRoutesColors={virtualRoutesColors}
                  routeLoading={routeLoading}
                  virtualRouteLoading={virtualRouteLoading}
                  selectedVisitId={selectedVisitId}
                  selectedVisitsId={selectedVisitsId}
                  handleVisitClick={handleClickVisit}
                  handleClickRoute={handleClickRoute}
                  handleAddVirtualRoute={handleAddVirtualRoute}
                  handleClearVirtualRoutes={handleClearVirtualRoutes}
                  autoRoutingInProgress={autoRoutingInProgress}
                  handleGenerateVirtualRoutes={handleGenerateVirtualRoutes}
                  handleCreateManyRoutes={handleCreateManyRoutes}
                />
              </div>
            </div>
            <div className="route-internal-map-content">
              <div className="route-internal-content-menu">
                <h1 className="route-internal-title-content">Mapa</h1>
                {driversShow && (
                  <PeriodicGetter
                    countdownStartValue={15}
                    handleGet={() =>
                      handleGetMostRecentLocationsForAllDrivers()
                    }
                    loading={locationsLoading}
                  />
                )}
                <button
                  onClick={() => setShowFilterMenu(!showFilterMenu)}
                  className={`route-page-menu-button ${
                    visitLoading ? "route-page-disabled-menu-button" : ""
                  } ${isUnsettedFilters ? "route-page-red-color" : ""}`}
                  disabled={visitLoading}
                >
                  <i className="fa fa-filter" aria-hidden="true"></i>
                </button>
              </div>
              <MapColumnMemo
                visits={[...unroutedVisits, ...visitsOfCurrentRoutes]}
                routes={routes}
                colors={colors}
                virtualRoutes={virtualRoutes}
                virtualRoutesColors={virtualRoutesColors}
                addressById={addressById}
                onHoverVisitPin={React.useCallback((visitId) => {
                  setSelectedVisitId(visitId);
                })}
                selectedVisitsId={selectedVisitsId}
                selectedRouteId={selectedRouteId}
                locationsByDriverId={locationsByDriverId}
                drivers={allDrivers}
                timeWindowById={timeWindowById}
                showDrivers={driversShow}
                showFilterMenu={showFilterMenu}
                driversShow={driversShow}
                setDriversShow={React.useCallback((value) =>
                  setDriversShow(value)
                )}
                handleSetFilters={React.useCallback((value) =>
                  setIsUnsettedFilters(value)
                )}
                autoRoutingInProgress={autoRoutingInProgress}
              />
            </div>
          </div>
        </DragDropContext>
      </UnauthenticatedRedirect>
    </>
  );
};

const mapStateToProps = (state, props: Props) => {
  return {
    allDrivers: Selectors.AllDriversSelector(state),
    routeFilters: Selectors.routeFiltersSelector(state),
    routes: Selectors.routeListSelector(state),
    autoRoutingInProgress: Selectors.isAutoRoutingVisitsSelector(state),
    virtualRoutes: Selectors.allVirtualRoutesSelector(state),
    unroutedVisits: Selectors.unroutedVisitsSelector(state),
    allVisits: Selectors.AllVisitsSelector(state),
    allVehicles: Selectors.AllVehiclesSelector(state),
    numberOfImmutableVisitsInRoute: (id) =>
      Selectors.numberOfImmutableVisitsInRouteSelector(state, id),
    addressById: (id) => Selectors.addressByIdSelector(state, id),
    timeWindowById: (id) => Selectors.timeWindowByIdSelector(state, id),
    locationsByDriverId: (id) =>
      Selectors.locationsByDriverIdSelector(state, id),
    vehicleById: (id) => Selectors.vehicleByIdSelector(state, id),
    visitById: (id) => Selectors.visitByIdSelector(state, id),
    visitLoading: Selectors.visitsLoadingSelector(state),
    timeWindowLoading: Selectors.timeWindowSelector(state),
    taskLoading: Selectors.taskSelector(state),
    routeLoading: Selectors.routesLoadingSelector(state),
    virtualRouteLoading: Selectors.virtualRoutesLoadingSelector(state),
    addressLoading: Selectors.addressLoadingSelector(state),
    locationsLoading: Selectors.locationsLoadingSelector(state),
    visitsOfCurrentRoutes: Selectors.visitsOfCurrentRoutesSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    handleGetMostRecentLocationsForAllDrivers: () => {
      dispatch(Actions.getMostRecentLocationsForAllDrivers());
    },
    handleSearchVisits: (searchParams) => {
      dispatch(Actions.searchVisits(searchParams));
    },
    handleSearchRoutes: (searchParams) => {
      dispatch(Actions.searchRoutes(searchParams));
    },
    handleSearchVehicles: (searchParams) => {
      dispatch(Actions.searchVehicles(searchParams));
    },
    handleEditRouteFilters: (searchParams) => {
      dispatch(Actions.editRouteFilters(searchParams));
    },
    handleCreateRoute: (routeParams) => {
      dispatch(Actions.newRoute(routeParams));
    },
    handleCreateManyRoutes: (routesParams) => {
      dispatch(Actions.createRoutes(routesParams));
    },
    handleEditTimeWindow: (id, timeWindowParam) => {
      dispatch(Actions.editTimeWindow(id, timeWindowParam));
    },
    handleCreateVisit: (visitParams) => {
      dispatch(Actions.newVisit(visitParams));
    },
    handleEditVisit: (id, visitParam) => {
      dispatch(Actions.editVisit(id, visitParam));
    },
    handleCreateTask: (taskParams) => {
      dispatch(Actions.newTask(taskParams));
    },
    handleEditTask: (id, taskParams) => {
      dispatch(Actions.editTask(id, taskParams));
    },
    handleCreateOrEditVisitAddress: (addressParams, visitId) => {
      dispatch(Actions.newAddress(addressParams, visitId));
    },
    handleDeleteVisit: (visitId) => {
      dispatch(Actions.deleteVisit(visitId));
    },
    handleDeleteTask: (taskId) => {
      dispatch(Actions.deleteTask(taskId));
    },
    handleSortPreparationQueueBasedOnRoutes: () => {
      dispatch(Actions.sortPreparationQueueBasedOnRoutes());
    },
    handleGenerateVirtualRoutes: (vehicleIds, visitIds) => {
      dispatch(Actions.getVirtualRoutes(vehicleIds, visitIds));
    },
    handleStartAutoRoutingVisits: () => {
      dispatch(Actions.startAutoRoutingVisits());
    },
    handleStopAutoRoutingVisits: () => {
      dispatch(Actions.stopAutoRoutingVisits());
    },
    handleAddVirtualRoute: () => {
      dispatch(Actions.addVirtualRoute());
    },
    handleClearVirtualRoutes: () => {
      dispatch(Actions.clearVirtualRoutes());
    },
    handleMoveVirtualVisit: (
      id,
      sourceVirtualRouteId,
      virtualRouteDestinationId
    ) => {
      dispatch(
        Actions.moveVirtualVisit({
          visitId: id,
          srcVirtualRouteId: sourceVirtualRouteId,
          destVirtualRouteId: virtualRouteDestinationId,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutePage);
