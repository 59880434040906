// @flow
import React, { useState, useEffect } from "react";
import { notificationHelper, usePrevious } from "../../common";
import _ from "lodash";
// Components
import FulfillmentList from "../components/FulfillmentList";
import FulfillmentFilters from "../components/FulfillmentFilters";
import { UnauthenticatedRedirect } from "../../employee";
// Composers
import { connect } from "react-redux";
// Import Actions
import { searchFulfillments } from "../actions";
// Selectors
import {
  fulfillmentListSelector,
  fulfillmentsLoadingSelector,
} from "../selectors";
// Types
import type { Fulfillment, SearchParams } from "../types";
import type { Dispatch } from "../../common";

type Props = {
  fulfillments: Fulfillment[],
  loading: Boolean,
  handleSearchFulfillments: (searchParams: SearchParams) => void,
};

const FulfillmentListPage = ({
  fulfillments,
  loading,
  handleSearchFulfillments,
}: Props) => {
  const [showNotification, setShowNotification] = useState(true);
  const prevFulfillments = usePrevious(fulfillments);

  useEffect(() => {
    if (showNotification && prevFulfillments.length > 0) {
      const alteredFulfillments = _.differenceBy(
        fulfillments,
        prevFulfillments,
        (value) =>
          `${value.number}${value.state}${value.needsAttention}${value.flagged}`
      );

      // Alert for new orders in the error state
      const relevantFulfillments = _.filter(
        alteredFulfillments,
        (v) =>
          v.state in
            ["error", "payment_evaluating", "payment_evaluating", "pending"] ||
          v.needs_attention ||
          v.flagged
      );
      if (relevantFulfillments.length === 1) {
        const orderNumber = relevantFulfillments[0].number;
        notificationHelper(`Pedido ${orderNumber} foi atualizado!`, {
          body: "Verifique o sistema do supervisor para mais detalhes.",
        });
      } else if (relevantFulfillments.length > 1) {
        notificationHelper(
          `${relevantFulfillments.length} pedidos foram atualizados!`,
          {
            body: "Verifique o sistema do supervisor para mais detalhes.",
          }
        );
      }
    }
  }, [fulfillments]);

  return (
    <UnauthenticatedRedirect>
      <FulfillmentFilters
        handleSearchFulfillments={handleSearchFulfillments}
        loading={loading}
        fulfillmentsQuantity={fulfillments.length}
        hanleNotificationToggle={setShowNotification}
      />
      <FulfillmentList fulfillments={fulfillments} loading={loading} />
    </UnauthenticatedRedirect>
  );
};

const mapStateToProps = (state) => {
  return {
    fulfillments: fulfillmentListSelector(state),
    loading: fulfillmentsLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    handleSearchFulfillments: (searchParams) => {
      dispatch(searchFulfillments(searchParams));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FulfillmentListPage);
