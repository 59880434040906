// @flow
import React from 'react';
import moment from 'moment';
// Components
import { UnauthenticatedRedirect } from '../../employee';
import { routeConnector } from '../../routes';
import StatisticsFilter from '../components/StatisticsFilter';
import StatisticsProductTable from '../components/StatisticsProductTable';
import StatisticsPreparationTable from '../components/StatisticsPreparationTable';
import StatisticsRouteTravelTable from '../components/StatisticsRouteTravelTable';
import { LinePlot } from '../../common';
import { withRouter } from 'react-router-dom';
// Composers
import { connect } from 'react-redux';
// Import Actions
import * as Actions from '../actions';
// Selectors
import * as Selectors from '../selectors';
// Types
import type { OrdersStatistic } from '../types';
import type { Dispatch } from '../../common';
//Styles
import './StatisticsPage.css';

type Props = {
  ordersStatistics: OrdersStatistic,
  handleGetOrdersStatistics: any => void,
  handleSearchDrivers: any => void,
  loadingOrders: boolean,

  productStatistics: any[],
  handleGetProductsStatistics: OrdersStatistic,
  loadingProducts: any => void,
  //Connector
  drivers: Any[],
  getDrivers: () => void,
};

type State = {
  productTableCurrentByDateFilters: {
    startedAt: String,
    endedAt: String,
  },
};

class StatisticsPage extends React.Component {
  state: State;
  constructor(props: Props) {
    super(props);
    this.props.getDrivers();

    this.formatXAxis.bind(this);
    this.formatYAxis.bind(this);

    this.state = {
      productTableCurrentByDateFilters: {
        startedAt: '',
        endedAt: '',
      },
    };
  }

  formatYAxis(tickItem) {
    return tickItem;
  }

  formatXAxis(tickItem) {
    return moment(tickItem).utcOffset('-0300').format('DD/MM/YY');
  }

  render() {
    return (
      <>
        <UnauthenticatedRedirect>
          <div className="statistics-page-main">
            <div>
              <h3>Estado de Pedido por Período</h3>
              <StatisticsFilter
                defaultStartDate={moment().subtract(7, 'days')}
                defaultEndDate={moment().add(1, 'days')}
                showDateGroup
                handleSearch={this.props.handleGetOrdersStatistics}
                loading={this.props.loadingOrders}
              />
              <div className="statistics-chart-container ">
                <LinePlot
                  data={this.props.ordersStatistics}
                  color={[
                    '#eb5b1e',
                    '#f0885a',
                    '#2eb411',
                    '#212121',
                    '#a5a5a5',
                    '#ce3c25',
                    '#ed1ded',
                    '#ac589c',
                    '#df789c',
                  ]}
                  showToolTipText={true}
                  yFormatter={this.formatYAxis}
                  xFormatter={this.formatXAxis}
                />
              </div>
            </div>
            <div>
              <h3>Total de Produtos por Período</h3>
              <StatisticsFilter
                defaultStartDate={moment().add(1, 'days')} // Defaults to the next day
                defaultEndDate={moment().add(1, 'days')}
                handleSearch={searchParams => {
                  this.props.handleGetProductsStatistics(searchParams);
                  // We need to save these dates in our current state to use for navigating to the fulfillments search page when statistics table row is clicked
                  this.setState({
                    productTableCurrentByDateFilters: {
                      startedAt: searchParams.byDate.startedAt,
                      endedAt: searchParams.byDate.endedAt,
                    },
                  });
                }}
                showTimeFilter
                defaultStartTime={moment().hour(0).minute(0).second(0)}
                defaultEndTime={moment().hour(23).minute(59).second(59)}
                loading={this.props.loadingProducts}
              />

              <StatisticsProductTable
                data={this.props.productStatistics}
                onClickRow={product =>
                  this.props.history.push(
                    // TODO: This should use the product ID instead of the name. Required backend change.
                    `/fulfillments?line_item_name=${product.name}&by_date[started_at]=${this.state.productTableCurrentByDateFilters.startedAt}&by_date[ended_at]=${this.state.productTableCurrentByDateFilters.endedAt}`,
                  )
                }
              />
            </div>
            <div>
              <h3>Produtividade por Açougueiro</h3>
              <StatisticsFilter
                defaultStartDate={moment().subtract(7, 'days')}
                defaultEndDate={moment().add(1, 'days')}
                handleSearch={searchParams =>
                  this.props.handleGetPreparationStatistics(searchParams)
                }
                loading={this.props.loadingPreparation}
                showProductFilter={false}
              />

              <StatisticsPreparationTable
                data={this.props.preparationStatistics}
              />
            </div>
            <div>
              <h3>Produtividade por Motorista</h3>
              <StatisticsFilter
                defaultStartDate={moment().subtract(7, 'days')}
                defaultEndDate={moment().add(1, 'days')}
                handleSearch={searchParams =>
                  this.props.handleGetRouteTravelStatistics(searchParams)
                }
                loading={this.props.loadingRouteTravel}
                showProductFilter={false}
              />

              <StatisticsRouteTravelTable
                data={this.props.routeTravelStatistics}
              />
            </div>
            <div>
              <h3>Produtividade de Entrega</h3>
              <StatisticsFilter
                defaultStartDate={moment().subtract(7, 'days')}
                defaultEndDate={moment().add(1, 'days')}
                handleSearch={searchParams =>
                  this.props.handleGetTaskStateStatistics(searchParams)
                }
                loading={this.props.loadingTaskState}
                showDriverFilter={true}
                drivers={this.props.drivers}
              />

              <div className="statistics-chart-container ">
                <LinePlot
                  data={this.props.taskStateStatistics}
                  color={['#eb5b1e', '#2eb411', '#ed1ded']}
                  showToolTipText={true}
                  yFormatter={this.formatYAxis}
                  xFormatter={this.formatXAxis}
                />
              </div>
            </div>
          </div>
        </UnauthenticatedRedirect>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    ordersStatistics: Selectors.currentOrdersStatisticsSelector(state),
    loadingOrders: Selectors.gettingOrdersStatisticsLoadingSelector(state),
    productStatistics: Selectors.currentProductStatisticsSelector(state),
    loadingProducts: Selectors.gettingProductsStatisticsLoadingSelector(state),
    preparationStatistics:
      Selectors.currentPreparationStatisticsSelector(state),
    taskStateStatistics: Selectors.currentTaskStateStatisticsSelector(state),
    loadingPreparation:
      Selectors.gettingPreparationStatisticsLoadingSelector(state),
    loadingTaskState:
      Selectors.gettingTaskStateStatisticsLoadingSelector(state),
    routeTravelStatistics:
      Selectors.currentRouteTravelStatisticsSelector(state),
    loadingRouteTravel:
      Selectors.gettingRouteTravelStatisticsLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    handleGetOrdersStatistics: searchParams => {
      dispatch(Actions.getOrderStatistics(searchParams));
    },
    handleGetProductsStatistics: searchParams => {
      dispatch(Actions.getProductsStatistics(searchParams));
    },
    handleGetPreparationStatistics: searchParams => {
      dispatch(Actions.getPreparationtatistics(searchParams));
    },
    handleGetTaskStateStatistics: searchParams => {
      dispatch(Actions.getTaskStateStatistics(searchParams));
    },
    handleGetRouteTravelStatistics: searchParams => {
      dispatch(Actions.getRouteTravelStatistics(searchParams));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(routeConnector(StatisticsPage)));
