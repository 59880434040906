import jwtDecode from "jwt-decode";

export default function() {
  const token = sessionStorage.getItem("access-token");
  if (token) {
    const id = jwtDecode(token).sub;
    return id;
  } else {
    return null;
  }
}
