// @flow
import React from "react";
// Components
import { Redirect } from "react-router-dom";
// Composers
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// Import Actions
import { logout } from "../actions";
// Selectors
import { loggedInSelector } from "../selectors";
// Types
import type { ContextRouter } from "react-router-dom";
import type { Dispatch } from "../../common";

type RouterProps = ContextRouter;
type Props = { loggedIn: boolean } & RouterProps;

class LogoutPage extends React.Component {
  constructor(props: Props) {
    super(props);

    props.handleLogout();
  }

  render() {
    if (!this.props.loggedIn)
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { message: "Sua sessão foi finalizada com sucesso" }
          }}
        />
      );

    return <div>LOGGING OUT</div>;
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: loggedInSelector(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    handleLogout: () => {
      dispatch(logout());
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LogoutPage)
);
