// @flow
import { createSelector } from "reselect";
import _ from "lodash";
// Types
import type { ReduxState } from "../../types";
import type { StoreState } from "../types";

const thisStateSelector = (state: ReduxState): StoreState => {
  return state.statistics;
};

// Get Orders Statistics Map
export const currentOrdersStatisticsSelector = createSelector(
  thisStateSelector,
  state =>
    state.currentOrderStatisticsList.map(date => state.ordersStatistics[date])
);

// Get the current product statistics root object
const currentProductsStatisticsSelector = createSelector(
  thisStateSelector,
  state => state.productsStatistics[state.currentProductsStatistics]
);

// Get the current product statistics start date selector
export const currentProductStatisticsStartDateSelector = createSelector(
  thisStateSelector,
  currentProductsStatisticsSelector,
  (state, currentStatistics) =>
    currentStatistics ? currentStatistics.startDate : null
);

// Get Products Statistics Map
export const currentProductStatisticsSelector = createSelector(
  thisStateSelector,
  currentProductsStatisticsSelector,
  (state, currentStatistics) =>
    currentStatistics
      ? _.sortBy(
          currentStatistics.statistics.map(
            name => state.productStatistics[name]
          ),
          ["sku"]
        )
      : null
);

/////////////// PREPARATION STATISTICS RELATED

// Get preparation Statistics ids
const currentPreparationStatisticsIdListSelector = createSelector(
  thisStateSelector,
  state => state.currentPreparationStatisticsList
);

// Get Preparation Statistics Map
const currentPreparationStatisticsMapSelector = createSelector(
  thisStateSelector,
  state => state.preparationStatistics
);

export const currentPreparationStatisticsSelector = state => {
  const preparationMap = currentPreparationStatisticsMapSelector(state);
  const preparationIdList = currentPreparationStatisticsIdListSelector(state);

  return preparationIdList.map((id) => preparationMap[id]);
};

/////////////// ROUTE TRAVEL STATISTICS RELATED

// Get Route Travel Statistics ids
const currentRouteTravelStatisticsIdListSelector = createSelector(
  thisStateSelector,
  state => state.currentRouteTravelStatisticsList
);

// Get Route Travel Statistics Map
const currentRouteTravelStatisticsMapSelector = createSelector(
  thisStateSelector,
  state => state.routeTravelStatistics
);

export const currentRouteTravelStatisticsSelector = (state) => {
  const routeTravelMap = currentRouteTravelStatisticsMapSelector(state);
  const routeTravelIdList = currentRouteTravelStatisticsIdListSelector(state);

  return routeTravelIdList.map(id => routeTravelMap[id]);
};

/////////////// TASK STATE STATISTICS RELATED

// Get preparation Statistics ids
const currentTaskStateStatisticsIdListSelector = createSelector(
  thisStateSelector,
  state => state.currentTaskStateStatisticsList
);

// Get Preparation Statistics Map
const currentTaskStateStatisticsMapSelector = createSelector(
  thisStateSelector,
  state => state.taskStateStatistics
);

export const currentTaskStateStatisticsSelector = state => {
  const taskStateMap = currentTaskStateStatisticsMapSelector(state);
  const taskStateIdList = currentTaskStateStatisticsIdListSelector(state);

  return taskStateIdList.map(id => taskStateMap[id]);
};

//////////////////// Loading Selectors //////////////////////

export const gettingOrdersStatisticsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingOrdersStatistics
);

export const gettingProductsStatisticsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingProductsStatistics
);

export const gettingPreparationStatisticsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingPreparationStatistics
);


export const gettingTaskStateStatisticsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingTaskStateStatistics
);
export const gettingRouteTravelStatisticsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingRouteTravelStatistics
);
