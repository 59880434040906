// @flow
import { createAction } from "redux-actions";
// Apis
import Api from "../api";
// Selectors
import * as Selectors from "../selectors";
// Logger
import { logException } from "../../common";
//Types
import type { Dispatch } from "../../common";

// Generate Virtual Routes
export const generateVirtualRoutesRequest = createAction(
  "GENERATE_VIRTUAL_ROUTES_REQUEST"
);
export const generateVirtualRoutes = createAction("GENERATE_VIRTUAL_ROUTES");

export function getVirtualRoutes(vehicleIds, visitIds) {
  return async (dispatch: Dispatch) => {
    dispatch(generateVirtualRoutesRequest());
    try {
      const response = await Api.generateVirtualRoutes(vehicleIds, visitIds);
      dispatch(generateVirtualRoutes(response));
    } catch (err) {
      logException(err);
      dispatch(generateVirtualRoutes(err));
    }
  };
}

export const startAutoRoutingVisits = createAction("START_AUTO_ROUTING_VISITS");

export const stopAutoRoutingVisits = createAction("STOP_AUTO_ROUTING_VISITS");

export const clearVirtualRoutes = createAction("CLEAR_VIRTUAL_ROUTES");

export const removeVirtualRoute = createAction("REMOVE_VIRTUAL_ROUTE");

export const addVirtualRoute = createAction("ADD_VIRTUAL_ROUTE");

export const moveVirtualVisit = createAction("MOVE_VIRTUAL_VISIT");

// Search Visit
export const fetchVisitsRequest = createAction("FETCH_VISITS_REQUEST");
export const fetchVisits = createAction("FETCH_VISITS");

//////////////////////////////////////////////////////////////////
//////////// VISIT RELATED

export function searchVisits(searchParams) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchVisitsRequest());
    try {
      const response = await Api.searchVisits(searchParams);
      dispatch(fetchVisits(response));
    } catch (err) {
      logException(err);
      dispatch(fetchVisits(err));
    }
  };
}

// Get a specific visit
export const fetchVisitRequest = createAction("FETCH_VISIT_REQUEST");
export const fetchVisit = createAction("FETCH_VISIT");

export function findVisit(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchVisitRequest());
    try {
      const response = await Api.getVisit(id);
      dispatch(fetchVisit(response));
    } catch (err) {
      logException(err);
      dispatch(fetchVisit(err));
    }
  };
}

// Create a new visit
export const createVisitRequest = createAction("CREATE_VISIT_REQUEST");
export const createVisit = createAction("CREATE_VISIT");

export function newVisit(params: any) {
  return async (dispatch: Dispatch) => {
    dispatch(createVisitRequest());
    try {
      const response = await Api.createVisit(params);
      dispatch(createVisit(response));
    } catch (err) {
      logException(err);
      dispatch(createVisit(err));
    }
  };
}

// Update a specific visit
export const updateVisitRequest = createAction("UPDATE_VISIT_REQUEST");
export const updateVisit = createAction("UPDATE_VISIT");

export function editVisit(id: number, params: any) {
  return async (dispatch: Dispatch, getState) => {
    const prevVisit = Selectors.visitByIdSelector(getState(), id);
    dispatch(updateVisitRequest({ prevVisit, params }));
    try {
      const { position, ...updatableParams } = { ...params };
      let response = await Api.editVisit(id, updatableParams); // TODO: We should improve this logic to make less requests

      if (
        position &&
        position !== response.entities.visits[response.result].position
      ) {
        // If we want to update the position we need to make a second request
        response = await Api.sortVisit(id, position);
      }

      dispatch(updateVisit(response));

      // If we updated something related to a route such as the visit position or routeId we need to call the route path
      if (
        Object.keys(params).includes("routeId") ||
        Object.keys(params).includes("position")
      ) {
        const routeFilters = Selectors.routeFiltersSelector(getState());
        dispatch(searchRoutes(routeFilters));
      }
    } catch (err) {
      logException(err);
      dispatch(updateVisit(err));
    }
  };
}

// Delete a new visit
export const destroyVisitRequest = createAction("DESTROY_VISIT_REQUEST");
export const destroyVisit = createAction("DESTROY_VISIT");

export function deleteVisit(id) {
  return async (dispatch: Dispatch, getState) => {
    dispatch(destroyVisitRequest());
    try {
      await Api.deleteVisit(id);
      const visit = Selectors.visitByIdSelector(getState(), id);
      dispatch(destroyVisit(visit)); // We pass the visit back since we need to remove its ID from the route to which it belongs
    } catch (err) {
      logException(err);
      dispatch(destroyVisit(err));
    }
  };
}

//////////////////////////////////////////////////////////////////
//////////// ROUTE RELATED

// Edit Route Filters
export const editRouteFilters = createAction("EDIT_ROUTE_FILTERS");

// Search Route
export const fetchRoutesRequest = createAction("FETCH_ROUTES_REQUEST");
export const fetchRoutes = createAction("FETCH_ROUTES");

export function searchRoutes(searchParams) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchRoutesRequest());
    try {
      const response = await Api.searchRoutes(searchParams);
      dispatch(fetchRoutes(response));
    } catch (err) {
      logException(err);
      dispatch(fetchRoutes(err));
    }
  };
}
// Get one route
export const fetchRouteRequest = createAction("FETCH_ROUTE_REQUEST");
export const fetchRoute = createAction("FETCH_ROUTE");

export function findRoute(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchRouteRequest());
    try {
      const response = await Api.getRoute(id);
      dispatch(fetchRoute(response));
    } catch (err) {
      logException(err);
      dispatch(fetchRoute(err));
    }
  };
}
// Estimate third party delivery route
export const estimateThirdPartyDeliveryOrderRequest = createAction(
  "ESTIMATE_THIRD_PARTY_DELIVERY_ORDER_REQUEST"
);
export const estimateThirdPartyDeliveryOrder = createAction("ESTIMATE_THIRD_PARTY_DELIVERY_ORDER");

export function estimateThirdPartyDeliveryRoute(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(estimateThirdPartyDeliveryOrderRequest());
    try {
      const response = await Api.estimateThirdPartyDeliveryRoute(id);
      dispatch(estimateThirdPartyDeliveryOrder(response));
    } catch (err) {
      logException(err);
      dispatch(estimateThirdPartyDeliveryOrder(err));
    }
  };
}
// Start third party delivery route
export const createThirdPartyDeliveryOrderRequest = createAction(
  "CREATE_THIRD_PARTY_DELIVERY_ORDER_REQUEST"
);
export const createThirdPartyDeliveryOrder = createAction("CREATE_THIRD_PARTY_DELIVERY_ORDER");

export function createThirdPartyDeliveryRoute(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(createThirdPartyDeliveryOrderRequest());
    try {
      const response = await Api.createThirdPartyDeliveryRoute(id);
      dispatch(createThirdPartyDeliveryOrder(response));
    } catch (err) {
      logException(err);
      dispatch(createThirdPartyDeliveryOrder(err));
    }
  };
}
// Refresh third party delivery attributes
// export const redoThirdPartyDeliveryOrderRequest = createAction("REDO_THIRD_PARTY_DELIVERY_ORDER_REQUEST");
// export const redoThirdPartyDeliveryOrder = createAction("REDO_THIRD_PARTY_DELIVERY_ORDER");

// export function redoThirdPartyDeliveryRoute(id: string) {
//   return async (dispatch: Dispatch) => {
//     dispatch(redoThirdPartyDeliveryOrderRequest());
//     try {
//       const response = await Api.redoThirdPartyDeliveryRoute(id);
//       dispatch(redoThirdPartyDeliveryOrder(response));
//     } catch (err) {
//       logException(err);
//       dispatch(redoThirdPartyDeliveryOrder(err));
//     }
//   };
// }
// Refresh third party delivery attributes
export const refreshThirdPartyDeliveryOrderRequest = createAction(
  "REFRESH_THIRD_PARTY_DELIVERY_ORDER_REQUEST"
);
export const refreshThirdPartyDeliveryOrder = createAction("REFRESH_THIRD_PARTY_DELIVERY_ORDER");

export function refreshThirdPartyDeliveryRoute(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(refreshThirdPartyDeliveryOrderRequest());
    try {
      const response = await Api.refreshThirdPartyDeliveryRoute(id);
      dispatch(refreshThirdPartyDeliveryOrder(response));
    } catch (err) {
      logException(err);
      dispatch(refreshThirdPartyDeliveryOrder(err));
    }
  };
}

// Cancel third party delivery order
export const cancelThirdPartyDeliveryOrderRequest = createAction(
  "CANCEL_THIRD_PARTY_DELIVERY_ORDER_REQUEST"
);
export const cancelThirdPartyDeliveryOrder = createAction("CANCEL_THIRD_PARTY_DELIVERY_ORDER");

export function cancelThirdPartyDeliveryRoute(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(cancelThirdPartyDeliveryOrderRequest());
    try {
      const response = await Api.cancelThirdPartyDeliveryRoute(id);
      dispatch(cancelThirdPartyDeliveryOrder(response));
    } catch (err) {
      logException(err);
      dispatch(cancelThirdPartyDeliveryOrder(err));
    }
  };
}

// Create a new route
export const createRouteRequest = createAction("CREATE_ROUTE_REQUEST");
export const createRoute = createAction("CREATE_ROUTE");

export function newRoute(params: any) {
  return async (dispatch: Dispatch) => {
    dispatch(createRouteRequest());
    try {
      const response = await Api.createRoute(params);
      dispatch(createRoute(response));
    } catch (err) {
      logException(err);
      dispatch(createRoute(err));
    }
  };
}

// Create many new routes
export const createManyRoutesRequest = createAction(
  "CREATE_MANY_ROUTES_REQUEST"
);
export const createManyRoutes = createAction("CREATE_MANY_ROUTES");

export function createRoutes(routesParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch(createManyRoutesRequest());
    try {
      const response = await Api.createManyRoutes(routesParams);
      dispatch(createManyRoutes(response));

      dispatch(stopAutoRoutingVisits());
    } catch (err) {
      logException(err);
      dispatch(createManyRoutes(err));
    }
  };
}

// Update a specific route
export const updateRouteRequest = createAction("UPDATE_ROUTE_REQUEST");
export const updateRoute = createAction("UPDATE_ROUTE");

export function editRoute(id: string, updatableParameters: any) {
  return async (dispatch: Dispatch) => {
    dispatch(updateRouteRequest());
    try {
      const response = await Api.editRoute(id, updatableParameters);
      dispatch(updateRoute(response));
    } catch (err) {
      logException(err);
      dispatch(updateRoute(err));
    }
  };
}

// Move a specific route (sorting related)
export const moveRouteRequest = createAction("MOVE_ROUTE_REQUEST");
export const moveRoute = createAction("MOVE_ROUTE");

export function sortRoute(id: string, desiredPosition: number) {
  return async (dispatch: Dispatch) => {
    dispatch(moveRouteRequest());
    try {
      const response = await Api.moveRoute(id, desiredPosition);
      dispatch(moveRoute(response));
    } catch (err) {
      logException(err);
      dispatch(moveRoute(err));
    }
  };
}

// Delete a new visit
export const destroyRouteRequest = createAction("DESTROY_ROUTE_REQUEST");
export const destroyRoute = createAction("DESTROY_ROUTE");

export function deleteRoute(id) {
  return async (dispatch: Dispatch, getState) => {
    dispatch(destroyRouteRequest());
    try {
      await Api.deleteRoute(id);
      dispatch(destroyRoute({ routeId: id }));
    } catch (err) {
      logException(err);
      dispatch(destroyRoute(err));
    }
  };
}

//Reorder visits in route
export const reorderVisitsInRouteRequest = createAction(
  "REORDER_VISITS_IN_ROUTE_REQUEST"
);
export const reorderVisitsInRouteResponse = createAction(
  "REORDER_VISITS_IN_ROUTE_RESPONSE"
);

export function reorderVisitsInRoute(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(reorderVisitsInRouteRequest());
    try {
      const response = await Api.reorderVisitsInRoute(id);
      dispatch(reorderVisitsInRouteResponse(response));
    } catch (err) {
      logException(err);
      dispatch(reorderVisitsInRouteResponse(err));
    }
  };
}

// Reorder the orders that are in preparation queue based on their positions in routes
export const sortPreparationQueueBasedOnRoutesRequest = createAction(
  "SORT_PREPARATION_QUEUE_BASED_ON_ROUTES_REQUEST"
);
export const sortPreparationQueueBasedOnRoutesResponse = createAction(
  "SORT_PREPARATION_QUEUE_BASED_ON_ROUTES_RESPONSE"
);

export function sortPreparationQueueBasedOnRoutes() {
  return async (dispatch: Dispatch, getState) => {
    dispatch(sortPreparationQueueBasedOnRoutesRequest());
    try {
      const response = await Api.sortPreparationQueueBasedOnRoutes(); // TODO: Consider changing this path to a different module

      const searchParams = Selectors.routeFiltersSelector(getState());
      dispatch(searchRoutes(searchParams));

      dispatch(sortPreparationQueueBasedOnRoutesResponse(response));
    } catch (err) {
      logException(err);
      dispatch(sortPreparationQueueBasedOnRoutesResponse(err));
    }
  };
}

//////////////////////////////////////////////////////////////////
//////////// TASK RELATED

// Create a new task
export const createTaskRequest = createAction("CREATE_TASK_REQUEST");
export const createTask = createAction("CREATE_TASK");

export function newTask(params: any) {
  return async (dispatch: Dispatch) => {
    dispatch(createTaskRequest());
    try {
      const response = await Api.createTask(params);
      dispatch(createTask(response));
    } catch (err) {
      logException(err);
      dispatch(createTask(err));
    }
  };
}

// Edit a new task
export const updateTaskRequest = createAction("UPDATE_TASK_REQUEST");
export const updateTask = createAction("UPDATE_TASK");

export function editTask(id, params) {
  return async (dispatch: Dispatch) => {
    dispatch(updateTaskRequest());
    try {
      const response = await Api.editTask(id, params);
      dispatch(updateTask(response));
    } catch (err) {
      logException(err);
      dispatch(updateTask(err));
    }
  };
}

// Delete a new task
export const destroyTaskRequest = createAction("DESTROY_TASK_REQUEST");
export const destroyTask = createAction("DESTROY_TASK");

export function deleteTask(taskId) {
  return async (dispatch: Dispatch, getState) => {
    dispatch(destroyTaskRequest());
    try {
      Api.deleteTask(taskId);
      const task = Selectors.taskByIdSelector(getState(), taskId);
      dispatch(destroyTask({ visitId: task.visitId, taskId }));
    } catch (err) {
      logException(err);
      dispatch(destroyTask(err));
    }
  };
}

//////////////////////////////////////////////////////////////////
//////////// DRIVER RELATED

export const fetchDriversRequest = createAction("FETCH_DRIVERS_REQUEST");
export const fetchDrivers = createAction("FETCH_DRIVERS");

export function searchDrivers(searchParams) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchDriversRequest());
    try {
      const response = await Api.searchDrivers(searchParams);
      dispatch(fetchDrivers(response));
    } catch (err) {
      logException(err);
      dispatch(fetchDrivers(err));
    }
  };
}

//////////////////////////////////////////////////////////////////
//////////// VEHICLE RELATED

export const fetchVehiclesRequest = createAction("FETCH_VEHICLE_REQUEST");
export const fetchVehicles = createAction("FETCH_VEHICLE");

export function searchVehicles(searchParams) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchVehiclesRequest());
    try {
      const response = await Api.searchVehicles(searchParams);
      dispatch(fetchVehicles(response));
    } catch (err) {
      logException(err);
      dispatch(fetchVehicles(err));
    }
  };
}

//////////////////////////////////////////////////////////////////
//////////// TIME WINDOW RELATED

export const updateTimeWindowRequest = createAction(
  "UPDATE_TIME_WINDOW_REQUEST"
);
export const updateTimeWindow = createAction("UPDATE_TIME_WINDOW");

export function editTimeWindow(id: string, updatableParameters) {
  return async (dispatch: Dispatch) => {
    dispatch(updateTimeWindowRequest());
    try {
      const response = await Api.editTimeWindow(id, updatableParameters);
      dispatch(updateTimeWindow(response));
    } catch (err) {
      logException(err);
      dispatch(updateTimeWindow(err));
    }
  };
}

//////////////////////////////////////////////////////////////////
//////////// SHIPPING RATE RELATED

export const updateShippingRateRequest = createAction(
  'UPDATE_SHIPPING_RATE_REQUEST',
);
export const updateShippingRate = createAction('UPDATE_SHIPPING_RATE');

export function editShippingRate(timeWindow, updatableParameters) {
  return async (dispatch: Dispatch) => {
    dispatch(updateShippingRateRequest());
    try {
      const response = await Api.editShippingRate(
        timeWindow.shippingRateId,
        updatableParameters,
      );
      dispatch(
        updateShippingRate({
          ...response,
          timeWindowId: timeWindow.id,
        }),
      );
    } catch (err) {
      logException(err);
      dispatch(updateShippingRate(err));
    }
  };
}


//////////////////////////////////////////////////////////////////
//////////// ADDRESS RELATED

export const createAddress = createAction("CREATE_ADDRESS");
export const createAddressRequest = createAction("CREATE_ADDRESS_REQUEST");

export function newAddress(updatableParameters, visitId) {
  return async (dispatch: Dispatch) => {
    dispatch(createAddressRequest());
    try {
      const response = await Api.createOrEditAddress(
        updatableParameters,
        visitId
      );
      dispatch(createAddress(response));
      if (!updatableParameters.id) {
        // After creating an address we need to associate it to the visit
        dispatch(editVisit(visitId, { addressId: response.result }));
      }
    } catch (err) {
      logException(err);
      dispatch(createAddress(err));
    }
  };
}

//////////////////////////////////////////////////////////////////
//////////// LOCATIONS RELATED

export const getMostRecentLocationsForAllDriversResponse = createAction(
  "GET_MOST_RECENT_LOCATIONS_FOR_ALL_DRIVERS_RESPONSE"
);
export const getMostRecentLocationsForAllDriversRequest = createAction(
  "GET_MOST_RECENT_LOCATIONS_FOR_ALL_DRIVERS_REQUEST"
);

export function getMostRecentLocationsForAllDrivers() {
  return async (dispatch) => {
    dispatch(getMostRecentLocationsForAllDriversRequest());
    try {
      const response = await Api.getMostRecentLocationsForAllDrivers();
      dispatch(getMostRecentLocationsForAllDriversResponse(response));
    } catch (err) {
      logException(err);
      dispatch(getMostRecentLocationsForAllDriversResponse(err));
    }
  };
}

export const getLocationsByVisitResponse = createAction(
  "GET_LOCATIONS_BY_VISIT_RESPONSE"
);
export const getLocationsByVisitRequest = createAction(
  "GET_LOCATIONS_BY_VISIT_REQUEST"
);

export function getLocationsByVisit(visitId) {
  return async (dispatch) => {
    dispatch(getLocationsByVisitRequest());
    try {
      const response = await Api.getLocationsByVisit(visitId);
      dispatch(getLocationsByVisitResponse(response));
    } catch (err) {
      logException(err);
      dispatch(getLocationsByVisitResponse(err));
    }
  };
}
