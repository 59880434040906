// @flow
const notificationHelper = (title, options = {}) => {
  let notification = {};
  // Wrapper based on https://developer.mozilla.org/en-US/docs/Web/API/notification
  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.error("This browser does not support notifications.");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    notification = new Notification(title, options);
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        notification = new Notification(title, options);
      }
    });
  }

  return notification;
};

export { notificationHelper };
