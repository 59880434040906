// @flow
import React, { Component } from "react";
// Components
import { Link } from "react-router-dom";
// Style
import "./HamburgerMenu.css";

class HamburgerMenu extends Component {
  render() {
    return (
      <div className="hamburger-menu-main">
        <Link to={"/fulfillments/sort"}>Ordenador</Link>
        <Link to={"/routes"}>Roteirizador</Link>
        <Link to={"/statistics"}>Estatísticas</Link>
        <Link to="/logout">Logout</Link>
      </div>
    );
  }
}

export default HamburgerMenu;
