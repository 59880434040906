// @flow
import React, { useState } from "react";
// Components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MaterialButton from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

const CancelationReasonDialog = ({
    orderCancelationDialogIsOpen,
    handleOnCloseOrderCancelationDialog,
    handleOnConfirmOrderCancelationDialog,
}) => {
    const possibleReasons = [
        "Suspeita de fraude",
        "Cancelamento solicitado pelo cliente",
        "Produto em falta",
        "Pagamento não recebido",
        "Erro do Call Center",
        "O pedido foi refeito",
        "Produção parada por motivos maiores",
        "Produção fora do horário de funcionamento"
    ]

    const [cancelationReason, setCancelationReason] = useState(possibleReasons[0]);
    const [additionalInformation, setAdditionalInformation] = useState('');

    const reasonWithAdditionalInformation = () => {
        return `${cancelationReason}${additionalInformation ? ` - ${additionalInformation}` : ""}`
    };

    return (
        <Dialog
            open={orderCancelationDialogIsOpen}
            onClose={handleOnCloseOrderCancelationDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Qual a razão do cancelamento?
            </DialogTitle>
            <DialogContent>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 500 }}>
                    <InputLabel id="cancelation-reason-label" style={styles.inputLabel}>
                        Razão do cancelamento
                    </InputLabel>
                    <Select
                        labelId="cancelation-reason-label"
                        id="cancelation-reason-select"
                        value={cancelationReason}
                        onChange={(e) => setCancelationReason(e.target.value)}
                        label="Selecione o motivo do cancelamento"
                    >
                        {possibleReasons.map(reason => <MenuItem value={reason}>{reason}</MenuItem>)}
                    </Select>
                </FormControl>
                <DialogContentText display="block" id="alert-dialog-description" style={styles.paragraph}>
                    Forneça informações adicionais (ex., se o pedido foi refeito, informe o número do novo
                    pedido, se produto(s) estiver(em) em falta, informe o(s) produto(s), etc.).
                </DialogContentText>
                <TextField id="standard-basic" style={styles.additionalInformation}
                    label="Informações adicionais" value={additionalInformation} variant="standard"
                    onChange={(e) => setAdditionalInformation(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <MaterialButton
                    onClick={handleOnCloseOrderCancelationDialog}
                    color="primary"
                >
                    Voltar
                </MaterialButton>
                <MaterialButton
                    onClick={() => handleOnConfirmOrderCancelationDialog(reasonWithAdditionalInformation())}
                    color="secondary"
                >
                    Confirmar cancelamento
                </MaterialButton>
            </DialogActions>
        </Dialog>
    );
};

const styles = {
    inputLabel: {
        minWidth: "200px"
    },
    paragraph: {
        marginTop: "2rem",
        marginBottom: "3px"
    },
    additionalInformation: {
        width: "100%"
    }
}

export default CancelationReasonDialog;