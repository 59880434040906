// @flow
import React, { useState, useEffect } from "react";
import _ from "lodash";
// Components
import GoogleMapReact from "google-map-react";
import VisitDefaultMapIcon from "./map/VisitDefaultMapIcon";
import VisitDeliveredMapIcon from "./map/VisitDeliveredMapIcon";
import VisitFailedMapIcon from "./map/VisitFailedMapIcon";
import DistributionCenterMapIcon from "./map/DistributionCenterMapIcon";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import MapFilterMenu from "./MapFilterMenu";
// Helpers
import {
  isTimeWithinBounds,
  distributionCenterGeoLocation,
} from "../../common";
// Styles
import "./MapColumn.css";

import runtimeEnv from "@avantsoft/js-runtime-env";
const env = runtimeEnv();

const VehicleMapIcon = ({ color, index, driver, infoAtMoment, currentRoute }) => (
  <div className="map-column-vehicle-marker">
    <span data-tip="" data-for={`vehicle-${index}-position`}>
      <ReactTooltip id={`vehicle-${index}-position`}>
        <p className="map-column-vehicle-pin-driver-info">{driver.name}</p>
        <p className="map-column-vehicle-pin-driver-info">{`Velocidade: ${infoAtMoment.speed} km/h`}</p>
        <p className="map-column-vehicle-pin-driver-info">{`Última atualização: ${moment(
          infoAtMoment.mClientTime
        )
          .utcOffset("-0300")
          .format("HH:mm")}`}</p>
        {currentRoute && currentRoute.startedAt && <p className="map-column-vehicle-pin-driver-info">Previsão do horário de retorno: {moment(currentRoute.startedAt).utcOffset("-0300").add(currentRoute.totalDeliveryTime, "seconds").format("HH:mm")}</p>}
      </ReactTooltip>
      <i
        className="fa fa-truck"
        style={{
          color: color,
          transform: "translate(-50%, -50%)",
          backgroundOrigin: "initial",
        }}
        aria-hidden="true"
      ></i>
    </span>
  </div>
);

const MapColumn = ({
  visits,
  routes,
  virtualRoutes,
  colors,
  virtualRoutesColors,
  timeWindowById,
  addressById,
  onHoverVisitPin,
  locationsByDriverId,
  drivers,
  showDrivers,
  selectedVisitsId,
  selectedRouteId,
  showFilterMenu,
  driversShow,
  setDriversShow,
  handleSetFilters,
  autoRoutingInProgress,
}) => {
  const [filters, setFilters] = useState({
    morningVisitsShow: true,
    afternoonVisitsShow: true,
    eveningVisitsShow: true,
    unroutedVisitsShow: true,
    pendingVisitsShow: true,
    inProgressVisitsShow: true,
    failedVisitsShow: true,
    successVisitsShow: true,
  });

  const [googleMap, setGoogleMap] = useState(null);
  const [currentPolylines, setCurrentPolylines] = useState([]);
  const [polylineShow, setPolylineShow] = useState(false);

  const updateFilters = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  useEffect(() => {
    handleSetFilters(!Object.values(filters).every((filter) => filter));
  }, [filters]);

  useEffect(() => {
    if (googleMap) {
      // Remove all existing polylines from map
      if (currentPolylines.length > 0) {
        currentPolylines.map((p) => p.setMap(null));
      }

      if (!polylineShow) return;

      const polylines = [];
      routes.map((r, ind) => {
        if (!r.polyline) return;
        const polyline = new googleMap.maps.Polyline({
          path: googleMap.maps.geometry.encoding.decodePath(r.polyline),
          strokeColor: colors[ind],
          strokeOpacity:
            !selectedRouteId || selectedRouteId === r.id ? 0.8 : 0.2,
          strokeWeight: 2,
        });
        polyline.setMap(googleMap.map); // Add polyline to map
        polylines.push(polyline); // Save polyline in our local array
        setCurrentPolylines(polylines); // Overwrite current polyline list
      });
    }
  }, [routes, colors, visits, polylineShow]);

  const filterVisitByState = (visit) => {
    // Always show the visit that is in the routing process and is selected
    if (autoRoutingInProgress && _.includes(selectedVisitsId, visit.id))
      return true;

    if (!visit.routeId && !filters.unroutedVisitsShow) return false;
    if (visit.state === "pending" && !filters.pendingVisitsShow) return false;
    if (visit.state === "in_progress" && !filters.inProgressVisitsShow)
      return false;
    if (visit.state === "failed" && !filters.failedVisitsShow) return false;
    if (visit.state === "success" && !filters.successVisitsShow) return false;

    return true;
  };

  return (
    <>
      {showFilterMenu ? (
        <MapFilterMenu
          filters={filters}
          updateFilters={updateFilters}
          driversShow={driversShow}
          setDriversShow={setDriversShow}
          polylineShow={polylineShow}
          setPolylineShow={setPolylineShow}
        />
      ) : null}

      <div className="map-column-container">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: env.REACT_APP_GCP_API_KEY,
            libraries: "geometry,places", // geometry library is required to decode polylines
          }}
          defaultCenter={{ lat: -12.9090308, lng: -38.4480927 }}
          defaultZoom={11}
          onChildMouseEnter={(key, childProps) => {
            childProps.visit && onHoverVisitPin(childProps.visit.id);
          }}
          onChildMouseLeave={() => {
            onHoverVisitPin(null);
          }}
          yesIWantToUseGoogleMapApiInternals // Required to access googleMaps API directly for polylines
          onGoogleApiLoaded={({ map, maps }) => setGoogleMap({ map, maps })}
        >
          <DistributionCenterMapIcon
            visit={{}}
            lat={distributionCenterGeoLocation("pituba").lat}
            lng={distributionCenterGeoLocation("pituba").lng}
          />

          {showDrivers &&
            drivers.map((driver, index) => {
              const locations = locationsByDriverId(driver.id);
              if (!locations.length) return null;
              const driverIsInRoute =
                _.findIndex(routes, (r) => r.id === locations[0].routeId) >= 0;
              const driverCurrentRoute = driverIsInRoute ? routes.find(route => route.driver===driver.id && route.state==="in_progress") : null;
              return (
                <VehicleMapIcon
                  key={index}
                  index={index}
                  driver={driver}
                  infoAtMoment={locations[0]}
                  lat={locations[0].latitude}
                  lng={locations[0].longitude}
                  currentRoute={driverCurrentRoute}
                  color={
                    driverIsInRoute
                      ? colors[
                      _.findIndex(
                        routes,
                        (r) => r.id === locations[0].routeId
                      )
                      ]
                      : "#000"
                  }
                />
              );
            })}

          {visits.map((visit, index) => {
            if (!filterVisitByState(visit)) return null;

            const address = addressById(visit.address);

            const timeWindow = timeWindowById(visit.timeWindow);
            const endTime = moment(timeWindow.endTime).format("HH:mm");

            if (
              !filters.morningVisitsShow &&
              isTimeWithinBounds(endTime, "0:00", "13:00")
            ) {
              return null;
            }

            if (
              !filters.afternoonVisitsShow &&
              isTimeWithinBounds(endTime, "13:00", "17:00")
            ) {
              return null;
            }

            if (
              !filters.eveningVisitsShow &&
              isTimeWithinBounds(endTime, "17:00", "23:59")
            ) {
              return null;
            }

            if (!address) return null;
            const indexOfRouteToWhichVisitBelongs = _.findIndex(routes, (r) =>
              _.find(r.visits, (v) => v === visit.id)
            );
            const indexOfVirtualRouteToWhichVisitBelongs =
              virtualRoutes &&
              _.findIndex(virtualRoutes, (r) =>
                _.find(r.visits, (v) => v === visit.id)
              );
            const mapIconProps = {
              key: index,
              lat: address.latitude,
              lng: address.longitude,
              visit: visit,
              // Note: Choose the color and then choose the transparency
              color:
                (indexOfRouteToWhichVisitBelongs > -1
                  ? colors[indexOfRouteToWhichVisitBelongs]
                  : indexOfVirtualRouteToWhichVisitBelongs > -1
                    ? virtualRoutesColors[indexOfVirtualRouteToWhichVisitBelongs]
                    : "#eb5b1e") +
                (!selectedVisitsId.length ||
                  _.includes(selectedVisitsId, visit.id)
                  ? "ff"
                  : "23"),
            };
            if (visit.state === "failed") {
              return <VisitFailedMapIcon {...mapIconProps} />;
            } else if (visit.state === "success") {
              return <VisitDeliveredMapIcon {...mapIconProps} />;
            } else {
              return <VisitDefaultMapIcon {...mapIconProps} />;
            }
          })}
        </GoogleMapReact>
      </div>
    </>
  );
};

export default MapColumn;
