// @flow
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';
//Helpers
import {
  translateAasm,
  ColorBar,
  truncateString,
  quantityConverter,
} from '../../common';
// Components
import { Link } from 'react-router-dom';
import WeightLimitedIcon from './WeightLimitedIcon';
// Types
import type { Fulfillment } from '../types';
//Styles
import './FulfillmentItem.css';
import PrintSaleReceiptIcon from './PrintSaleReceiptIcon';

type Props = {
  fulfillment: Fulfillment,
};

class FulfillmentItem extends React.Component {
  props: Props;

  render() {
    const fulfillment = this.props.fulfillment;
    const deadlineAlert =
      moment(fulfillment.endTime)
        .utcOffset('-0300')
        .subtract(60, 'm')
        .isSameOrBefore(moment()) &&
      fulfillment.state !== 'ready' &&
      fulfillment.state !== 'canceled';
    return (
      <div className="fulfillment-item-main">
        <hr className="fulfillment-item-line" />
        <div className="fulfillment-item-container">
          <Link
            className={
              fulfillment.flagged
                ? 'fulfillment-item-content fulfillment-flagged'
                : 'fulfillment-item-content'
            }
            to={`/fulfillments/${fulfillment.number}`}
          >
            <div className="fulfillment-item-order-number-and-weight">
              <i
                className={`fa ${
                  fulfillment.address.cnpj ? 'fa-building' : 'fa-user'
                } 
                fulfillment-item-hidden-on-mobile 
                fulfillment-item-user-or-company-icon`}
                title={`${
                  fulfillment.address.cnpj
                    ? 'Pedido de pessoa jurídica'
                    : 'Pedido de pessoa física'
                }`}
              />
              <p>{fulfillment.number}</p>
              <div className="fulfillment-item-print-sale-receipt">
                <PrintSaleReceiptIcon
                  shouldPrintSaleReceipt={fulfillment.shouldPrintSaleReceipt}
                />
              </div>
              <div className="fulfillment-item-weight-limited">
                <WeightLimitedIcon weightLimited={fulfillment.weightLimited} />
              </div>
            </div>
            <p className="fulfillment-item-hidden-on-mobile fulfillment-item-content-big">
              Criado / Alt.:{' '}
              {moment(fulfillment.createdAt).utcOffset('-0300').format('LT')} /{' '}
              {moment(fulfillment.updatedAt).utcOffset('-0300').format('LT')}
            </p>
            <p className="fulfillment-item-hidden-on-mobile fulfillment-item-content-big">
              Bairro:{' '}
              {truncateString(
                _.upperCase(fulfillment.address.neighborhood),
                15,
              )}
            </p>
            <p className="fulfillment-item-content-medium">
              {`${quantityConverter(
                fulfillment.totalWeight,
                'g',
              )} / ${quantityConverter(
                fulfillment.totalUnits,
                'un',
              )} / ${Math.floor(fulfillment.estimatedPreparationTime)}min`}
            </p>
            {fulfillment.startTime && fulfillment.endTime ? (
              <p
                className={`fulfillment-item-content-large ${
                  deadlineAlert ? 'fulfillment-item-content-bold' : null
                }`}
              >
                Entrega:{' '}
                {moment(fulfillment.endTime).utcOffset('-0300').fromNow()}
                {' das '}
                {moment(fulfillment.startTime).utcOffset('-0300').format('LT')}
                {' às '}
                {moment(fulfillment.endTime).utcOffset('-0300').format('LT')}
              </p>
            ) : (
              <p className="fulfillment-item-content-large">
                Entrega: {moment(fulfillment.deadline).fromNow()}
              </p>
            )}
            <p className="fulfillment-item-hidden-on-mobile fulfillment-item-content-small">
              Centro:{' '}
              {fulfillment.distributionCenter
                ? fulfillment.distributionCenter.toUpperCase()
                : ' -- '}
            </p>
            <p className="fulfillment-item-status fulfillment-item-content-medium">
              {translateAasm(fulfillment.state)}
            </p>
          </Link>
          <ColorBar
            needsAttention={fulfillment.needsAttention}
            routeId={fulfillment.routeId}
            error={fulfillment.state}
          />
        </div>
      </div>
    );
  }
}

export default FulfillmentItem;
