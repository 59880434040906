// @flow
import React from "react";
// Composers
import { issueSourcesConnector } from "../../issue-sources";
// Components
import { Button } from "../../common";
// Types
import type { IssueSource } from "../../issue-sources";
// Styles
import "./IssueCreator.css";

type Props = {
  handleCreateIssue: (issueSourceId: number, details: string) => void,
  creatingLineItemIssueLoading: boolean,

  // From Issue Sources Connector
  issueSources: IssueSource[],
  issueSourcesLoading: boolean,
  issueSourceById: (id: number) => IssueSource
};

type State = {
  issueSourceId: string,
  details: string,
  shouldShow: boolean // Should we show the creation menu?
};

const initialState = {
  issueSourceId: "",
  details: "",
  shouldShow: false
};

class IssueList extends React.Component {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = initialState;
  }

  render() {
    if (!this.state.shouldShow) {
      return (
        <div className="issue-creator-add">
          <Button
            text="Adicionar Erro"
            buttonType="button-tertiary"
            loading={false}
            onClick={() =>
              this.setState((prevState, props) => ({
                ...prevState,
                shouldShow: true
              }))
            }
          />
        </div>
      );
    }

    return (
      <div className="issue-creator">
        <h3 className="issue-title">Criador de erro</h3>
        <div className="issue-creator-main">
          <div>
            <div>Categoria </div>
            <select
              value={this.state.issueSourceId}
              onChange={e => {
                const value = e.target.value;
                this.setState((prevState, props) => ({
                  ...prevState,
                  issueSourceId: value
                }));
              }}
            >
              <option value="" />

              {this.props.issueSources.map(source =>
                source.type === "IssueSource::CustomerApprovalNeeded" ? (
                  <option key={source.id} value={source.id}>
                    {source.name} &#128222;
                  </option>
                ) : (
                  <option key={source.id} value={source.id}>
                    {source.name}
                  </option>
                )
              )}
            </select>
          </div>

          <div className="issue-input">
            <div>Detalhes</div>
            <input
              type="text"
              className=""
              placeholder="Ex: A mercadoria que temos em estoque esta com problema."
              value={this.state.details}
              onChange={e => {
                const value = e.target.value;
                this.setState((prevState, props) => ({
                  ...prevState,
                  details: value
                }));
              }}
            />
          </div>

          <Button
            text="Adicionar"
            buttonType="button-tertiary"
            loading={this.props.creatingLineItemIssueLoading}
            disabled={!this.state.issueSourceId} // Disable unless an option is selected
            onClick={() =>
              this.props.handleCreateIssue.bind(this)(
                this.state.issueSourceId,
                this.state.details
              )
            }
          />
        </div>
      </div>
    );
  }
}

export default issueSourcesConnector(IssueList);
