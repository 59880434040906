// @flow
import React from 'react';
// Components
import IssueList from '../components/IssueList';
import IssueCreator from './IssueCreator';
import EditOrResetInput from '../components/EditOrResetInput';
import PortioningSizeText from '../components/PortioningSizeText';
import SwitchGroup from '../../common/components/SwitchGroup';
// Composers
import { connect } from 'react-redux';
// Helpers
import { quantityConverter } from '../../common';
// Images
import NoPreparationImages from '../../fulfillments/images/noPreparationImages.png';
// Import Actions
import {
  editLineItem,
  resolveLineItemIssue,
  createLineItemIssue,
} from '../actions';
// Selectors
import {
  updatingLineItemLoadingSelector,
  removingLineItemIssueSelector,
  creatingLineItemIssueSelector,
} from '../selectors';
// Types
import type {
  LineItem as LineItemType,
  UpdatableLineItem,
  Mix,
} from '../types';
import type { Dispatch } from '../../common';
import NoImage from '../images/noImageChicken.png';
//Styles
import './LineItem.css';
import ShowAllPreparationImagesModal from '../components/ShowAllPreparationImagesModal';

type Props = {
  lineItem: LineItemType,
  mixes: Mix[],

  // From connector
  updateLoading: boolean,
  handleUpdate: (updatableParameters: UpdatableLineItem) => void,
  resolveLineItemIssue: (id: number) => void,
};

class LineItem extends React.Component {
  props: Props;

  constructor() {
    super();
    this.state = { showAllImagesModal: false };
  }

  handleCloseImagesModal() {
    this.setState({ showAllImagesModal: false });
  }

  handleConfirm(onConfirm: () => void) {
    const decision = window.confirm(
      'Cuidado! Esse item não aparecerá na estações para ser produzido. Tem certeza que deseja continuar?',
    );

    if (decision) {
      onConfirm();
    }
  }

  render() {
    const lineItem = this.props.lineItem; //TODO: Sort this for mixId
    const handleSwitchStarred = starred => {
      this.props.handleUpdate({
        starred,
      });
    };
    const handleSwitchShouldPrepare = shouldPrepare => {
      this.props.handleUpdate({
        shouldPrepare,
      });
    };
    const lineItemMix = this.props.mixes.find(mix => mix.id === lineItem.mixId);
    return (
      <div className="line-item-main">
        {lineItem.observation ? (
          <div className="line-item-observation-div">
            <p className="line-item-observation-header-text">Observação: </p>{' '}
            <p className={'line-item-observation '}>{lineItem.observation}</p>
          </div>
        ) : null}
        <div className="line-item-content">
          {lineItem.imageUrl === null ||
          lineItem.imageUrl === undefined ||
          lineItem.imageUrl === '' ? (
            <img src={NoImage} alt="" className="line-item-image" />
          ) : (
            <img src={lineItem.imageUrl} alt="" className="line-item-image" />
          )}
          <p>
            <span>{lineItem.name}</span>

            <PortioningSizeText
              portioningSize={
                lineItemMix && lineItemMix.portioningSize
                  ? lineItemMix.portioningSize
                  : lineItem.portioningSize
              }
              quantityUnit={lineItem.mixId ? 'g' : lineItem.quantityUnit}
              quantityOrdered={
                lineItemMix && lineItemMix.quantity
                  ? lineItemMix.quantity
                  : lineItem.quantityOrdered
              }
            />
          </p>
          <p>
            {lineItem.sku} : {lineItem.originalId}
          </p>

          <div className="line-item-order-details">
            Pedido:{' '}
            {quantityConverter(lineItem.quantityOrdered, lineItem.quantityUnit)}
          </div>
          <ShowAllPreparationImagesModal
            isOpen={this.state.showAllImagesModal}
            onRequestClose={() => this.handleCloseImagesModal()}
            images={lineItem.preparationImages}
            lineItemName={lineItem.name}
          />

          {this.props.lineItem.preparationImages &&
          this.props.lineItem.preparationImages.length > 0 ? (
            <div>
              <img
                onClick={() => this.setState({ showAllImagesModal: true })}
                src={this.props.lineItem.preparationImages[0].url}
                className="line-item-preparation-image"
              />
            </div>
          ) : (
            <div>
              <img
                src={NoPreparationImages}
                className="line-item-preparation-image no-image"
              />
            </div>
          )}

          <div className="line-item-selector-container">
            <div className="line-item-selector">
              <div className="line-item-selector-content">
                Pesado:{' '}
                {quantityConverter(
                  lineItem.quantityActual,
                  lineItem.quantityUnit,
                )}
              </div>
              <EditOrResetInput
                loading={this.props.updateLoading}
                onSubmit={value =>
                  this.props.handleUpdate({ quantityActual: value })
                }
                onReset={value =>
                  this.props.handleUpdate({
                    quantityActual: '0',
                    isProcessed: false,
                  })
                }
              />
            </div>

            <div className="line-item-selector">
              <div className="line-item-selector-content">
                Revisado:{' '}
                {quantityConverter(
                  lineItem.quantityRevised,
                  lineItem.quantityUnit,
                )}
              </div>
              <EditOrResetInput
                loading={this.props.updateLoading}
                onSubmit={value =>
                  this.props.handleUpdate({ quantityRevised: value })
                }
                onReset={value =>
                  this.props.handleUpdate({
                    quantityRevised: '0',
                  })
                }
              />
            </div>
          </div>
          <div className="line-item-pre-prep-and-prep-selectors">
            <SwitchGroup
              label="Pré preparar?"
              help="Este produto deve ser prioritário na pré preparação?"
              states={[this.props.lineItem.starred]}
              setStates={[starred => handleSwitchStarred(starred)]}
            />
            <SwitchGroup
              label="Preparar?"
              help="Este produto deve ser preparado?"
              states={[this.props.lineItem.shouldPrepare]}
              setStates={[
                shouldPrepare => {
                  !shouldPrepare
                    ? this.handleConfirm(() =>
                        handleSwitchShouldPrepare(shouldPrepare),
                      )
                    : handleSwitchShouldPrepare(shouldPrepare);
                },
              ]}
            />
          </div>
        </div>

        <div className="line-item-issue-container">
          <IssueList
            issues={lineItem && lineItem.issues ? lineItem.issues : []}
            handleDeleteIssue={this.props.handleDeleteIssue}
            removingLineItemIssue={this.props.removingLineItemIssueLoading}
          />

          <hr className="issue-divider" />

          <IssueCreator
            handleCreateIssue={this.props.handleCreateLineItemIssue}
            creatingLineItemIssueLoading={
              this.props.creatingLineItemIssueLoading
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    updateLoading: updatingLineItemLoadingSelector(state, props.lineItem.id),
    removingLineItemIssueLoading: (id: number) =>
      removingLineItemIssueSelector(state, id),
    creatingLineItemIssueLoading: creatingLineItemIssueSelector(
      state,
      props.lineItem.id,
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    handleUpdate: (updatableProperties: UpdatableLineItem) => {
      dispatch(editLineItem(stateProps.lineItem.id, updatableProperties));
    },
    handleDeleteIssue: (id: number) => {
      dispatch(resolveLineItemIssue(id));
    },
    handleCreateLineItemIssue: (issueSourceId: number, details: string) => {
      dispatch(
        createLineItemIssue(stateProps.lineItem.id, issueSourceId, details),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineItem);
