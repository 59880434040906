// @flow
import React from 'react';
import _ from 'lodash';
// Components
// Composers
import { connect } from 'react-redux';
// Import Actions
import * as FraudAction from '../actions';
// Selectors
import * as FraudSelectors from '../selectors';
// Types
import type { Dispatch } from '../../common';
import type { OrderFraud as OrderFraudType } from '../types';
//Helpers
import {
  arrayToStringWithReadableSeparators,
  priceConverter,
} from '../../common/commonHelpers';
//Styles
import './OrderFraud.css';

type Props = {
  orderNumber: string,
  // From connector
  order: OrderFraudType,
  getOrder: (orderNumber: string) => void,
};

class OrderFraud extends React.Component {
  constructor(props: Props) {
    super(props);

    if (props.orderNumber.length > 0) {
      props.getOrder(props.orderNumber);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.orderNumber !== this.props.orderNumber) {
      this.props.getOrder(nextProps.orderNumber);
    }
  }

  render() {
    const order = this.props.order;
    if (_.isEmpty(order)) return null;

    // Payment that is currently being used in this order
    const currentPayment = _.last(this.props.payments);
    const creditPayment = _.find(
      this.props.payments,
      payment => payment.type === 'Store Credit',
    );
    const storeCreditSum = _.sumBy(
      _.filter(
        this.props.payments,
        payment =>
          payment.type === 'Store Credit' &&
          payment.status !== 'failed' &&
          payment.status !== 'void',
      ),
      p => parseFloat(p.amount),
    );

    const cards = [];
    // Create list of used cards
    this.props.payments.forEach(i => {
      if (i.lastDigits) cards.unshift(i.lastDigits);
    });

    return (
      <div className="order-fraud-main">
        <h3>Estatísticas do Pedido</h3>

        <div className="address-item-line">
          <div>
            <b>Total do pedido: </b> {priceConverter(order.total)}
          </div>
          <div>
            <b>Créditos BigClub utilizados: </b>{' '}
            {priceConverter(order.appliedBigclubCredit)}
          </div>
          <div>
            <b>Créditos : </b> {priceConverter(order.appliedStoreCredits)}
          </div>
          <div>
            <b>Total após utilização de créditos : </b>{' '}
            {priceConverter(
              order.total -
                order.appliedStoreCredits -
                order.appliedBigclubCredit,
            )}
          </div>
          {order.channel !== 'call_center' &&
          order.channel !== 'started_online_finalized_with_intervention' ? (
            <div>
              <div title={`${this.props.order.device.whois}`}>
                <b>IP da compra: </b> {order.device.ip} <b>Browser: </b>{' '}
                {order.device.browser} <b>Sistema operacional: </b>{' '}
                {order.device.os}{' '}
              </div>

              <div>
                <b>{`IP da compra foi usado por ${
                  order.device.otherUsers.length
                }
                  ${
                    order.device.otherUsers.length > 1
                      ? `usuários ${
                          order.device.otherUsers.length > 10 ? 'incluindo' : ''
                        }`
                      : 'usuário'
                  }: `}</b>
                <span className="order-fraud-item-list">
                  {arrayToStringWithReadableSeparators(
                    order.device.otherUsers.slice(0, 10).map(a => a.email),
                  )}
                </span>
              </div>
            </div>
          ) : order.channel === 'started_online_finalized_with_intervention' ? (
            <div>
              Pedido iniciado pelo <b>site</b> e finalizado pelo{' '}
              <b>call center</b>.
            </div>
          ) : (
            <div>
              Pedido finalizado pelo <b>call center</b>.
            </div>
          )}
          <div>
            <b>Tentativas de pagamento: </b> {order.payments.length}
            {cards.length > 1 ? (
              <span>
                , <b>incluindo:</b> {_.uniq(cards).slice(0, 5).join(', ')}
              </span>
            ) : null}
            .
          </div>
          <div>
            <b>Pedidos completos próximo ao endereço:</b>{' '}
            {order.address.completedDeliveriesNearAddress}
          </div>
          <div>
            <b>Pedidos cancelados próximo ao endereço:</b>{' '}
            {order.address.canceledDeliveriesNearAddress}
          </div>

          {currentPayment ? (
            <div>
              <div>
                <b>Pago com: </b> {currentPayment.type}{' '}
                {currentPayment.lastDigits
                  ? `xxxx-xxxx-xxxx-${currentPayment.lastDigits}`
                  : ``}
                {currentPayment.status == 'pending' &&
                currentPayment.type === 'Pix'
                  ? `(Aguardando pagamento)`
                  : ``}
                {creditPayment && currentPayment !== creditPayment
                  ? ` e Crédito (${priceConverter(storeCreditSum)})`
                  : ''}
              </div>
              <div>
                <b>Letras de segurança: </b>
                {currentPayment.securityChar
                  ? `${currentPayment.securityChar}`
                  : ``}
              </div>

              {/* If the payment was made by credit card */}
              {currentPayment.lastDigits ? (
                <div>
                  <b>
                    {`Cartão usado por ${currentPayment.otherUsers.length} ${
                      currentPayment.otherUsers.length > 1
                        ? 'usuários'
                        : 'usuário'
                    }: `}
                  </b>
                  <span className="order-fraud-item-list">
                    {arrayToStringWithReadableSeparators(
                      currentPayment.otherUsers.map(a => a.email),
                    )}
                  </span>
                </div>
              ) : null}
            </div>
          ) : (
            <div>Sem dados de pagamento.</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    order: FraudSelectors.orderSelector(state),
    payments: FraudSelectors.userOrderPaymentsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    getOrder: (orderNumber: string) => {
      dispatch(FraudAction.getOrder(orderNumber));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderFraud);
