import React from "react";

const VisitDeliveredMapIcon = ({ color }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      viewBox="0 0 512 512"
      style={{
        enableBackground: "new 0 0 512 512",
        position: "absolute",
        transform: "translate(-15px, -30px)",
        backgroundOrigin: "initial"
      }}
    >
      <g>
        <g>
          <g>
            <path
              style={{ fill: `${color}` }}
              d="M291.125,184.458l-24.458,24.458v-102.25C266.667,100.771,261.896,96,256,96s-10.667,4.771-10.667,10.667v102.25
				l-24.458-24.458c-4.167-4.167-10.917-4.167-15.083,0c-4.167,4.167-4.167,10.917,0,15.083l42.667,42.667
				c2.083,2.083,4.813,3.125,7.542,3.125s5.458-1.042,7.542-3.125l42.667-42.667c4.167-4.167,4.167-10.917,0-15.083
				C302.042,180.292,295.292,180.292,291.125,184.458z"
            />
            <path
              style={{ fill: `${color}` }}
              d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.666,506.5
				c1.875,3.396,5.448,5.5,9.334,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021
				C426.667,76.563,350.104,0,256,0z M256,288c-64.698,0-117.333-52.635-117.333-117.333S191.302,53.333,256,53.333
				s117.333,52.635,117.333,117.333S320.698,288,256,288z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default VisitDeliveredMapIcon;
