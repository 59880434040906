// @flow
import React from "react";
//Helpers
import {
  kgAndUnQuantityConverter,
  minutesToHoursAndMinutes,
} from "../../common/";
//Styles
import "./StatisticsRouteTravelTable.css";

type Props = {
  data: any,
};

type State = {};

class StatisticsRouteTravelTable extends React.Component {
  props: Props;
  state: State;

  render() {
    const data = this.props.data;
    return (
      <div className="statistics-route-travel-table-main">
        {data && data[0] ? (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th># Visitas</th>
                  <th># Rotas</th>
                  <th>Km Estimados</th>
                  <th>Tempo em Rota</th>
                  <th className="statistics-route-travel-table-hidden-on-sm"># Kg/Un entregues</th>
                  <th className="statistics-route-travel-table-hidden-on-sm">% Estimado / Real</th>
                </tr>
              </thead>

              {data.map((d, i) => (
                <tbody key={i}>
                  <tr>
                    <td>{d.driverName}</td>
                    <td>{d.visitsCount}</td>
                    <td>{d.routesCount}</td>
                    <td>{Math.trunc(d.estimatedDistance / 1000)}</td>
                    <td>
                      {minutesToHoursAndMinutes(
                        Math.trunc(d.finalDuration / 60)
                      )}
                    </td>
                    <td className="statistics-route-travel-table-hidden-on-sm">
                      {kgAndUnQuantityConverter(
                        d.totalWeight,
                        "g",
                        d.totalUnits
                      )}
                    </td>
                    <td className="statistics-route-travel-table-hidden-on-sm">
                      {Math.trunc(
                        (d.estimatedDistance / d.finalDuration) * 100
                      ) || "-"}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        ) : (
          <div>Sem dados para gerar tabela.</div>
        )}
      </div>
    );
  }
}

export default StatisticsRouteTravelTable;
