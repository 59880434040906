// @flow
import React from "react";
import moment from "moment";
import "moment/locale/pt-br";
// Components
import { Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { ColorBar, quantityConverter } from "../../../common";
import WeightLimitedIcon from "../WeightLimitedIcon";
// Types
import type { Fulfillment } from "../../types";
//Styles
import "./FulfillmentSortItem.css";

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle
});

type Props = {
  fulfillment: Fulfillment,
  isDragDisabled: boolean,
  index: number
};

class FulfillmentSortItem extends React.Component {
  props: Props;

  render() {
    const fulfillment = this.props.fulfillment;
    const deadlineAlert =
      moment(fulfillment.endTime)
        .utcOffset("-0300")
        .subtract(60, "m")
        .isSameOrBefore(moment()) &&
      fulfillment.state !== "ready" &&
      fulfillment.state !== "canceled";
    return (
      <Draggable
        draggableId={`draggable-${fulfillment.number}`}
        index={this.props.index}
        isDragDisabled={this.props.isDragDisabled}
      >
        {(provided, snapshot) => (
          <div
            className="fulfillment-sort-item-main"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <hr className="fulfillment-sort-item-line" />
            <div className="fulfillment-sort-item-container">
              <Link
                className={`fulfillment-sort-item-content`}
                to={`/fulfillments/${fulfillment.number}`}
              >
                <div className="fulfillment-sort-item-order-number">
                  <p>
                    {fulfillment.position} - {fulfillment.number}
                  </p>
                  <WeightLimitedIcon
                    weightLimited={fulfillment.weightLimited}
                  />
                </div>
                <p>Bairro: {fulfillment.address.neighborhood}</p>
                <p className="fulfillment-item-hidden-on-mobile fulfillment-item-content-medium">
                  {`${quantityConverter(
                    fulfillment.totalWeight,
                    "g"
                  )} / ${quantityConverter(
                    fulfillment.totalUnits,
                    "un"
                  )} / ${Math.floor(fulfillment.estimatedPreparationTime)}min`}
                </p>
                <p
                  className={`fulfillment-item-content-large ${
                    deadlineAlert ? "fulfillment-item-content-bold" : null
                  }`}
                >
                  Entrega:{" "}
                  {moment(fulfillment.endTime)
                    .utcOffset("-0300")
                    .fromNow()}
                  {" das "}
                  {moment(fulfillment.startTime)
                    .utcOffset("-0300")
                    .format("LT")}
                  {" às "}
                  {moment(fulfillment.endTime)
                    .utcOffset("-0300")
                    .format("LT")}
                </p>
              </Link>
              <ColorBar
                needsAttention={fulfillment.needsAttention}
                routeId={fulfillment.routeId}
                state={fulfillment.state}
              />
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

export default FulfillmentSortItem;
