// @flow
import React, { useState, useEffect } from 'react';
import { translateAasm } from '../../common';
// Components
//Style
import './EditableTaskItem.css';
//Helpers

const EditableTaskItem = ({ task, handleEditTask, handleDeleteTask }) => {
  const [taskParams, setTaskParams] = useState({});
  useEffect(() => {
    if (task.type === 'GenericTask') {
      setTaskParams({ instructions: task.instructions });
    }
  }, [task.id]);
  if (task.type === 'GenericTask')
    return (
      <div className="editable-task-item-field">
        <input
          placeholder="Instruções: Entregar banner promocional"
          value={taskParams.instructions || ''}
          onChange={e =>
            setTaskParams({ ...taskParams, instructions: e.target.value })
          }
          onBlur={() => handleEditTask({ ...taskParams })}
        />
        <button
          onClick={() => handleDeleteTask(task.id)}
          className={'editable-task-item-button'}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>
    );

  if (task.type === 'DeliverTask') {
    return (
      <div className="editable-task-item-field">
        <p className="editable-task-item-deliver-task-description">
          {task.orderNumber} - {translateAasm(task.fulfillmentState)}{' '}
          {task.fulfillmentPosition && `- Posição ${task.fulfillmentPosition}`}
        </p>
        <button
          onClick={() => handleDeleteTask(task.id)}
          className={'editable-task-item-button'}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>
    );
  }

  return null;
};

export default EditableTaskItem;
