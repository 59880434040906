// @flow
import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

// Components
import {
  InputFieldErrorMessage,
  InputFieldLabel,
  Button,
  citiesNamesHelper as cities,
  forms,
} from '../../common';
import AddressSearch from './AddressSearch';

//Style
import './VisitItemAddressEditor.css';

const MemoizedAddressSearch = memo(AddressSearch);
const { ADDRESS_FORM_FIELDS_CONSTRAINTS } = forms;

const expectedFieldNames = Object.keys(ADDRESS_FORM_FIELDS_CONSTRAINTS);
const VisitItemAddressEditor = ({
  handleCreateOrEditAddress,
  address: visitAddress,
  loading: addressLoading,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, defaultValues, isDirty },
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: _.pickBy(visitAddress, (v, k) =>
      expectedFieldNames.includes(k),
    ),
  });

  const fillAddressFormWithValuesFromSearch = address => {
    Object.entries(address).forEach(([fieldName, newValue]) => {
      if (newValue) {
        setValue(fieldName, newValue);
      }
    });
  };

  const handleAddressSearch = newAddress => {
    if (!newAddress) return;
    fillAddressFormWithValuesFromSearch({
      ...visitAddress,
      address1: `${newAddress.streetType || ''} ${newAddress.address || ''}`,
      address2: `${newAddress.local || ''} ${newAddress.complement || ''}`,
      neighborhood: newAddress.neighborhood || '',
      latitude: newAddress.latitude,
      longitude: newAddress.longitude,
      city: newAddress.city || '',
      zipcode: newAddress.cep || '',
    });
  };

  const handleAddressChange = () => {
    const addressPayload = _.merge(
      { id: (visitAddress && visitAddress.id) || undefined },
      getValues(),
    );

    handleCreateOrEditAddress(addressPayload);
    reset(getValues());
  };

  return (
    <div className="visit-item-main-content">
      <p className="visit-item-main-editor-subtitle">Informações de Endereço</p>
      <form onSubmit={handleSubmit(handleAddressChange)}>
        <div className="address-form-main">
          <div className="address-form-line address-form-line--labelled">
            <InputFieldLabel>Preencher com um endereço</InputFieldLabel>

            <MemoizedAddressSearch onSubmit={handleAddressSearch} />
          </div>
          <div className="address-form-line">
            <div className="address-form-line-content-with-error">
              <InputFieldLabel htmlFor="firstName">Nome</InputFieldLabel>
              <InputFieldErrorMessage
                message={(errors.firstName && errors.firstName.message) || ''}
              />
              <input
                placeholder="Marcos"
                type="text"
                defaultValue={defaultValues.firstName}
                id="firstName"
                {...register(
                  'firstName',
                  ADDRESS_FORM_FIELDS_CONSTRAINTS.firstName,
                )}
              />
            </div>
            <div className="address-form-line-content-with-error">
              <InputFieldLabel htmlFor="lastName">Sobrenome</InputFieldLabel>
              <InputFieldErrorMessage
                message={(errors.lastName && errors.lastName.message) || ''}
              />
              <input
                type="text"
                placeholder="Zirco"
                defaultValue={defaultValues.lastName}
                id="lastName"
                {...register(
                  'lastName',
                  ADDRESS_FORM_FIELDS_CONSTRAINTS.lastName,
                )}
              />
            </div>
          </div>
          <div className="address-form-line">
            <div className="address-form-line-content-with-error">
              <InputFieldLabel htmlFor="address1">Endereço</InputFieldLabel>
              <InputFieldErrorMessage
                message={(errors.address1 && errors.address1.message) || ''}
              />
              <input
                type="text"
                placeholder="Rua Padre José Henrique 4"
                defaultValue={defaultValues.address1}
                id="address1"
                {...register(
                  'address1',
                  ADDRESS_FORM_FIELDS_CONSTRAINTS.address1,
                )}
              />
            </div>
          </div>
          <div className="address-form-line">
            <div className="address-form-line-content-with-error">
              <InputFieldLabel htmlFor="address2">Complemento</InputFieldLabel>

              <InputFieldErrorMessage
                message={(errors.address2 && errors.address2.message) || ''}
              />

              <input
                placeholder="Final de linha de Vale das Pedrinhas"
                defaultValue={defaultValues.address2}
                type="text"
                id="address2"
                {...register(
                  'address2',
                  ADDRESS_FORM_FIELDS_CONSTRAINTS.address2,
                )}
              />
            </div>
          </div>
          <div className="address-form-line">
            <div className="address-form-line-content-with-error">
              <InputFieldLabel htmlFor="neighborhood">Bairro</InputFieldLabel>

              <InputFieldErrorMessage
                message={
                  (errors.neighborhood && errors.neighborhood.message) || ''
                }
              />
              <input
                placeholder="Nordeste"
                type="text"
                defaultValue={defaultValues.neighborhood}
                id="neighborhood"
                {...register(
                  'neighborhood',
                  ADDRESS_FORM_FIELDS_CONSTRAINTS.neighborhood,
                )}
              />
            </div>
            <div className="address-form-line-content-with-error">
              <InputFieldLabel htmlFor="city">Cidade</InputFieldLabel>

              <InputFieldErrorMessage
                message={(errors.city && errors.city.message) || ''}
              />
              <select
                defaultValue={defaultValues.city}
                id="city"
                {...register('city', ADDRESS_FORM_FIELDS_CONSTRAINTS.city)}
              >
                {cities.map((city, i) => {
                  return (
                    <option key={i} value={city}>
                      {city}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="address-form-line-content-with-error">
              <InputFieldLabel htmlFor="zipcode">CEP</InputFieldLabel>

              <InputFieldErrorMessage
                message={(errors.zipcode && errors.zipcode.message) || ''}
              />
              <input
                type="number"
                placeholder="41905400"
                id="zipcode"
                defaultValue={defaultValues.zipcode}
                {...register(
                  'zipcode',
                  ADDRESS_FORM_FIELDS_CONSTRAINTS.zipcode,
                )}
              />
            </div>
          </div>
          <div className="address-form-line">
            <div className="address-form-line-content-with-error">
              <InputFieldLabel htmlFor="phone">Telefone</InputFieldLabel>

              <InputFieldErrorMessage
                message={(errors.phone && errors.phone.message) || ''}
              />
              <input
                placeholder="5571999850000 (apenas números)"
                id="phone"
                type="number"
                defaultValue={defaultValues.phone}
                {...register('phone', ADDRESS_FORM_FIELDS_CONSTRAINTS.phone)}
              />
            </div>
            <div className="address-form-line-content-with-error">
              <InputFieldLabel htmlFor="reference">Referência</InputFieldLabel>

              <InputFieldErrorMessage
                message={(errors.reference && errors.reference.message) || ''}
              />
              <input
                placeholder="Perto do supermercado"
                defaultValue={defaultValues.reference}
                id="reference"
                type="text"
                {...register(
                  'reference',
                  ADDRESS_FORM_FIELDS_CONSTRAINTS.reference,
                )}
              />
            </div>
          </div>

          <div className="address-form-line address-form-line--labelled">
            <InputFieldLabel htmlFor="additionalInformation">
              Informações adicionais
            </InputFieldLabel>

            <textarea
              id="additionalInformation"
              type="text"
              placeholder="Tocar a campanhia quando chegar"
              defaultValue={defaultValues.additionalInformation}
              {...register('additionalInformation')}
            />
          </div>
        </div>

        <div className="visit-item-address-editor-buttons-actions">
          <Button
            text="Salvar Endereço"
            title="Salvar Endereço"
            buttonType="button-primary"
            type="submit"
            disabled={!isDirty || addressLoading}
            loading={addressLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default VisitItemAddressEditor;
