// @flow
import React from "react";
import { CNPJ } from "cpf_cnpj";
// Components
import AddressPositionMap from "./AddressPositionMap";
// Types
import type { UpdatableAddress } from "../types";
//Style
import "./AddressItem.css";
import { PlacementPhotoScroller } from "../../common";
type Props = {
  address: Address,
  orderNumber: Number,
  editingAddressLoading: boolean,
  onConfirmOrRejectPosition: (updatableParameters: UpdatableAddress) => void
};

class AddressItem extends React.Component {
  props: Props;

  copyToClip() {
    // Create html container to store copied information
    // Add initial div tag
    let htmlContainer = "<div>";

    // Add order information
    htmlContainer += `<b>*${this.props.orderNumber}*</b> <br/>
      Nome: <b>${this.props.address.receiverName}</b> <br/>
      Endereço: ${this.props.address.addressLine1} <br/>
      ${
        this.props.address.addressLine2
          ? `Complemento: ${this.props.address.addressLine2} <br/>`
          : ""
      }
      Bairro: ${this.props.address.neighborhood} <br/>
      Cidade: ${this.props.address.city} <br/>
      CEP: ${this.props.address.zipcode} <br/>
      ${
        this.props.address.cnpj
          ? `CNPJ: ${CNPJ.format(this.props.address.cnpj)} <br/>`
          : ""
      }
      ${
        this.props.address.additionalInformation
          ? `Informações adicionais: ${this.props.address.additionalInformation} <br/>`
          : ""
      }
      ${
        this.props.address.reference
          ? `Ponto de referência: ${this.props.address.reference} <br/>`
          : ""
      }
      Telefone: ${this.props.address.telephone} <br/>
    `;

    // Add closing div tag to the html Container
    htmlContainer += "</div>";

    // Now create element in the document who's inner html will be the htmlContainer
    var container = document.createElement("div");
    container.innerHTML = htmlContainer;
    document.body.appendChild(container);

    // Copy html to the clipboard
    window.getSelection().removeAllRanges(); // Remove any selections already on the page
    var range = document.createRange();
    range.selectNode(container);
    window.getSelection().addRange(range); // Select the htmlContainer

    // Call the copy command
    document.execCommand("copy");

    // Finally when the copy has been completed remove the container
    document.body.removeChild(container);

    // Alert the user that the copy has been performed
    alert("O endereço foi copiado!");
  }

  render() {
    return (
      <div className="address-item-main">
        <div className="address-item-header">
          <h3>Endereço do pedido</h3>
          <div className="address-item-map-icon">
            <a
              href={encodeURI(
                `https://www.google.com/maps/search/?api=1&query=${this.props.address.addressLine1},${this.props.address.addressLine2},${this.props.address.neighborhood},${this.props.address.city}`
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-map-marker fa-fw" title="Visualizar local." />
            </a>
          </div>
          <div className="address-item-copy-icon">
            <i
              className="fa fa-copy fa-fw"
              onClick={() => this.copyToClip()}
              title="Copiar endereço para clipboard."
            />
          </div>
        </div>

        {this.props.address !== null ? (
          <div>
            <div className="address-container-flex">
              <div className="address-container-flex-initial-information">
                <div className="address-item-line">
                  <div>
                    <b>
                      {this.props.address.cnpj
                        ? this.props.address.company
                        : null}
                      <br />
                      Nome: {this.props.address.receiverName}
                    </b>
                  </div>
                  <div>Endereço: {this.props.address.addressLine1}</div>
                  <div>Complemento: {this.props.address.addressLine2}</div>
                  <div>Bairro: {this.props.address.neighborhood}</div>
                  <div>Cidade: {this.props.address.city}</div>
                  <div>CEP: {this.props.address.zipcode}</div>
                  <div>
                    {this.props.address.cnpj
                      ? `CNPJ: ${CNPJ.format(this.props.address.cnpj)}`
                      : null}
                  </div>
                </div>

                <div>
                  Informações adicionais:
                  {this.props.address.additionalInformation}
                </div>
                <div>Ponto de referência: {this.props.address.reference}</div>
                <div>Telefone: {this.props.address.telephone}</div>
              </div>
              <div>
                <AddressPositionMap
                  address={this.props.address}
                  onConfirmOrRejectPosition={
                    this.props.onConfirmOrRejectPosition
                  }
                  isLoading={this.props.editingAddressLoading}
                />
              </div>
            </div>
            <PlacementPhotoScroller
              placementPhotos={this.props.address.images}
            />
          </div>
        ) : (
          <div>Sem endereço associado ao pedido.</div>
        )}
      </div>
    );
  }
}

export default AddressItem;
