// @flow
import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';

// Components
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import {
  visitStates,
  truncateString,
  routeStates,
  quantityConverter,
} from '../../common';
// Helpers
import polyline from '@mapbox/polyline';
//Style
import './RouteDetailsModal.css';

const RouteDetailsModal = ({
  handleModalClose,
  route,
  visits,
  handleGetLocationsByVisit,
  locationsByVisitId,
  driverById,
  vehicleById,
  taskById,
  timeWindowById,
}) => {
  useEffect(() => {
    visits.forEach(visit => {
      handleGetLocationsByVisit(visit.id);
    });
  }, []);
  const driver = driverById(route.driver);
  const vehicle = vehicleById(route.vehicle);
  const navigatedCoordinates = [].concat.apply(
    [],
    visits.map(visit => {
      return locationsByVisitId(visit.id).map(location => [
        location.latitude,
        location.longitude,
      ]);
    }),
  );
  return (
    <Modal
      isOpen="true"
      onRequestClose={() => handleModalClose()}
      ariaHideApp={false}
    >
      <div className="route-details-modal-container">
        <h1>Visualizador de Rota</h1>
        <div>
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?size=266x400&path=weight:5%7Cenc:${
              route.polyline
            }&path=weight:5%7Ccolor:green%7Cenc:${polyline.encode(
              navigatedCoordinates,
            )}&key=${process.env.REACT_APP_GCP_API_KEY}`}
            alt=""
          />
          <div>
            <ul>
              {(vehicle && vehicle.licensePlate && (
                <li>
                  <span>
                    <strong>Veículo: </strong>
                    {vehicle.licensePlate}
                  </span>
                </li>
              )) ||
                null}
              {(driver && driver.name && (
                <li>
                  <span>
                    <strong>Motorista: </strong>
                    {driver.name}
                  </span>
                </li>
              )) ||
                null}
              {route.plannedAt && (
                <li>
                  <span>
                    <strong>Planejada para: </strong>
                    {moment(route.plannedAt)
                      .utcOffset('-0300')
                      .format('DD/MM/YYYY HH:mm')}
                  </span>
                </li>
              )}
              {route.startedAt && (
                <li>
                  <span>
                    <strong>Iniciada em: </strong>
                    {moment(route.startedAt)
                      .utcOffset('-0300')
                      .format('DD/MM/YYYY HH:mm')}
                  </span>
                </li>
              )}
              {route.completedAt && (
                <li>
                  <span>
                    <strong>Finalizada em: </strong>
                    {moment(route.completedAt)
                      .utcOffset('-0300')
                      .format('DD/MM/YYYY HH:mm')}
                  </span>
                </li>
              )}
              <li>
                <span>
                  <strong>Estado: </strong>
                  {routeStates(route.state)}
                </span>
              </li>
              <li>
                <span>
                  <strong>Tempo Total de Preparação: </strong>
                  {`${route.totalPreparationTime} min`}
                </span>
              </li>
              {(route.totalWeight && (
                <li>
                  <span>
                    <strong>Peso Total da Rota: </strong>
                    {quantityConverter(route.totalWeight, 'g')}
                  </span>
                </li>
              )) ||
                null}
            </ul>
          </div>
        </div>
        {(route.visits.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Visita</th>
                <th>Tarefas</th>
                <th>Estado</th>
                <th>Horários</th>
                <th>Horário de Chegada</th>
                <th>Horário de Entrega</th>
              </tr>
            </thead>
            <tbody>
              {visits.map(visit => (
                <tr key={visit.id}>
                  <td>{visit.id}</td>
                  <td>
                    <ul>
                      {visit.tasks &&
                        visit.tasks.map(taskId => {
                          const task = taskById(taskId);
                          switch (task.type) {
                            case 'GenericTask':
                              return (
                                <li key={taskId}>
                                  <p key={taskId}>
                                    {`${truncateString(task.instructions, 40)}`}
                                  </p>
                                  {(task.failedReason && (
                                    <p className="route-details-modal-alert">{`FALHA: ${task.failedReason}`}</p>
                                  )) ||
                                    null}
                                </li>
                              );
                            case 'DeliverTask':
                              return (
                                <li key={taskId}>
                                  <p key={taskId}>
                                    <Link
                                      key={taskId}
                                      to={`/fulfillments/${task.orderNumber}`}
                                    >
                                      {task.orderNumber}
                                    </Link>{' '}
                                  </p>
                                </li>
                              );
                          }
                        })}
                    </ul>
                  </td>
                  <td>{visitStates(visit.state)}</td>

                  <td>
                    {`(${moment(timeWindowById(visit.timeWindow).startTime)
                      .utcOffset('-0300')
                      .format('DD/MM')})
                  ${moment(timeWindowById(visit.timeWindow).startTime)
                    .utcOffset('-0300')
                    .format('HH:mm')} - ${moment(
                      timeWindowById(visit.timeWindow).endTime,
                    )
                      .utcOffset('-0300')
                      .format('HH:mm')}`}
                  </td>
                  <td>
                    {(visit.visitedAt &&
                      moment(visit.visitedAt)
                        .utcOffset('-0300')
                        .format('HH:mm')) ||
                      '- -'}
                  </td>
                  <td>
                    {(visit.signedAt &&
                      moment(visit.signedAt)
                        .utcOffset('-0300')
                        .format('HH:mm')) ||
                      '- - '}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )) ||
          null}
      </div>
    </Modal>
  );
};

export default RouteDetailsModal;
