// @flow
import { createSelector } from "reselect";
import _ from "lodash";
// Types
import type { ReduxState } from "../../types";
import type { StoreState } from "../types";

const thisStateSelector = (state: ReduxState): StoreState => {
  return state.routes;
};

////////////////////////////////////////////////////////////////////
//////////////////// VIRTUAL ROUTE RELATED /////////////////////////////////

const virtualRouteMapSelector = createSelector(
  thisStateSelector,
  (state) => state.virtualRoutes
);

export const allVirtualRoutesSelector = (state: StoreState) => {
  const virtualRoutesMap = virtualRouteMapSelector(state);

  return Object.values(virtualRoutesMap);
};

////////////////////////////////////////////////////////////////////
//////////////////// ROUTE RELATED /////////////////////////////////

const routeMapSelector = createSelector(
  thisStateSelector,
  (state) => state.routes
);

const currentRouteListSelector = createSelector(
  thisStateSelector,
  (state) => state.currentRouteList
);

export const routeFiltersSelector = createSelector(
  thisStateSelector,
  (state) => state.routeFilters
);

export const routeListSelector = createSelector(
  thisStateSelector,
  routeMapSelector,
  (state, routeMap) => {
    return _.sortBy(
      state.currentRouteList.map((id) => routeMap[id]),
      ["position"]
    );
  }
);

export const routeByIdSelector = (state: StoreState, id: number) => {
  const routeMap = routeMapSelector(state);
  const route = routeMap[id];

  return route;
};

////////////////////////////////////////////////////////////////////
//////////////////// VISIT RELATED /////////////////////////////////

const visitMapSelector = createSelector(
  thisStateSelector,
  (state) => state.visits
);

export const visitByIdSelector = (state: StoreState, id: ?number) => {
  const visitMap = visitMapSelector(state);

  return visitMap[id] || null;
};

export const unroutedVisitsSelector = (state: StoreState) => {
  const visitMap = visitMapSelector(state);

  return _.filter(Object.values(visitMap), { routeId: null });
};

export const AllVisitsSelector = (state: StoreState) => {
  const visitMap = visitMapSelector(state);

  return Object.values(visitMap);
};

export const visitsOfCurrentRoutesSelector = (state: StoreState) => {
  const visitMap = visitMapSelector(state);
  const routeMap = routeMapSelector(state);
  const currentRouteList = currentRouteListSelector(state);

  const visitIds = currentRouteList.reduce(
    (arr, cur) => [...arr, ...routeMap[cur].visits],
    []
  );

  return visitIds.map((id) => visitMap[id]);
};

export const numberOfImmutableVisitsInRouteSelector = (
  state: StoreState,
  id: number
) => {
  const route = routeByIdSelector(state, id);

  if (!route) return 0;

  const numberOfImmutableVisitsInRoute = route.visits.reduce(
    (acc, visit) =>
      acc +
      !(
        !visitByIdSelector(state, visit).state ||
        visitByIdSelector(state, visit).state === "pending"
      ),
    0
  );

  return numberOfImmutableVisitsInRoute;
};

////////////////////////////////////////////////////////////////////
///////////////////// TASK RELATED /////////////////////////////////

const taskMapSelector = createSelector(
  thisStateSelector,
  (state) => state.tasks
);

export const taskByIdSelector = (state: StoreState, id: ?number) => {
  const taskMap = taskMapSelector(state);

  return taskMap[id] || null;
};

////////////////////////////////////////////////////////////////////
///////////////////// TIME WINDOW RELATED /////////////////////////////////

const timeWindowMapSelector = createSelector(
  thisStateSelector,
  (state) => state.timeWindow
);

export const timeWindowByIdSelector = (state: StoreState, id: ?number) => {
  const timeWindowMap = timeWindowMapSelector(state);

  return timeWindowMap[id] || null;
};

////////////////////////////////////////////////////////////////////
///////////////////// ADDRESS RELATED /////////////////////////////////

const addressMapSelector = createSelector(
  thisStateSelector,
  (state) => state.addresses
);

export const addressByIdSelector = (state: StoreState, id: ?number) => {
  const addressMap = addressMapSelector(state);

  return addressMap[id] || null;
};

////////////////////////////////////////////////////////////////////
///////////////////// LOCATION RELATED /////////////////////////////

const locationMapSelector = createSelector(
  thisStateSelector,
  (state) => state.locations
);

export const locationsByDriverIdSelector = (state: StoreState, driverId) => {
  const locationMap = locationMapSelector(state);
  const locations = _.orderBy(
    _.filter(Object.values(locationMap), { driverId }),
    (l) => l.clientTime,
    ["desc"]
  );

  return locations;
};

export const locationsByVisitIdSelector = (state: StoreState, visitId) => {
  const locationMap = locationMapSelector(state);
  const locations = _.orderBy(
    _.filter(Object.values(locationMap), { visitId }),
    (l) => l.clientTime,
    ["desc"]
  );

  return locations;
};

////////////////////////////////////////////////////////////////////
///////////////////// DRIVER RELATED ///////////////////////////////

const driverMapSelector = createSelector(
  thisStateSelector,
  (state) => state.drivers
);

export const AllDriversSelector = (state: StoreState) => {
  const driverMap = driverMapSelector(state);
  const drivers = Object.values(driverMap);

  return drivers;
};

export const driverByIdSelector = (state: StoreState, id: ?number) => {
  const driverMap = driverMapSelector(state);
  return driverMap[id] || {};
};

////////////////////////////////////////////////////////////////////
///////////////////// VEHICLE RELATED ///////////////////////////////

const vehicleMapSelector = createSelector(
  thisStateSelector,
  (state) => state.vehicles
);

export const AllVehiclesSelector = (state: StoreState) => {
  const vehicleMap = vehicleMapSelector(state);

  return Object.values(vehicleMap);
};

export const vehicleByIdSelector = (state: StoreState, id: ?number) => {
  const vehicleMap = vehicleMapSelector(state);
  return vehicleMap[id] || {};
};

////////////////////////////////////////////////////////////////////
//////////////////// LOADING RELATED ///////////////////////////////

export const estimatingThirdPartyDeliveryRouteSelector = createSelector(
  thisStateSelector,
  (state) => state.loading.estimatingThirdPartyDeliveryRoute
);

export const thirdPartyDeliveryRouteLoadingSelector = createSelector(
  thisStateSelector,
  (state) =>
    state.loading.creatingLoggiRoute || state.loading.updatingLoggiRoute
);

export const routesLoadingSelector = createSelector(
  thisStateSelector,
  (state) =>
    state.loading.gettingRoutes ||
    state.loading.creatingRoute ||
    state.loading.updatingRoute ||
    state.loading.deletingRoute
);

export const isAutoRoutingVisitsSelector = createSelector(
  thisStateSelector,
  (state) => state.autoRoutingVisits.isAutoRouting
);

export const virtualRoutesLoadingSelector = createSelector(
  thisStateSelector,
  (state) => state.loading.gettingVirtualRoutes
);

export const visitsLoadingSelector = createSelector(
  thisStateSelector,
  (state) =>
    state.loading.gettingVisits ||
    state.loading.creatingVisit ||
    state.loading.deletingVisit
);

export const timeWindowSelector = createSelector(
  thisStateSelector,
  (state) => state.loading.editingTimeWindow
);

export const taskSelector = createSelector(
  thisStateSelector,
  (state) =>
    state.loading.creatingTask ||
    state.loading.updatingTask ||
    state.loading.deletingTask
);

export const routeLoadingSelector = createSelector(
  thisStateSelector,
  (state) =>
    state.loading.gettingRoutes ||
    state.loading.creatingRoute ||
    state.loading.updatingRoute
);

export const addressLoadingSelector = createSelector(
  thisStateSelector,
  (state) => state.loading.creatingOrEditingAddress
);

export const locationsLoadingSelector = createSelector(
  thisStateSelector,
  (state) => state.loading.gettingLocations
);
