// @flow
import React from "react";
// Components

// Composers
import { connect } from "react-redux";
// Import Actions
import { findIssueSources } from "../actions";
// Selectors
import "../selectors";
// Types
import type { Dispatch } from "../../common";

type Props = { findIssueSources: () => void };

class IssueSourcesGetter extends React.Component {
  constructor(props: Props) {
    super(props);

    props.findIssueSources();
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    findIssueSources: () => {
      dispatch(findIssueSources());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueSourcesGetter);
