import React, { Component } from "react";
import { connect } from "react-redux";
// Import Actions
import { findUser } from "../actions";
// Selectors
import { userSalesSelector } from "../selectors";
//Style
import "./UserNotes.css";

export class UserNotes extends Component {
  constructor(props) {
    super(props);

    if (this.props.userId) {
      this.props.findUser(this.props.userId);
    }
  }

  render() {
    return (
      <div>
        {this.props.userId && this.props.userSales.notes ? (
          <div className="fulfillment-page-user-notes">
            <h3>Anotações Sobre o Cliente</h3>
            <p>{this.props.userSales.notes}</p>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, props: Props) => {
  return {
    userSales: userSalesSelector(state, props.userId)
  };
};

const mapDispatchToProps = (dispatch, stateProps) => {
  return {
    findUser: (userId: number) => {
      dispatch(findUser(userId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNotes);
