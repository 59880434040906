// @flow
import { createAction } from "redux-actions";
// Types
import type { Dispatch } from "../../common";
// Apis
import Api from "../api";

// Logger
import { logException } from "../../common";

// Search fulfillments
export const fetchIssueSourcesRequest = createAction(
  "FETCH_ISSUE_SOURCES_REQUEST"
);
export const fetchIssueSources = createAction("FETCH_ISSUE_SOURCES");

export function findIssueSources() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchIssueSourcesRequest());
    try {
      const response = await Api.getIndex();
      dispatch(fetchIssueSources(response));
    } catch (err) {
      logException(err);
      dispatch(fetchIssueSources(err));
    }
  };
}
