// @flow
import { handleActions, combineActions } from "redux-actions";
import _ from "lodash";
import { combineReducers } from "redux";
// Actions
import * as Actions from "../actions";
// Types
import type { Reducer } from "redux";
import type { Action } from "../../common";

// The orders that are currently shown in search
const currentList: Reducer<string, Action> = handleActions(
  {
    [combineActions(
      Actions.fetchFulfillments,
      Actions.updateFulfillmentPosition
    )]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state
    }
  },
  []
);

// All orders saves as a Map<>
const fulfillments: Reducer<{ [string]: any }, Action> = handleActions(
  {
    [combineActions(
      Actions.fetchFulfillments,
      Actions.fetchFulfillment,
      Actions.updateFulfillment
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.fulfillments
      }),
      throw: (state, action) => state
    },
    [Actions.updateFulfillmentPosition]: {
      next: (state, action) => ({
        ...action.payload.entities.fulfillments
      })
    },
    [Actions.updateAddress]: {
      next: (state, action) => ({
        ...state,
        [action.payload.orderNumber]: {
          ...state[action.payload.orderNumber],
          address: action.payload.entities.addresses[action.payload.result]
        }
      })
    }
  },
  {}
);

// LINE ITEMS
const lineItems = handleActions(
  {
    [combineActions(
      Actions.fetchFulfillments,
      Actions.fetchFulfillment,
      Actions.updateFulfillment,
      Actions.updateLineItem,
      Actions.updateFulfillmentPosition
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.lineItems
      }),
      throw: (state, action) => state
    },
    [Actions.addLineItemIssue]: {
      next: (state, action) => ({
        ...state,
        [action.payload.lineItemId]: {
          ...state[action.payload.lineItemId],
          issues: [
            ...state[action.payload.lineItemId].issues,
            action.payload.result
          ]
        }
      })
    }
  },
  {}
);

// MIXES
const mixes = handleActions(
  {
    [combineActions(
      Actions.fetchFulfillments,
      Actions.fetchFulfillment,
      Actions.updateFulfillment,
      Actions.updateFulfillmentPosition
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.mixes
      }),
      throw: (state, action) => state
    }
  },
  {}
);

// User notes
const userSales = handleActions(
  {
    [Actions.fetchUserSales]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.users
      }),
      throw: (state, action) => state
    }
  },
  {}
);

// ISSUES (BELONG TO LINE ITEMS)
const issues = handleActions(
  {
    [combineActions(
      Actions.fetchFulfillments,
      Actions.fetchFulfillment,
      Actions.updateFulfillment,
      Actions.updateLineItem,
      Actions.addLineItemIssue,
      Actions.updateFulfillmentPosition,
      Actions.setAsResolvedLineItemIssue
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.issues
      }),
      throw: (state, action) => state
    }
  },
  {}
);

const versions = handleActions(
  {
    [Actions.fetchFulfillmentVersions]: {
      next: (state, action) => ({
        ...state,
        [action.payload.orderNumber]: action.payload.versions
      })
    }
  },
  {}
);

//tracker
const trackers = handleActions(
  {
    [Actions.fetchTracker]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.trackers
      }),
      throw: (state, action) => state
    }
  },
  {}
);

// Loading
const initialLoadingState = {
  gettingFulfillments: false,
  gettingFulfillment: false,
  gettingFulfillmentVersions: false,
  updatingFulfillment: false,
  creatingRouterVisit: false,
  updatingFulfillmentPosition: false,
  updatingLineItems: [],
  deletingLineItemIssue: [],
  creatinglineItemIssue: [],
  editingOrderObservation: false
};
const loading = handleActions(
  {
    [Actions.fetchFulfillmentsRequest]: state => ({
      ...state,
      gettingFulfillments: true
    }),
    [Actions.fetchFulfillments]: state => ({
      ...state,
      gettingFulfillments: false
    }),
    [Actions.fetchFulfillmentRequest]: state => ({
      ...state,
      gettingFulfillment: true
    }),
    [Actions.fetchFulfillment]: state => ({
      ...state,
      gettingFulfillment: false
    }),
    [Actions.fetchFulfillmentVersionsRequest]: state => ({
      ...state,
      gettingFulfillmentVersions: true
    }),
    [Actions.fetchFulfillmentVersions]: state => ({
      ...state,
      gettingFulfillmentVersions: false
    }),
    [Actions.postRouterVisitRequest]: state => ({
      ...state,
      creatingRouterVisit: true
    }),
    [Actions.postRouterVisit]: state => ({
      ...state,
      creatingRouterVisit: false
    }),
    [Actions.updateFulfillmentRequest]: state => ({
      ...state,
      updatingFulfillment: true
    }),
    [Actions.updateFulfillment]: state => ({
      ...state,
      updatingFulfillment: false
    }),
    [Actions.updateFulfillmentPositionRequest]: state => ({
      ...state,
      updatingFulfillmentPosition: true
    }),
    [Actions.updateFulfillmentPosition]: state => ({
      ...state,
      updatingFulfillmentPosition: false
    }),
    [Actions.updateLineItemRequest]: (state, action) => ({
      ...state,
      updatingLineItems: [...state.updatingLineItems, action.payload.lineItemId]
    }),
    [Actions.updateLineItem]: (state, action) => ({
      ...state,
      updatingLineItems: _.filter(
        state.updatingLineItems,
        action.payload.result
      )
    }),
    [Actions.setAsResolvedLineItemIssueRequest]: (state, action) => ({
      ...state,
      deletingLineItemIssue: [
        ...state.deletingLineItemIssue,
        action.payload.issueId
      ]
    }),
    [Actions.setAsResolvedLineItemIssue]: {
      next: (state, action) => ({
        ...state,
        deletingLineItemIssue: _.filter(
          state.deletingLineItemIssue,
          action.payload.issueId
        )
      })
    },
    [Actions.updateOrderObservationRequest]: state => ({
      ...state,
      editingOrderObservation: true
    }),
    [Actions.updateOrderObservation]: state => ({
      ...state,
      editingOrderObservation: false
    }),
    [Actions.addLineItemIssueRequest]: (state, action) => ({
      ...state,
      creatinglineItemIssue: [
        ...state.creatinglineItemIssue,
        action.payload.lineItemId
      ]
    }),
    [Actions.addLineItemIssue]: {
      next: (state, action) => ({
        ...state,
        creatinglineItemIssue: _.filter(
          state.creatinglineItemIssue,
          action.payload.lineItemId
        )
      })
    },
    [Actions.updateAddressRequest]: state => ({
      ...state,
      editingAddress: true
    }),
    [Actions.updateAddress]: state => ({
      ...state,
      editingAddress: false
    })
  },
  initialLoadingState
);

// Errors
// An array with the newest API errors
const errors = handleActions(
  {
    [combineActions(
      Actions.fetchFulfillments,
      Actions.fetchFulfillment,
      Actions.updateFulfillment,
      Actions.postRouterVisit
    )]: {
      throw: (state, action) => [...state, action]
    }
    // [clearOrderErrors]: (state, action) => [],
  },
  []
);

const reducers = combineReducers({
  currentList,
  fulfillments,
  lineItems,
  issues,
  versions,
  userSales,
  trackers,
  mixes,
  loading,
  errors
});

export default reducers;
