// @flow
import React from "react";
// Components
// Types
// Helpers
import { translateAasm } from "../../common";
import { decamelize } from "humps";
//Style
import "./FulfillmentStatistics.css";

type Props = {
  fulfillment: any
};

class FulfillmentStatistics extends React.Component {
  props: Props;

  render() {
    return (
      <div className="address-item-main">
        <div className="address-item-header">
          <h3>Estatísticas de Produção</h3>
        </div>
        <div className="address-item-line">
          {this.props.fulfillment.timeInStates != null ? (
            Object.keys(this.props.fulfillment.timeInStates).map((k, i) => (
              <div key={i}>
                <b>{translateAasm(decamelize(k))}:</b>{" "}
                {Math.trunc(this.props.fulfillment.timeInStates[k] / 60)}{" "}
                minutos{" "}
                {k === "preparation" &&
                  `(Tempo estimado: ${this.props.fulfillment.estimatedPreparationTime} minutos)`}
              </div>
            ))
          ) : (
            <div>Sem dados de produção.</div>
          )}
        </div>
      </div>
    );
  }
}

export default FulfillmentStatistics;
