// @flow
import { schema } from "normalizr";
import { camelizeKeys } from "humps";

export const issue = new schema.Entity("issues");
export const lineItem = new schema.Entity("lineItems", { issues: [issue] });
export const mix = new schema.Entity("mixes", { lineItems: [lineItem] });
export const fulfillment = new schema.Entity(
  "fulfillments",
  {
    lineItems: [lineItem],
    mixes: [mix],
  },
  { idAttribute: "number", processStrategy: entity => camelizeKeys(entity) },
);
export const fulfillments = new schema.Array(fulfillment);
export const user = new schema.Entity("users");
export const address = new schema.Entity("addresses");
export const tracker = new schema.Entity(
  "trackers",
  {},
  { processStrategy: entity => camelizeKeys(entity), idAttribute: "number" },
);
