// @flow
import { handleActions } from "redux-actions";
import { combineReducers } from "redux";
// Actions
import * as Actions from "../actions";
// Types
import type { Reducer } from "redux";
import type { Action } from "../../types";

/////////////// ORDERS STATISTICS RELATED

// Ther orders statistics that are currently being displayed
const currentOrderStatisticsList: Reducer<string, Action> = handleActions(
  {
    [Actions.fetchOrdersStatistics]: {
      next: (state, action) => action.payload.result
    }
  },
  []
);

// Statistics on Orders
const ordersStatistics: Reducer<string, Action> = handleActions(
  {
    [Actions.fetchOrdersStatistics]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.ordersStatistics
      }),
      throw: (state, action) => state
    }
  },
  {}
);

/////////////// PRODUCTS STATISTICS RELATED

// Ther products statistics that are currently being displayed
const currentProductsStatistics: Reducer<string, Action> = handleActions(
  {
    [Actions.fetchProductsStatistics]: {
      next: (state, action) => action.payload.result
    }
  },
  null
);

// Statistics on products
// This contains the array with references to the statistics and meta data
const productsStatistics: Reducer<string, Action> = handleActions(
  {
    [Actions.fetchProductsStatistics]: {
      next: (state, action) => action.payload.entities.productsStatistics
    }
  },
  {}
);

// Actual statistics for each product as an array
const productStatistics: Reducer<string, Action> = handleActions(
  {
    [Actions.fetchProductsStatistics]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.productStatistics
      })
    }
  },
  {}
);

/////////////// PREPARATION STATISTICS RELATED

// Ther orders statistics that are currently being displayed
const currentPreparationStatisticsList: Reducer<string, Action> = handleActions(
  {
    [Actions.fetchPreparationStatistics]: {
      next: (state, action) =>
        Object.keys(action.payload.entities.preparationStatistics  || [])
    }
  },
  []
);

// Statistics on Orders
const preparationStatistics: Reducer<string, Action> = handleActions(
  {
    [Actions.fetchPreparationStatistics]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.preparationStatistics
      }),
      throw: (state, action) => state,
    },
  },
  {}
);

/////////////// DRIVER STATISTICS RELATED

// Ther orders statistics that are currently being displayed
const currentRouteTravelStatisticsList: Reducer<string, Action> = handleActions(
  {
    [Actions.fetchRouteTravelStatistics]: {
      next: (state, action) =>
        Object.keys(action.payload.entities.routeTravelStatistics || []),
    },
  },
  []
);

// Statistics on Orders
const routeTravelStatistics: Reducer<string, Action> = handleActions(
  {
    [Actions.fetchRouteTravelStatistics]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.routeTravelStatistics,
      }),
      throw: (state, action) => state,
    },
  },
  {}
);

//Statistics on Tasks

// Ther orders statistics that are currently being displayed
const currentTaskStateStatisticsList: Reducer<string, Action> = handleActions(
  {
    [Actions.fetchTaskStateStatistics]: {
      next: (state, action) =>
        action.payload.result
    }
  },
  []
);

const taskStateStatistics: Reducer<string, Action> = handleActions(
  {
    [Actions.fetchTaskStateStatistics]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.taskStateStatistics
      }),
      throw: (state, action) => state
    }
  },
  {}
);

// Loading
const initialLoadingState = {
  gettingOrdersStatistics: false,
  gettingProductsStatistics: false,
  gettingPreparationStatistics: false,
  gettingTaskStateStatistics: false,
  gettingRouteTravelStatistics: false
};
const loading = handleActions(
  {
    [Actions.fetchOrdersStatisticsRequest]: (state, action) => ({
      ...state,
      gettingOrdersStatistics: true
    }),
    [Actions.fetchOrdersStatistics]: (state, action) => ({
      ...state,
      gettingOrdersStatistics: false
    }),
    [Actions.fetchProductsStatisticsRequest]: (state, action) => ({
      ...state,
      gettingProductsStatistics: true
    }),
    [Actions.fetchProductsStatistics]: (state, action) => ({
      ...state,
      gettingProductsStatistics: false
    }),
    [Actions.fetchPreparationStatisticsRequest]: (state, action) => ({
      ...state,
      gettingPreparationStatistics: true
    }),
    [Actions.fetchPreparationStatistics]: (state, action) => ({
      ...state,
      gettingPreparationStatistics: false
    }),
    [Actions.fetchTaskStateStatisticsRequest]: (state, action) => ({
      ...state,
      gettingTaskStateStatistics: true
    }),
    [Actions.fetchTaskStateStatistics]: (state, action) => ({
      ...state,
      gettingTaskStateStatistics: false
    }),
    [Actions.fetchRouteTravelStatisticsRequest]: (state, action) => ({
      ...state,
      gettingRouteTravelStatistics: true
    }),
    [Actions.fetchRouteTravelStatistics]: (state, action) => ({
      ...state,
      gettingRouteTravelStatistics: false
    }),
  },
  initialLoadingState
);

const reducers = combineReducers({
  currentOrderStatisticsList,
  ordersStatistics,

  currentProductsStatistics,
  productsStatistics,
  productStatistics,

  currentPreparationStatisticsList,
  preparationStatistics,

  currentTaskStateStatisticsList,
  taskStateStatistics,

  currentRouteTravelStatisticsList,
  routeTravelStatistics,

  loading
});

export default reducers;
