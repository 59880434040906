// @flow
import React from "react";
import { quantityConverter } from "../../common";
//Styles
import "./StatisticsPreparationTable.css";

type Props = {
  data: any
};

type State = {};

class StatisticsPreparationTable extends React.Component {
  props: Props;
  state: State;

  render() {
    const data = this.props.data;
    return (
      <div className="statistics-preparation-table-main">
        {data && data[0] ? (
          <div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Total Kg</th>
                  <th>Total Un</th>
                  <th># Pedidos</th>
                </tr>
              </thead>

              {data.map((o, i) => (
                <tbody key={i}>
                  <tr>
                    <td>{o.employeeId}</td>
                    <td>{o.employeeName}</td>
                    <td>{quantityConverter(o.totalWeight, "g")}</td>
                    <td>{quantityConverter(o.totalUnits, "un")}</td>
                    <td>{o.fulfillmentCount}</td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        ) : (
          <div>Sem dados para gerar tabela.</div>
        )}
      </div>
    );
  }
}

export default StatisticsPreparationTable;
