// @flow
import React, { useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import _ from 'lodash';
// Components
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import GoogleStaticTrackerMapsImage from '../components/GoogleStaticTrackerMapsImage';
// Helpers
import {
  distributionCenterGeoLocation,
  visitStates,
  truncateString,
  PlacementPhotoScroller,
} from '../../common';
//Style
import './VisitDetailsModal.css';

const VisitDetailsModal = ({
  visitId,
  visitById,
  addressById,
  taskById,
  timeWindowById,
  routeById,
  driverById,
  vehicleById,
  handleGetLocationsByVisit,
  locationsByVisitId,
  handleModalClose,
  handleGetVisit,
}) => {
  // Get the locations to populate the poly-line
  useEffect(() => {
    handleGetLocationsByVisit(visitId);
    handleGetVisit(visitId);
  }, []);

  const visit = visitById(visitId);
  const timeWindow = timeWindowById(visit.timeWindow);
  const address = addressById(visit.address) ? addressById(visit.address) : {};
  const route = routeById(visit.routeId) ? routeById(visit.routeId) : {};
  const vehicle = vehicleById(route.vehicle) ? vehicleById(route.vehicle) : {};
  const driver = driverById(route.driver) ? driverById(route.driver) : {};
  const locations = locationsByVisitId(visitId)
    ? locationsByVisitId(visitId)
    : {};

  const positionOnRoute = Math.max(
    _.findIndex(route.visits, v => {
      return v === visitId;
    }),
    0,
  );

  const previousVisitAddress =
    positionOnRoute === 0
      ? {
          // Coordinates of the distribution center
          latitude: distributionCenterGeoLocation('pituba').lat,
          longitude: distributionCenterGeoLocation('pituba').lng,
        }
      : addressById(visitById(route.visits[positionOnRoute - 1]).address);

  return (
    <Modal
      isOpen="true"
      onRequestClose={() => handleModalClose()}
      ariaHideApp={false}
    >
      <div className="visit-details-modal-main">
        <h1>Visualizador de Visita</h1>

        <div className="visit-details-modal-item-information">
          {address && address.latitude && address.longitude ? (
            <div>
              <GoogleStaticTrackerMapsImage
                startLocation={previousVisitAddress}
                endLocation={address}
                size={'532x800'}
                locations={locations}
              />
            </div>
          ) : null}
          <div>
            <div>
              <b>Faixa de Horário: </b>
              {timeWindow ? (
                `${
                  _.isEmpty(timeWindow)
                    ? 'Sem Tempos'
                    : '(' +
                      moment(timeWindow.startTime)
                        .utcOffset('-0300')
                        .format('DD/MM') +
                      ') ' +
                      moment(timeWindow.startTime)
                        .utcOffset('-0300')
                        .format('LT') +
                      ' a ' +
                      '(' +
                      moment(timeWindow.endTime)
                        .utcOffset('-0300')
                        .format('DD/MM') +
                      ') ' +
                      moment(timeWindow.endTime).utcOffset('-0300').format('LT')
                }`
              ) : (
                <p>Sem faixa de horários definida</p>
              )}
            </div>

            {visit.state === 'success' || visit.state === 'error' ? (
              <div>
                <b>Estado:</b> {visit.state === 'success' ? 'Sucesso' : 'Falha'}
              </div>
            ) : null}

            {visit.visitedAt || visit.signedAt ? (
              <>
                <div>
                  <b>Data da entrega: </b>
                  {moment(visit.visitedAt || visit.signedAt)
                    .utcOffset('-0300')
                    .format('LLL')}
                </div>
                <div>
                  {`A visita foi entregue ${
                    timeWindow.endTime >= (visit.visitedAt || visit.signedAt)
                      ? 'dentro do horário.'
                      : 'atrasada!'
                  }`}
                </div>
              </>
            ) : (
              <div>A visita ainda não foi entregue.</div>
            )}

            {visit.receiverName ? (
              <div>
                <b>Entregue para:</b> {visit.receiverName}
              </div>
            ) : null}

            {visit.receiverDocument ? (
              <div>
                <b>Documento: </b>
                {` ${visit.receiverDocument}`}
                <b className="visit-detail-modal-document-information">
                  ({visit.receiverIdType})
                </b>
              </div>
            ) : null}

            {visit.signature ? (
              <img
                className="visit-details-modal-signature"
                src={`data:image/jpeg;base64,${visit.signature}`}
              />
            ) : null}

            <div>
              {`${
                visit.signatureRequired
                  ? 'A assinatura é obrigatória para essa visita.'
                  : 'A assinatura não é obrigatória nessa visita.'
              }`}
            </div>

            {visit.signedAt ? (
              <div>
                <b>Assinado:</b>{' '}
                {moment(visit.signedAt).utcOffset('-0300').format('llll')}
              </div>
            ) : null}

            {driver && driver.name ? (
              <div>
                <b>Entregador(a):</b>{' '}
                {visit.thirdPartyDeliveryTrackingUrl ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://${visit.thirdPartyDeliveryTrackingUrl}`}
                  >
                    {driver.name}
                  </a>
                ) : (
                  driver.name
                )}
              </div>
            ) : null}

            {vehicle && vehicle.licensePlate ? (
              <div>
                <b>Veículo:</b> {vehicle.licensePlate}
              </div>
            ) : null}
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th>Tarefas</th>
              <th>Estado</th>
              <th>Horários</th>
              <th>Horário de Chegada</th>
              <th>Horário de Entrega</th>
            </tr>
          </thead>
          <tbody>
            <tr key={visit.id}>
              <td>
                <ul>
                  {visit.tasks &&
                    visit.tasks.map(taskId => {
                      const task = taskById(taskId);
                      switch (task.type) {
                        case 'GenericTask':
                          return (
                            <li key={taskId}>
                              <p key={taskId}>
                                {`${truncateString(task.instructions, 40)}`}
                              </p>
                              {(task.failedReason && (
                                <p className="route-details-modal-alert">{`FALHA: ${task.failedReason}`}</p>
                              )) ||
                                null}
                            </li>
                          );
                        case 'DeliverTask':
                          return (
                            <li key={taskId}>
                              <p key={taskId}>
                                <Link
                                  key={taskId}
                                  to={`/fulfillments/${task.orderNumber}`}
                                >
                                  {task.orderNumber}
                                </Link>{' '}
                              </p>
                            </li>
                          );
                      }
                    })}
                </ul>
              </td>
              <td>{visitStates(visit.state)}</td>

              <td>
                {(timeWindowById(visit.timeWindow) &&
                  `(${moment(timeWindowById(visit.timeWindow).startTime)
                    .utcOffset('-0300')
                    .format('DD/MM')})
                  ${moment(timeWindowById(visit.timeWindow).startTime)
                    .utcOffset('-0300')
                    .format('HH:mm')} - ${moment(
                    timeWindowById(visit.timeWindow).endTime,
                  )
                    .utcOffset('-0300')
                    .format('HH:mm')}`) ||
                  '- -'}
              </td>
              <td>
                {(visit.visitedAt &&
                  moment(visit.visitedAt).utcOffset('-0300').format('HH:mm')) ||
                  '- -'}
              </td>
              <td>
                {(visit.signedAt &&
                  moment(visit.signedAt).utcOffset('-0300').format('HH:mm')) ||
                  '- - '}
              </td>
            </tr>
          </tbody>
        </table>
        {address && address.images && (
          <PlacementPhotoScroller placementPhotos={address.images} />
        )}
      </div>
    </Modal>
  );
};

export default VisitDetailsModal;
