import React, { Component } from "react";
import { Button } from "../../common";

type Props = {
  handleUpdateFulfillment: () => void,
  loading: boolean,
  textButton1: string,
  buttonType1: string,
  flagged: boolean,
  textButton2: string,
  buttonType2: string
};
export class FulfillmentPaymentEditor extends Component {
  props: Props;

  render() {
    return (
      <div className="fulfillment-editor-button-container">
        <Button
          text={this.props.textButton1}
          onClick={this.props.onClick1}
          buttonType={this.props.buttonType1}
          loading={this.props.loading}
        />
        <div className="fulfillment-central-div">
          <div className="flagged-button-div">
            <Button
              text={this.props.flagged ? "Desinalizar" : "Sinalizar"}
              buttonType={
                this.props.flagged ? "button-flagged" : "button-not-flagged"
              }
              onClick={() =>
                this.props.handleUpdateFulfillment({
                  flagged: !this.props.flagged
                })
              }
              loading={this.props.loading}
            />
          </div>
        </div>
        <Button
          text={this.props.textButton2}
          buttonType={this.props.buttonType2}
          onClick={this.props.onClick2}
          loading={this.props.loading}
          title={this.props.hasPendingPayment ?
            'Aguardando pagamento' : null}
          disabled={this.props.hasPendingPayment}
        />
      </div>
    );
  }
}

export default FulfillmentPaymentEditor;
