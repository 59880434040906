//flow
import React from "react";
import _ from "lodash";
// Components
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";
//Style
import "./SwitchGroup.css";

type Props = {
  label: String,
  help: String,
  hints: String[],
  states: Boolean[],
  setStates: Function[],
};

const SwitchGroup = ({ label, help, hints, states, setStates }: Props) => {
  // Create a unique ID for the tooltips
  const id = label + help; // TODO: Ensure this is a unique

  return (
    <div className="switcher-component">
      <div>
        <span>{label}</span>
        <span
          className="switcher-component-internal-switcher-helper"
          data-tip=""
          data-for={id}
        >
          <i className="fa fa-question-circle" aria-hidden="true"></i>
          <ReactTooltip id={id}>
            <p>{help}</p>
          </ReactTooltip>
        </span>
      </div>
      <div className="switcher-component-internal-switcher">
        <div>
          {states.map((state, index) => (
            <div
              className="switcher-component-internal-switcher-content"
              key={index}
            >
              {hints && hints[index] ? <p>{hints[index]}</p> : null}
              <Switch
                data-testid={`switch-group-${index}`}
                onChange={setStates && setStates[index]}
                checked={state}
                className="react-switch"
                height={14}
                width={28}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SwitchGroup;
