// @flow
import React, { useState, useEffect } from "react";
import moment from "moment";
import qs from "qs";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import humps from "humps";
// Components
import { Button } from "../../common";
import PeriodicGetter from "./PeriodicGetter";
import DatePicker from "react-datepicker";
// Types
import type { SearchParams } from "../types";
//Styles
import "./FulfillmentFilters.css";
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  loading: boolean,
  fulfillmentsQuantity: number,
  handleSearchFulfillments: (searchParams: SearchParams) => void,
};

type State =
  | {
    showFilter: boolean,
  }
  | SearchParams;

const initialState: SearchParams = {
  sku: "",
  lineItemName: "",
  number: "",
  state: "",
  byDate: {
    startedAt: moment().subtract(10, "days").format("YYYYMMDD"),
    endedAt: moment().format("YYYYMMDD"),
  },
  byDistributionCenter: "",
  hasAttachedSaleReceipt: "",
  needsAttention: "",
};

const FulfillmentFilters = ({
  fulfillmentsQuantity,
  loading,
  location,
  history,
  handleSearchFulfillments,
  hanleNotificationToggle,
}: Props) => {
  const [state, setState] = useState({ ...initialState, showFilter: false });

  useEffect(() => {
    // get the new search parameters
    const searchParams = humps.camelizeKeys(
      qs.parse(location.search.substr(1))
    );

    setState({
      sku: searchParams.sku || initialState.sku,
      lineItemName: searchParams.line_item_name || initialState.lineItemName,
      number: searchParams.number || initialState.number,
      state: searchParams.state || initialState.state,
      byDate: searchParams.byDate || initialState.byDate,
      byDistributionCenter:
        searchParams.byDistributionCenter ||
        initialState.byDistributionCenter,
      hasAttachedSaleReceipt:
        searchParams.hasAttachedSaleReceipt ||
        initialState.hasAttachedSaleReceipt,
      needsAttention:
        searchParams.needsAttention || initialState.needsAttention,

      showFilter: location.search !== "",
    });

    hanleNotificationToggle(location.search === "");

    handleSearchFulfillments(location.search);
  }, [location.search]);

  const whiteList = [
    "sku",
    "lineItemName",
    "number",
    "state",
    "byDistributionCenter",
    "hasAttachedSaleReceipt",
    "byDate",
    "needsAttention",
  ];

  return (
    <div className="fulfillment-filter-main">
      {state.showFilter ? (
        <span>
          <div
            className="fullfillment-filter-toggler"
            onClick={() => {
              setState({ ...state, showFilter: false });
              history.push("/fulfillments");
            }}
          >
            <i className="fa fa-angle-double-up" aria-hidden="true" />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              /* Here we decamelize keys, remove entries that are null or blank and stringify before sending */
              history.push(
                `/fulfillments?${qs.stringify(
                  humps.decamelizeKeys(
                    _.omitBy(
                      _.pick(state, whiteList),
                      (v) => v === null || v === ""
                    )
                  ),
                  { encodeValuesOnly: true }
                )}`
              );
            }}
            onReset={() =>
              history.push(
                `/fulfillments?${qs.stringify(initialState, {
                  encodeValuesOnly: true,
                })}`
              )
            }
          >
            <div className="fulfillment-filter-form-container">
              <div className="fulfillment-filter">
                <div>Estado</div>
                <select
                  value={state.state}
                  onChange={(e) => {
                    const value = e.target.value;
                    setState({
                      ...state,
                      state: value,
                    });
                  }}
                >
                  <option value="">Todos</option>
                  <option value="payment_evaluating">Aguardando análise</option>
                  <option value="pending">Aguardando liberação</option>
                  <option value="preparation_queue">Fila de preparação</option>
                  <option value="preparation">Preparação</option>
                  <option value="packaging_queue">Fila de Empacotamento</option>
                  <option value="packaging">Empacotamento</option>
                  <option value="ready">Pronto</option>
                  <option value="return_queue">Fila de devolução</option>
                  <option value="disassembly">Devolução</option>
                  <option value="storage">Em estocagem</option>
                  <option value="payment_denied">Pagamento negado</option>
                  <option value="canceled">Cancelado</option>
                  <option value="error">Erro</option>
                </select>
              </div>

              <div className="fulfillment-filter">
                <div>Centro</div>
                <select
                  value={state.byDistributionCenter}
                  onChange={(e) => {
                    const value = e.target.value;
                    setState({
                      ...state,
                      byDistributionCenter: value,
                    });
                  }}
                >
                  <option value="">Todos</option>
                  <option value="portao">Portão</option>
                  <option value="pituba">Pituba</option>
                </select>
              </div>

              <div className="fulfillment-filter">
                <div>Nota Fiscal Emitida</div>
                <select
                  value={state.hasAttachedSaleReceipt}
                  onChange={(e) => {
                    const value = e.target.value;
                    setState({
                      ...state,
                      hasAttachedSaleReceipt: value,
                    });
                  }}
                >
                  <option value=""></option>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </select>
              </div>

              <div className="fulfillment-filter">
                <div>Atenção</div>
                <input
                  type="checkbox"
                  checked={state.needsAttention}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setState({
                      ...state,
                      needsAttention: checked,
                    });
                  }}
                />
              </div>

              <div className="fulfillment-filter">
                <div>SKU</div>
                <input
                  type="text"
                  className="fulfillment-filter-input"
                  placeholder="Ex: 990121"
                  value={state.sku}
                  onChange={(e) => {
                    const value = e.target.value;
                    setState({
                      ...state,
                      sku: value,
                    });
                  }}
                />
              </div>

              <div className="fulfillment-filter">
                <div>Nome do Produto</div>
                <input
                  type="text"
                  className="fulfillment-filter-input"
                  placeholder="Ex: Picanha"
                  value={state.lineItemName}
                  onChange={(e) => {
                    const value = e.target.value;
                    setState({
                      ...state,
                      lineItemName: value,
                    });
                  }}
                />
              </div>

              <div className="fulfillment-filter">
                <div>Número do pedido</div>
                <input
                  type="text"
                  className="fulfillment-filter-input"
                  placeholder="Ex: R12341234"
                  value={state.number}
                  onChange={(e) => {
                    const value = e.target.value;
                    setState({
                      ...state,
                      number: value,
                    });
                  }}
                />
              </div>
              <div className="fulfillment-date-filters">
                <div className="fulfillment-filter">
                  <div>Início</div>
                  <DatePicker
                    selected={moment(state.byDate.startedAt)}
                    onChange={(date) =>
                      setState({
                        ...state,
                        byDate: {
                          ...state.byDate,
                          startedAt: date.format("YYYYMMDD"),
                        },
                      })
                    }
                    popperPlacement="bottom-start"
                  />
                </div>

                <div className="fulfillment-filter">
                  <div>Fim</div>
                  <DatePicker
                    selected={moment(state.byDate.endedAt)}
                    onChange={(date) =>
                      setState({
                        ...state,
                        byDate: {
                          ...state.byDate,
                          endedAt: date.format("YYYYMMDD"),
                        },
                      })
                    }
                    popperPlacement="bottom-end"
                  />
                </div>
              </div>
              <Button
                text="PROCURAR PEDIDO"
                type="submit"
                buttonType="button-tertiary"
                loading={loading}
              />
            </div>
          </form>

          <span className="fulfillment-filter-quantity-of-order">
            {`${fulfillmentsQuantity} resultados da pesquisa.`}
          </span>
        </span>
      ) : (
        <div className="fulfillment-filter-untoggled-with-getter">
          <PeriodicGetter
            handleGet={() => handleSearchFulfillments(location.search)}
            loading={loading}
          />
          <div
            className="fullfillment-filter-toggler"
            onClick={() => setState({ ...state, showFilter: true })}
          >
            <i className="fa fa-angle-double-down" aria-hidden="true" />
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(FulfillmentFilters);
