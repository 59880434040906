// @flow
import { createAction } from "redux-actions";
// Types
import type { Dispatch } from "../../types";
// Apis
import FraudApi from "../api";
// Selectors
// Log
import { logException } from "../../common";

// Get user fraud
export const fetchUserRequest = createAction("FETCH_USER_REQUEST");
export const fetchUser = createAction("FETCH_USER");

export function getUser(orderNumber: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchUserRequest());
    try {
      const response = await FraudApi.getUser(orderNumber);
      dispatch(fetchUser(response));
    } catch (err) {
      logException(err);
      dispatch(fetchUser(err));
    }
  };
}

// Get order fraud
export const fetchOrderRequest = createAction("FETCH_ORDER_REQUEST");
export const fetchOrder = createAction("FETCH_ORDER");

export function getOrder(orderNumber: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchOrderRequest());
    try {
      const response = await FraudApi.getOrder(orderNumber);
      dispatch(fetchOrder(response));
    } catch (err) {
      logException(err);
      dispatch(fetchOrder(err));
    }
  };
}
