/** @flow
 * ACTION CABLE CONFIGURATION
 */
import ActionCable from "action-cable-react-jwt";
import { employeeLoggedInSelector } from "../employee";
import runtimeEnv from "@avantsoft/js-runtime-env";
const env = runtimeEnv();

const WS_ENDPOINT =
  env.REACT_APP_WS_URL ||
  "wss://fulfillment-backend.bigdelivery-staging.avantsoft.com.br/cable";

function createSubscriptions(cable, store) { }

export default function configureCable(store) {
  let cable = null;
  let previousLoggedIn = false;
  store.subscribe(() => {
    // Get the logged in state from the correct reducer
    const loggedIn = employeeLoggedInSelector(store.getState());
    // Recreate consumer on startup and log in event
    if (!cable || previousLoggedIn !== loggedIn) {
      previousLoggedIn = loggedIn;
      // Disconnect from old customer if it exists
      if (cable) cable.disconnect();
      if (loggedIn) {
        // Get jwt from local storage
        const jwt = sessionStorage.getItem("access-token");
        cable = ActionCable.createConsumer(WS_ENDPOINT, jwt);
        // Create subscriptions
        createSubscriptions(cable, store);
      }
    }
  });
}
