// @flow
import React, { Component } from "react";
// Components
import { Link } from "react-router-dom";
// Styles
import Logo from "../images/LogoHorizontal.png";
import "./SimpleHeader.css";

type Props = {};

class SimpleHeader extends Component {
  props: Props;
  render() {
    return (
      <div className="simple-header-main">
        <Link to={"/fulfillments"}>
          <img
            className="simple-header-logo"
            src={Logo}
            alt={"BigDelivery Logo"}
          />
        </Link>
        {/*<hr className="simple-header-line" />*/}
      </div>
    );
  }
}

export default SimpleHeader;
