// @flow
import { createSelector } from "reselect";
import _ from "lodash";
// Types
import type { ReduxState } from "../../types";
import type { StoreState } from "../types";

const thisStateSelector = (state: ReduxState): StoreState => {
  return state.fulfillments;
};

//////////////////// FULFILLMENT /////////////////////////////////
const fulfillmentMapSelector = createSelector(
  thisStateSelector,
  state => state.fulfillments
);

export const fulfillmentListSelector = createSelector(
  thisStateSelector,
  fulfillmentMapSelector,
  (state, fulfillmentMap) => {
    return state.currentList.map(id => fulfillmentMap[id]);
  }
);

export const filteredFulfillmentListSelector = (state, filters: {}) => {
  return _.sortBy(_.filter(fulfillmentListSelector(state), filters), [
    "position"
  ]);
};

export const fulfillmentByIdSelector = (
  state: StoreState,
  id: ?number | ?string
) => {
  const fulfillmentMap = fulfillmentMapSelector(state);

  return fulfillmentMap[id] || null;
};

// Returns a fulfillment id given a previous fulfillment and the number of positions to move along in the fulfillmnt list
export const fulfillmentByIdAndIndexShiftSelector = (
  state: StoreState,
  id: ?number | ?string,
  positionsToMove: number
) => {
  const fulfillmentList = thisStateSelector(state).currentList;
  if (fulfillmentList.length === 0) return null; // Returns null in case the fulfillment list is empty

  const indexOfInitialId = fulfillmentList.findIndex(el => el === id);
  let desiredIndex = indexOfInitialId + positionsToMove;

  // If we reach the end of the list we should wrap around. Thus, we should take the mod of our desiredIndex
  desiredIndex = desiredIndex % fulfillmentList.length;

  return fulfillmentList[desiredIndex];
};

//Get User Sales Map
export const userSalesMapSelector = createSelector(
  thisStateSelector,
  state => state.userSales
);

export const userSalesSelector = (state, userId) => {
  const userMap = userSalesMapSelector(state);
  return userMap[userId] || {};
};

//////////////////// ADDRESS ///////////////////////////////////////////////
export const fulfillmentAddressByFulfillmentId = (
  state,
  id: ?number | ?string
) => {
  const fulfillment = fulfillmentByIdSelector(state, id);

  return fulfillment ? fulfillment.address : null;
};

//////////////////// LINE ITEMS AND ISSUES /////////////////////////////////
const lineItemMapSelector = createSelector(
  thisStateSelector,
  state => state.lineItems
);

const issueMapSelector = createSelector(
  thisStateSelector,
  state => state.issues
);

// Returns all line items beloging to a fulfillment
// export const lineItemsByFulfillmentId = (state, id) => {
//   const lineItemMap = lineItemMapSelector(state)

//   return lineItemMap[id] || []
// }

export const lineItemById = (state: StoreState, id: ?number) => {
  const lineItemMap = lineItemMapSelector(state);
  const issueMap = issueMapSelector(state);

  const lineItem = lineItemMap[id];

  // If we have a valid line item augment it with issues before returning
  if (!lineItem) {
    return null;
  }

  const augmentedLineItem = {
    ...lineItem,
    issues: _.without(
      lineItem.issues.map(issue => issueMap[issue]),
      undefined
    )
  };

  return augmentedLineItem;
};

//////////////////// MIXES /////////////////////////////////
const mixMapSelector = createSelector(thisStateSelector, state => state.mixes);

export const mixByIdWithLineItems = (state: StoreState, id: ?number) => {
  const mixesMap = mixMapSelector(state);
  const mix = mixesMap[id];

  if (!mix) {
    return null;
  }

  const lineItemsMap = lineItemMapSelector(state);

  return {
    ...mix,
    lineItems: _.without(
      mix.lineItems.map(lineItemId => lineItemsMap[lineItemId]),
      undefined,
    ),
  };
};

//////////////////// FULFILLMENT VERSIONS /////////////////////////////////
const fulfillmentVersionsMapSelector = createSelector(
  thisStateSelector,
  state => state.versions
);

export const fulfillmentVersionsByOrderNumberSelector = (
  state: StoreState,
  number: string
) => {
  const versionsMap = fulfillmentVersionsMapSelector(state);

  return versionsMap[number] || [];
};

//////////////////// ERRORS /////////////////////////////////
export const errorsSelector = createSelector(
  thisStateSelector,
  state => state.errors
);

//////////////////// TRACKER /////////////////////////////////
const trackerMapSelector = createSelector(
  thisStateSelector,
  state => state.trackers
);

export const trackerSelector = (state: StoreState, number: string) => {
  const tracker = trackerMapSelector(state);
  return tracker[number] || {};
};

//////////////////// Loading Selectors //////////////////////
export const fulfillmentsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingFulfillments
);

export const fulfillmentLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingFulfillment
);

export const fulfillmentVersionsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingFulfillmentVersions
);

export const updatingfulfillmentLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.updatingFulfillment
);

export const creatingRouterVisitUpdatingSelector = createSelector(
  thisStateSelector,
  state => state.loading.creatingRouterVisit
);

export const updatingfulfillmentPositionLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.updatingFulfillmentPosition
);

export const updatingLineItemLoadingSelector = (
  state: StoreState,
  id: ?number
) => {
  const loading = thisStateSelector(state).loading;

  return _.includes(loading.updatingLineItems, id);
};

export const removingLineItemIssueSelector = (
  state: StoreState,
  id: ?number
) => {
  const loading = thisStateSelector(state).loading;

  return _.includes(loading.deletingLineItemIssue, id);
};

export const creatingLineItemIssueSelector = (
  state: StoreState,
  id: ?number
) => {
  const loading = thisStateSelector(state).loading;

  return _.includes(loading.creatinglineItemIssue, id);
};

export const editingOrderObservationLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.editingOrderObservation
);

export const editingAddressLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.editingAddress
);
