// @flow
import Raven from "raven-js";
import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import createRavenMiddleware from "raven-for-redux";

Raven.config(
  "https://a3d1b6411de24a949611a5e18b6325bc@sentry.io/187645"
).install();

export default function configureStore(preloadedState) {
  // Create middleware
  const logger = createLogger();
  const raven = createRavenMiddleware(Raven);
  // Create store
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(thunk, logger, raven))
  );

  return store;
}
