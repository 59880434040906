// @flow
import React from 'react';
// Components
import LineItem from '../containers/LineItem';
import PrintSaleReceiptIcon from './PrintSaleReceiptIcon';
import WeightLimitedIcon from './WeightLimitedIcon';
import PaymentExceedIcon from './PaymentExceedIcon';
// Types
import type {
  LineItem as LineItemType,
  UpdatableLineItem,
  Mix,
} from '../types';
// Styles
import './LineItemList.css';
// Composers
import { connect } from 'react-redux';
// Helpers
import { lineItemById } from '../selectors';

type Props = {
  lineItems: LineItemType[],
  mixes: Mix[],
  weightLimited: boolean,
  shouldPrintSaleReceipt: boolean,
};

const LineItemList = ({
  lineItems,
  mixes,
  weightLimited,
  shouldPrintSaleReceipt,
}) => {
  const lineItemsNotBelongingToBlends: LineItemType[] = lineItems
    ? lineItems.filter(lineItem => lineItem.mixId === null)
    : [];
  const allLineItems = lineItems ? lineItems : [];
  const totalProducedOrderPrice = allLineItems.reduce((acc, lineItem) => {
    return acc + lineItem.quantityActual * Number(lineItem.unitPrice);
  }, 0);
  const totalLimitOrderPrice = allLineItems.reduce((acc, lineItem) => {
    return acc + lineItem.quantityOrdered * Number(lineItem.unitPrice);
  }, 0);
  return (
    <div className="line-item-list-main">
      <div className="line-item-list-header">
        <h3>Itens do pedido:</h3>
        <div className="line-item-list-white-space"></div>
        <div className="line-item-list-icons-info">
          <PrintSaleReceiptIcon
            shouldPrintSaleReceipt={shouldPrintSaleReceipt}
          />
          <WeightLimitedIcon weightLimited={weightLimited} />
          <PaymentExceedIcon
            paymentExceeded={totalProducedOrderPrice > totalLimitOrderPrice}
          />
        </div>
      </div>
      {lineItemsNotBelongingToBlends &&
        lineItemsNotBelongingToBlends.length > 0 &&
        lineItemsNotBelongingToBlends.map(lineItemNotBelongingToBlends => (
          <LineItem
            key={lineItemNotBelongingToBlends.id}
            lineItem={lineItemNotBelongingToBlends}
            mixes={mixes}
          />
        ))}
      {mixes && mixes.length > 0 && (
        <div>
          <h3>Blends do pedido:</h3>
          <div>
            {mixes.map(mix => (
              <div
                className="line-item-list-main"
                style={{ backgroundColor: '#eb5b1e' }}
              >
                <p>{`Nome: ${mix.name}`}</p>
                {mix.observation && <p>{`Observação: ${mix.observation}`}</p>}
                {mix.fatPercentage && (
                  <p>Percentual de gordura: {mix.fatPercentage}%</p>
                )}
                {mix.portioningSize && (
                  <p>Porcionamento: {mix.portioningSize}%</p>
                )}

                <div>
                  {mix.lineItems.map(lineItem => (
                    <LineItem
                      key={lineItem.id}
                      lineItem={lineItem}
                      mixes={mixes}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LineItemList;
