import Modal from 'react-modal';
import React from 'react';

import './ShowAllPreparationImagesModal.css';

type PreparationImages = {
  id: string,
  typeName: string,
  url: string,
};

type Props = {
  images: Array<PreparationImages>,
  isOpen: boolean,
  onRequestClose: () => void,
  lineItemName: string,
};

const ShowAllPreparationImagesModal = (props: Props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      ariaHideApp={false}
    >
      <div className="show-all-preparation-images-modal">
        <h2>{`Fotos da produção de ${props.lineItemName}`}</h2>
        {props.images &&
          props.images.map(preparationImage => (
            <img
              key={preparationImage.id}
              className="show-all-preparation-images-modal-preparation-image-large"
              src={preparationImage.url}
            ></img>
          ))}

        <button
          onClick={props.onRequestClose}
          className="show-all-preparation-images-modal-close-modal-button"
        >
          Fechar
        </button>
      </div>
    </Modal>
  );
};

export default ShowAllPreparationImagesModal;
