import React from 'react';
import './InputFieldErrorMessage.css';

const InputFieldLabel = ({ children = 'Label', ...labelProps }) => {
  return (
    <label className="input-label-text" {...labelProps}>
      {children}
    </label>
  );
};

export default InputFieldLabel;
