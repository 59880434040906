// @flow

// Methods
import { login, logout, validateToken } from './session';

const Employee = {
  login: login,
  logout: logout,
  validateToken: validateToken,
};

export default Employee;
