// @flow
import React from "react";
//Components
import SwitchGroup from "../../common/components/SwitchGroup";
//style
import "./MapFilterMenu.css";

const MapFilterMenu = ({
  filters,
  updateFilters,
  driversShow,
  setDriversShow,
  polylineShow,
  setPolylineShow,
}) => {
  const {
    morningVisitsShow,
    afternoonVisitsShow,
    eveningVisitsShow,
    unroutedVisitsShow,
    pendingVisitsShow,
    successVisitsShow,
    failedVisitsShow,
    inProgressVisitsShow,
  } = filters;

  return (
    <div className="filter-menu-main">
      <p>Filtros</p>
      <SwitchGroup
        label="Rota"
        help="Mostra caminhos das rotas com base no roteiro atual"
        states={[polylineShow]}
        setStates={[(toggleValue) => setPolylineShow(toggleValue)]}
      />
      <SwitchGroup
        label="Entregadores"
        help="Habilita visualização de entregadores no mapa"
        states={[driversShow]}
        setStates={[(toggleValue) => setDriversShow(toggleValue)]}
      />
      <SwitchGroup
        label="Horários"
        help="Habilita no mapa apenas visitas com horário específicos"
        hints={["manhã", "tarde", "noite"]}
        states={[morningVisitsShow, afternoonVisitsShow, eveningVisitsShow]}
        setStates={[
          (toggleValue) => updateFilters({ morningVisitsShow: toggleValue }),
          (toggleValue) => updateFilters({ afternoonVisitsShow: toggleValue }),
          (toggleValue) => updateFilters({ eveningVisitsShow: toggleValue }),
        ]}
      />
      <SwitchGroup
        label="Estados"
        help="Habilita no mapa apenas visitas com estados definidos"
        hints={["s/ rota", "pendente", "progresso", "completa", "falha"]}
        states={[
          unroutedVisitsShow,
          pendingVisitsShow,
          inProgressVisitsShow,
          successVisitsShow,
          failedVisitsShow,
        ]}
        setStates={[
          (toggleValue) => updateFilters({ unroutedVisitsShow: toggleValue }),
          (toggleValue) => updateFilters({ pendingVisitsShow: toggleValue }),
          (toggleValue) => updateFilters({ inProgressVisitsShow: toggleValue }),
          (toggleValue) => updateFilters({ successVisitsShow: toggleValue }),
          (toggleValue) => updateFilters({ failedVisitsShow: toggleValue }),
        ]}
      />
    </div>
  );
};

export default MapFilterMenu;
