// @flow
import React from 'react';
import './PaymentExceedIcon.css';

type Props = {
  paymentExceeded: boolean,
};

const PaymentExceedIcon = ({ paymentExceeded }: Props) =>
  paymentExceeded ? (
    <div className="payment-exceed">
      <i
        className="fa fa-exclamation-triangle"
        aria-hidden="true"
        title="Quantidade produzida não deve ser maior que o pagamento."
      />
    </div>
  ) : null;

export default PaymentExceedIcon;
