// @flow
import { LoginPage, LogoutPage } from "../employee";
import {
  FulfillmentListPage,
  FulfillmentPage,
  FulfillmentSortPage
} from "../fulfillments";
import { StatisticsPage } from "../statistics";
import { RoutePage } from "../routes";

const routes = [
  {
    path: "/login",
    component: LoginPage
  },
  {
    path: "/logout",
    component: LogoutPage
  },
  {
    path: "/fulfillments",
    exact: true,
    component: FulfillmentListPage
  },
  {
    path: "/fulfillments/sort",
    exact: true,
    component: FulfillmentSortPage
  },
  {
    path: "/fulfillments/:id",
    exact: true,
    component: FulfillmentPage
  },
  {
    path: "/statistics",
    exact: true,
    component: StatisticsPage
  },
  {
    path: "/routes",
    component: RoutePage
  }
];

export default routes;
