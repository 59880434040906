// @flow
import React, { PureComponent } from "react";
// Helpers
import runtimeEnv from "@avantsoft/js-runtime-env";
// Components
import Button from "../../common/components/Button";
//Type
import { Fulfillment, UpdatableAddress } from "../types";
//Style
import "./AddressPositionMap.css";
// Get ENV vars
const env = runtimeEnv();

type Props = {
  address: Fulfillment.address,
  onConfirmOrRejectPosition: (observation: string) => void
};

class AddressPositionMap extends PureComponent {
  constructor(props: Props) {
    super(props);
  }

  handleConfirm = (text: string, updatableAddres: UpdatableAddress) => {
    const decision = window.confirm(text);

    if (decision) {
      this.props.onConfirmOrRejectPosition(updatableAddres);
    }
  };

  render() {
    const address = this.props.address;

    // If we don't have a checkout lat/lng we should not show the map
    if (!address.checkoutLatitude || !address.checkoutLongitude) return null;

    const staticMapsURL = `https://maps.googleapis.com/maps/api/staticmap?&size=500x300&maptype=roadmap&markers=color:red%7Clabel:%7C${address.latitude},${address.longitude}&markers=color:green%7Clabel:%7C${address.checkoutLatitude},${address.checkoutLongitude}&key=${env.REACT_APP_GCP_API_KEY}`;
    const updatableAddressConfirm = {
      latitude: address.checkoutLatitude,
      longitude: address.checkoutLongitude,
      checkoutLatitude: null,
      checkoutLongitude: null
    };
    const updatableAddressReject = {
      checkoutLatitude: null,
      checkoutLongitude: null
    };

    return (
      <div className="map-location-content">
        <div>
          <img
            src={staticMapsURL}
            alt="Mapa com endereço e local da última entrega"
          />
        </div>
        <div className="address-button-content">
          <Button
            text="Manter"
            buttonType="button-needs-attention"
            loading={this.props.isLoading}
            onClick={() =>
              this.props.onConfirmOrRejectPosition(updatableAddressReject)
            }
          ></Button>

          <Button
            text="Alterar"
            buttonType="button-flagged"
            loading={this.props.isLoading}
            onClick={() =>
              this.handleConfirm(
                "Tem certeza que deseja alterar o endereço atual?",
                updatableAddressConfirm
              )
            }
          ></Button>
        </div>
      </div>
    );
  }
}
export default AddressPositionMap;
