// @flow
import React, { useState } from "react";
import { translateAasm } from "../../common";
import CancelationReasonDialog from "./CancelationReasonDialog";
// Components
//Styles
import "./StateDropdown.css";

const StateDropdown = ({
  state,
  orderState,
  handleUpdateFulfillment
}) => {
  const [cancelationDialogIsOpen, setCancelationDialogIsOpen] = useState(false);

  const handleCancelPrompt = () => {
    if (orderState !== 'complete') {
      window.alert('Você não pode cancelar um pedido aberto. Solicite ao Call Center para fechar o pedido e tente cancelar novamente.')

      return;
    }

    setCancelationDialogIsOpen(true);
  }

  const handleOnConfirmOrderCancelation = (reason) => {
    if (window.confirm("Esta ação não pode ser desfeita. Quer mesmo cancelar este pedido?")) {
      handleUpdateFulfillment({
        nextState: "canceled",
        cancelationReason: reason
      });
      setCancelationDialogIsOpen(false);
    }
  }

  const handleConfirmPrompt = (value) => {
    const confirm = window.confirm(
      `Tem certeza que gostaria de mudar o estado de ${translateAasm(
        state
      )} para ${translateAasm(value)}?`
    );

    if (confirm) {
      handleUpdateFulfillment({
        nextState: value
      });
    }
  }
  return (
    <div className="state-dropdown">
      <CancelationReasonDialog
        orderCancelationDialogIsOpen={cancelationDialogIsOpen}
        handleOnConfirmOrderCancelationDialog={handleOnConfirmOrderCancelation}
        handleOnCloseOrderCancelationDialog={() => setCancelationDialogIsOpen(false)}
      />
      <div>Estado</div>
      <select
        value={state}
        onChange={e => {
          if (state === "error" && e.target.value === "canceled") {
            handleCancelPrompt();
          } else {
            handleConfirmPrompt(e.target.value);
          }
        }}
      >
        <option value="" />
        <option value="payment_evaluating">
          {translateAasm("payment_evaluating")}
        </option>
        <option value="payment_denied">
          {translateAasm("payment_denied")}
        </option>
        <option value="pending">{translateAasm("pending")}</option>
        <option value="preparation_queue">
          {translateAasm("preparation_queue")}
        </option>
        <option value="preparation">{translateAasm("preparation")}</option>

        <option value="packaging_queue">
          {translateAasm("packaging_queue")}
        </option>
        <option value="packaging">{translateAasm("packaging")}</option>
        <option value="ready">{translateAasm("ready")}</option>
        <option value="error">{translateAasm("error")}</option>
        <option value="canceled">{translateAasm("canceled")}</option>
      </select>
    </div>
  );
}

export default StateDropdown;
