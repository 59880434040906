import { authGet } from "../../common/api";
import humps from "humps";
import { normalize } from "normalizr";
import * as schema from "./schema";
import qs from "qs";
import _ from "lodash";
// Types

const api = {
  async getOrdersStatistics(searchParams: any) {
    // Decamelize keys and stringify before sending
    // Remove entries that are null or blank
    let params = _.omitBy(searchParams, (v) => v === null || v === "");
    params = humps.decamelizeKeys(params);
    const searchString = qs.stringify(params);

    const response = await authGet(`/statistics/orders?${searchString}`);
    const data = await response.json().then((b) => humps.camelizeKeys(b));

    const normalized = normalize(data, schema.ordersStatistics);
    return normalized;
  },
  async getProductsStatistics(searchParams: any) {
    // Decamelize keys and stringify before sending
    // Remove entries that are null or blank
    let params = _.omitBy(searchParams, (v) => v === null || v === "");
    params = humps.decamelizeKeys(params);
    const searchString = qs.stringify(params);

    const response = await authGet(`/statistics/products?${searchString}`);
    const data = await response.json().then((b) => humps.camelizeKeys(b));

    const normalized = normalize(data, schema.productsStatistics);
    return normalized;
  },
  async getPreparationStatistics(searchParams: any) {
    // Decamelize keys and stringify before sending
    // Remove entries that are null or blank
    let params = _.omitBy(searchParams, (v) => v === null || v === "");
    params = humps.decamelizeKeys(params);
    const searchString = qs.stringify(params);

    const response = await authGet(
      `/statistics/employees_quantity_info?${searchString}`
    );
    const data = await response.json().then((b) => humps.camelizeKeys(b));

    const normalized = normalize(data, schema.preparationStatistics);
    return normalized;
  },

  async getTaskStateStatistics(searchParams: any) {
    // Decamelize keys and stringify before sending
    // Remove entries that are null or blank
    let params = _.omitBy(searchParams, v => v === null || v === "");
    params = humps.decamelizeKeys(params);
    const searchString = qs.stringify(params);

    const response = await authGet(

      `/statistics/tasks_state_info?${searchString}`
    );
    const data = await response.json().then(b => humps.camelizeKeys(b));

    const normalized = normalize(data, schema.taskStateStatistics);
    return normalized;
  },

  async getRouteTravelStatistics(searchParams: any) {
    // Decamelize keys and stringify before sending
    // Remove entries that are null or blank
    let params = _.omitBy(searchParams, (v) => v === null || v === "");
    params = humps.decamelizeKeys(params);
    const searchString = qs.stringify(params);

    const response = await authGet(
      `/statistics/routes_travel_info?${searchString}`
    );
    const data = await response.json().then((b) => humps.camelizeKeys(b));

    const normalized = normalize(data, schema.routeTravelStatistics);
    return normalized;
  },
};

export default api;
