// @flow
import React from 'react';
// Components
import LoginBox from './LoginBox';
// Styles
import './Login.css';

type Props = {
  isLoggingIn: boolean,
  loginErrors: {
    badUsername: boolean,
    badPassword: boolean,
  },
  submitLogin: () => void,
};

type State = {
  showLoginOrRegister: '' | 'login' | 'register',
};

class Login extends React.Component {
  state: State;
  constructor(props: Props) {
    super(props);

    this.state = {
      showLoginOrRegister: '',
    };
  }

  render() {
    return (
      <div className="login-main">

        <div className="login-content">
          <div className="login-container">
            <LoginBox
              onSubmitLogin={this.props.submitLogin}
              isLoading={this.props.isLoggingIn}
              errors={this.props.loginErrors}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
