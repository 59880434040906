// @flow
import { schema } from "normalizr";
import { camelizeKeys } from "humps";

////// RELATED TO USER
export const userOrder = new schema.Entity(
  "orders",
  {},
  { idAttribute: "number" }
);
export const userAddress = new schema.Entity("addresses");

export const user = new schema.Entity(
  "users",
  {
    orders: [userOrder],
    addresses: [userAddress]
  },
  { processStrategy: entity => camelizeKeys(entity) }
);

////// RELATED TO ORDER
export const orderAddress = new schema.Entity("addresses");
export const orderPayment = new schema.Entity("payments");

export const order = new schema.Entity(
  "orders",
  {
    addresses: [orderAddress],
    payments: [orderPayment]
  },
  { processStrategy: entity => camelizeKeys(entity), idAttribute: "number" }
);
