import {
  authPut as authPutSales,
  authPost as authPostSales,
  authGet as authGetSales,
  authDelete as authDeleteSales,
} from '../../common/salesApiHelpers';
import humps from 'humps';
import _ from 'lodash';
import * as schema from './schema';
import { normalize } from 'normalizr';
import { qsStringify } from '../../common';

const Routes = {
  async generateVirtualRoutes(vehicleIds, visitIds) {
    const response = await authGetSales(
      `/routing/routes/clustering?vehicle_ids=${vehicleIds}&visit_ids=${visitIds}`,
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.virtualRoutes);

    return normalized;
  },
  async estimateThirdPartyDeliveryRoute(routeId) {
    const response = await authPostSales(
      `/routing/routes/${routeId}/third_party_delivery_estimate`,
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.route);

    return normalized;
  },
  async createThirdPartyDeliveryRoute(routeId) {
    const response = await authPostSales(
      `/routing/routes/${routeId}/third_party_delivery_start`,
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.route);

    return normalized;
  },

  // async redoThirdPartyDeliveryRoute(routeId) {
  //   const response = await authPostSales(
  //     `/routing/routes/${routeId}/third_party_delivery_redo_order`
  //   );
  //   const data = await response.json().then();
  //   const normalized = normalize(data, schema.route);

  //   return normalized;
  // },
  async refreshThirdPartyDeliveryRoute(routeId) {
    const response = await authPostSales(
      `/routing/routes/${routeId}/third_party_delivery_refresh`,
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.route);

    return normalized;
  },
  async cancelThirdPartyDeliveryRoute(routeId) {
    const response = await authPostSales(
      `/routing/routes/${routeId}/third_party_delivery_cancel_order`,
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.route);

    return normalized;
  },
  async searchVisits(searchParams) {
    const response = await authGetSales(
      `/routing/visits?${qsStringify(searchParams)}`,
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.visits);

    return normalized;
  },
  async getVisit(id) {
    const response = await authGetSales(`/routing/visits/${id}`);
    const data = await response.json().then();
    const normalized = normalize(data, schema.visit);
    return normalized;
  },
  async createVisit(params) {
    const response = await authPostSales(
      `/routing/visits`,
      humps.decamelizeKeys(params),
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.visit);
    return normalized;
  },
  async editVisit(id, params) {
    const response = await authPutSales(
      `/routing/visits/${id}`,
      humps.decamelizeKeys(params),
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.visit);
    return normalized;
  },
  async deleteVisit(id) {
    await authDeleteSales(`/routing/visits/${id}`);
  },
  async sortVisit(id, desiredPosition) {
    const response = await authPutSales(`/routing/visits/${id}/sort`, {
      desired_position: desiredPosition,
    });
    const data = await response.json().then();
    const normalized = normalize(data, schema.visits);
    return normalized;
  },
  async sortPreparationQueueBasedOnRoutes() {
    await authPutSales(`/production/orders/sort_positions_by_route`, {});
  },
  async searchRoutes(searchParams) {
    const searchString = qsStringify(humps.decamelizeKeys(searchParams));
    const response = await authGetSales(`/routing/routes?${searchString}`);
    const data = await response.json().then();
    const normalized = normalize(data, schema.routes);

    return normalized;
  },
  async getRoute(id) {
    const response = await authGetSales(`/routing/routes/${id}`);
    const data = await response.json().then();
    //
    return data;
  },
  async createRoute(params) {
    const response = await authPostSales(
      `/routing/routes`,
      humps.decamelizeKeys(params),
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.route);
    return normalized;
  },
  async createManyRoutes(routeParams) {
    let params = {
      routeClusters: Object.values(
        _.omitBy(routeParams, v => v.visits.length === 0),
      ),
    };
    params = humps.decamelizeKeys(params);
    const response = await authPostSales(
      `/routing/routes/clustering`,
      humps.decamelizeKeys(params),
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.routes);
    return normalized;
  },
  async editRoute(id, updatableProperties) {
    let params = _.omitBy(updatableProperties, v => v === null);
    params = humps.decamelizeKeys(params);

    const response = await authPutSales(`/routing/routes/${id}`, params);
    const data = await response.json().then();
    const normalized = normalize(data, schema.route);
    return normalized;
  },
  async moveRoute(id, desiredPosition) {
    const response = await authPutSales(`/routing/routes/${id}/sort`, {
      desired_position: desiredPosition,
    });
    const data = await response.json().then();
    const normalized = normalize(data, schema.routes);
    return normalized;
  },
  async reorderVisitsInRoute(id) {
    const response = await authPutSales(
      `/routing/routes/${id}/order_visits`,
      {},
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.route);
    return normalized;
  },
  async deleteRoute(id) {
    await authDeleteSales(`/routing/routes/${id}`);
  },
  async createTask(params) {
    const response = await authPostSales(
      `/routing/tasks`,
      humps.decamelizeKeys(params),
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.task);
    return normalized;
  },
  async editTask(id, updatableProperties) {
    let params = _.omitBy(updatableProperties, v => v === null);
    params = humps.decamelizeKeys(params);

    const response = await authPutSales(`/routing/tasks/${id}`, params);
    const data = await response.json().then();
    const normalized = normalize(data, schema.task);
    return normalized;
  },
  async deleteTask(id) {
    await authDeleteSales(`/routing/tasks/${id}`);
  },
  async searchDrivers(searchParams) {
    const searchString = qsStringify(humps.decamelizeKeys(searchParams));
    const response = await authGetSales(`/routing/drivers?${searchString}`);
    const data = await response.json().then();
    const normalized = normalize(data, schema.drivers);

    return normalized;
  },
  async searchVehicles(searchParams) {
    const searchString = qsStringify(humps.decamelizeKeys(searchParams));
    const response = await authGetSales(`/routing/vehicles?${searchString}`);
    const data = await response.json().then();
    const normalized = normalize(data, schema.vehicles);

    return normalized;
  },
  async editTimeWindow(id, params) {
    const response = await authPutSales(
      `/routing/time_windows/${id}`,
      humps.decamelizeKeys(params),
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.timeWindow);
    return normalized;
  },
  async editShippingRate(id, params) {
    const response = await authPutSales(
      `/v2/shipping_rates/${id}`,
      humps.decamelizeKeys(params),
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.shippingRate);
    return normalized;
  },
  async createOrEditAddress(address) {
    const response = address.id
      ? await authPutSales(
          `/v2/addresses/${address.id}`,
          humps.decamelizeKeys(address),
        )
      : await authPostSales(`/v2/addresses`, humps.decamelizeKeys(address));
    const data = await response.json().then();
    const normalized = normalize(data, schema.address);
    return normalized;
  },

  async getMostRecentLocationsForAllDrivers() {
    const response = await authGetSales(
      `/routing/locations?most_recent_for_all_drivers=true`,
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.locations);
    return normalized;
  },
  async getLocationsByVisit(visitId) {
    const response = await authGetSales(
      `/routing/locations?by_visit=${visitId}`,
    );
    const data = await response.json().then();
    const normalized = normalize(data, schema.locations);
    return normalized;
  },
};

export default Routes;
