// @flow
import React from "react";
// Helpers
import polyline from "@mapbox/polyline";
// Components
//Styles
//Type

type Props = {
  startLocation: any,
  endLocation: any,
  locations: any,
  // Image parameters
  size: string
};

const GoogleStaticTrackerMapsImage = ({
  startLocation,
  endLocation,
  locations,
  size
}: Props) => {
  const navigatedCoordinates = locations.map(l => {
    return [l.latitude, l.longitude];
  });

  const staticMapsURL = `https://maps.googleapis.com/maps/api/staticmap?markers=size:small%7Ccolor:red%7C${
    startLocation.latitude
  },${startLocation.longitude}&markers=size:small%7Ccolor:green%7C${
    endLocation.latitude
  },${endLocation.longitude}&path=weight:3%7Ccolor:blue%7Cenc:${polyline.encode(
    navigatedCoordinates
  )}&size=${size}&maptype=roadmap&scale=2
    &key=${process.env.REACT_APP_GCP_API_KEY}`;

  return <img src={staticMapsURL} alt="" />;
};

export default GoogleStaticTrackerMapsImage;
