// @flow
import React from "react";
// Components
import FulfillmentItem from "./FulfillmentItem";
// Types
import type { Fulfillment } from "../types";
//Styles
import "./FulfillmentList.css";

type Props = {
  fulfillments: Fulfillment[],
  loading: boolean
};

class FulfillmentList extends React.Component {
  props: Props;

  render() {
    if (this.props.loading && this.props.fulfillments.length === 0) {
      return (
        <div className="fulfillment-list-loader">
          <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
          <div>Carregando...</div>
        </div>
      );
    }
    return (
      <div className="fulfillment-list-main">
        {/* If we have no fulfillments */}
        {this.props.fulfillments.length === 0 ? (
          <div>Sem resultados para a pesquisa...</div>
        ) : null}

        {this.props.fulfillments.map(f => (
          <FulfillmentItem key={f.number} fulfillment={f} />
        ))}
      </div>
    );
  }
}

export default FulfillmentList;
