// @flow
import React from "react";
import humps from "humps";
import _ from "lodash";
import qs from "qs";
// Components
import FulfillmentSortList from "../components/sorting/FulfillmentSortList";
import DistributionCenterDropDown from "../components/DistributionCenterDropDown";
import FulfillmentSortMap from "../components/sorting/FulfillmentSortMap";
import { UnauthenticatedRedirect } from "../../employee";
// Composers
import { connect } from "react-redux";
// Import Actions
import { searchFulfillments, editFulfillmentPosition } from "../actions";
// Selectors
import {
  filteredFulfillmentListSelector,
  fulfillmentsLoadingSelector,
  updatingfulfillmentPositionLoadingSelector
} from "../selectors";
// Types
import type { Fulfillment, SearchParams } from "../types";
import type { Dispatch } from "../../common";
// Styles
import "./FulfillmentSortPage.css";

type Props = {
  loading: boolean,
  updatingFulfillmentPositionLoading: boolean,
  fulfillments: (filter: {}) => Fulfillment[],
  handleSearchFulfillments: (searchParams: SearchParams) => void,
  handleUpdatePosition: (orderNumber: string, desiredPosition: number) => void
};

type State = {
  distributionCenter: string
};

class FulfillmentListPage extends React.Component {
  state: State;
  constructor(props: Props) {
    super(props);

    this.state = {
      distributionCenter: ""
    };
  }

  searchParamsString = params =>
    `?${qs.stringify(
      humps.decamelizeKeys(_.omitBy(params, v => v === null || v === "")),
      { encodeValuesOnly: true }
    )}`;

  render() {
    return (
      <UnauthenticatedRedirect>
        <div>
          <DistributionCenterDropDown
            distributionCenter={this.state.distributionCenter}
            defaultDistributionCenter="pituba"
            handleUpdateFulfillment={(distributionCenter: string) => {
              this.props.handleSearchFulfillments(
                this.searchParamsString({
                  ...distributionCenter,
                  state: "preparation_queue"
                })
              );
              this.setState((prevState, props) => ({
                ...prevState,
                ...distributionCenter
              }));
            }}
          />
        </div>
        <div className="fulfillment-sort-page">
          <FulfillmentSortList
            fulfillments={this.props.fulfillments({
              distributionCenter: this.state.distributionCenter,
              state: "preparation_queue"
            })}
            handleUpdate={this.props.handleUpdatePosition}
            loading={this.props.loading}
            updatingLoading={this.props.updatingFulfillmentPositionLoading}
          />
          <FulfillmentSortMap
            fulfillments={this.props.fulfillments({
              distributionCenter: this.state.distributionCenter,
              state: "preparation_queue"
            })}
            loading={this.props.loading}
          />
        </div>
      </UnauthenticatedRedirect>
    );
  }
}

const mapStateToProps = state => {
  return {
    fulfillments: filters => filteredFulfillmentListSelector(state, filters),
    updatingFulfillmentPositionLoading: updatingfulfillmentPositionLoadingSelector(
      state
    ),
    loading: fulfillmentsLoadingSelector(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    handleSearchFulfillments: searchParams => {
      dispatch(searchFulfillments(searchParams));
    },
    handleUpdatePosition: (orderNumber: string, desiredPosition: number) => {
      dispatch(editFulfillmentPosition(orderNumber, desiredPosition));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FulfillmentListPage);
