// @flow
import React from "react";
// Components
import { Button, InputFieldErrorMessage } from "../../common";
// Styles
import "./LoginBox.css";

type State = {
  badUsername: string,
  badPassword: string
};

type Props = {
  isLoading: boolean,
  errors: {
    badUsername: boolean,
    badPassword: boolean
  },
  onSubmitLogin: () => void
};

class LoginBox extends React.Component {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      badUsername: "",
      badPassword: ""
    };
  }

  // Validates the form on submission. If there are no validation error dispatches action.
  validateUserInput(username: string, password: string) {
    let noValidationErrors: boolean = true;

    if (username === "") {
      // First name is missing or invalid? TODO: Invalid part
      this.setState({ badUsername: "Campo é obrigatório" });
      noValidationErrors = false;
    } else {
      this.setState({ badUsername: "" });
    }

    if (password === "") {
      // Last name is missing or invalid? TODO: Invalid part
      this.setState({ badPassword: "Campo é obrigatório" });
      noValidationErrors = false;
    } else {
      this.setState({ badPassword: "" });
    }

    if (noValidationErrors) {
      this.props.onSubmitLogin(username, password);
    } else {
      console.error("Validation error on registration form.");
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // If we have a server error show them instead of the local errors
    if (nextProps.errors.badUsername) {
      this.setState({ badUsername: "Usuário ou senha incorretos" });
    }

    if (nextProps.errors.badPassword) {
      this.setState({ badPassword: "Usuário ou senha incorretos" });
    }
  }

  render() {
    return (
      <div className="login-box-main">
        <h3>Login</h3>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.validateUserInput(
              this.refs.email.value,
              this.refs.password.value
            );
          }}
        >

          <InputFieldErrorMessage message={this.state.badUsername} />
          <input
            ref="email"
            type="text"
            className="login-box-input"
            placeholder="Email"
          />

          <InputFieldErrorMessage message={this.state.badPassword} />
          <input
            ref="password"
            type="password"
            className="login-box-input"
            placeholder="Senha"
          />

          <div className="login-box-button">
            <Button
              text="ENTRAR"
              buttonType="button-primary"
              loading={this.props.isLoading}
              onClick={() => {}}
            />
          </div>

        </form>
      </div>
    );
  }
}

export default LoginBox;
