import React, { Component } from "react";
import "./ColorBar.css";

type Props = {
  needsAttention: boolean,
  routeId: string,
  error: string
};

class ColorBar extends Component {
  props: Props;

  render() {
    let colorClassName
    let title

    if (this.props.error === "error") {
      colorClassName = "color-bar-error";
      title = "Pedido em estado de erro."
    } else if (this.props.needsAttention === true) {
      colorClassName = "color-bar-attention";
      title = "Pedido precisa de atenção."
    } else if (!this.props.routeId) {
      colorClassName = "color-bar-no-route";
      title = "Pedido não foi roteirizado."
    } else {
      colorClassName = "color-bar-ok";
      title = "Ok."
    }

    return <div className={`color-bar ${colorClassName}`} title={`${title}`} />;
  }
}

export default ColorBar;
