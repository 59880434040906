import { authGet } from "../../common/salesApiHelpers";
import humps from "humps";
import { normalize } from "normalizr";
import * as schema from "./schema";
// Types

const Fraud = {
  async getUser(orderNumber: string) {
    const response = await authGet(`/v2/fraud_check/${orderNumber}/user`);
    const data = await response.json().then(b => humps.camelizeKeys(b));

    const normalized = normalize(data, schema.user);
    return normalized;
  },
  async getOrder(orderNumber: string) {
    const response = await authGet(`/v2/fraud_check/${orderNumber}/order`);
    const data = await response.json().then(b => humps.camelizeKeys(b));

    const normalized = normalize(data, schema.order);
    return normalized;
  }
};

export default Fraud;
