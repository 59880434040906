import React, { Component } from "react";
import { routes } from "./config";
// Components
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Validate } from "./employee";
import { Header, SimpleHeader } from "./header";
// Styles
import "./App.css";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div id="app">
          <Validate />
          <header>
            <Switch>
              <Route path="/login" component={SimpleHeader} />
              <Route component={Header} />
            </Switch>
          </header>

          <main>
            <div className="app-container">
              <Switch>
                {routes.map((route, i) => <Route key={i} {...route} />)}
                {/* If not route matches we should redirect to the login page */}
                <Route component={() => <Redirect to="/login" />} />
              </Switch>
            </div>
          </main>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
