// @flow
import React, { useState } from "react";
import moment from "moment";
// Components
import { Button } from "../../common";
import CancelationReasonDialog from "./CancelationReasonDialog";
import DistributionCenterDropDown from "./DistributionCenterDropDown";
import FulfillmentPaymentEditor from "./FulfillmentPaymentEditor";
import StateDropdown from "./StateDropdown";
//Styles
import "./FulfillmentEditor.css";

const FulfillmentEditor = ({
  fulfillment,
  updatingFulfillmentLoading,
  creatingRouterVisitLoading,
  handleUpdateFulfillment,
  handleResendToRouter,
}) => {
  const [cancelationDialogIsOpen, setCancelationDialogIsOpen] = useState(false);

  const handleCancelPrompt = () => {
    if (fulfillment.orderState !== 'complete') {
      window.alert('Você não pode cancelar um pedido aberto. Solicite ao Call Center para fechar o pedido e tente cancelar novamente.')

      return;
    }

    setCancelationDialogIsOpen(true);
  }

  const handleOnConfirmOrderCancelation = (reason) => {
    if (window.confirm("Esta ação não pode ser desfeita. Quer mesmo cancelar este pedido?")) {
      handleUpdateFulfillment({
        nextState: "canceled",
        cancelationReason: reason
      });
      setCancelationDialogIsOpen(false);
    }
  }

  const handleApprovePrompt = () => {
    const alertDeliveryTime = fulfillment
      ? moment(fulfillment.deadline).isAfter(
        moment().add(2, "hours")
      )
      : false;

    let decision = true;

    if (alertDeliveryTime) {
      decision = window.confirm(
        `Tem certeza? Ainda faltam ${moment(
          fulfillment.deadline
        ).fromNow(true)} para inicio do horário de entrega desse pedido!`
      );
    }

    if (decision) {
      handleUpdateFulfillment({
        nextState: "preparation_queue"
      });
    }
  }

  const handleDenyPaymentPrompt = () => {
    let decision = window.confirm(
      `Tem certeza que gostaria de negar o pedido por suspeita de pagamento?`
    );

    if (decision) {
      handleUpdateFulfillment({
        nextState: "payment_denied"
      });
    }
  }

  const handleApprovePaymentPrompt = () => {
    handleUpdateFulfillment({
      nextState: "pending",
    });
  }

  const handleReevaluatePaymentPrompt = () => {
    let decision = window.confirm(
      `Tem certeza que gostaria de reavaliar o pagamento do pedido?`
    );

    if (decision) {
      handleUpdateFulfillment({
        nextState: "payment_evaluating"
      });
    }
  }

  return (
    <div className="fulfillment-editor-main">
      <CancelationReasonDialog
        orderCancelationDialogIsOpen={cancelationDialogIsOpen}
        handleOnConfirmOrderCancelationDialog={handleOnConfirmOrderCancelation}
        handleOnCloseOrderCancelationDialog={() => setCancelationDialogIsOpen(false)}
      />
      <h3>Editor de pedido</h3>
      {fulfillment.state === "payment_evaluating" ? ( //payment_evaluating
        <FulfillmentPaymentEditor
          handleUpdateFulfillment={handleUpdateFulfillment}
          loading={updatingFulfillmentLoading}
          flagged={fulfillment.flagged}
          hasPendingPayment={fulfillment.hasPendingPayment}
          textButton1="PAGAMENTO SUSPEITO"
          buttonType1="button-secondary"
          onClick1={handleDenyPaymentPrompt}
          textButton2="APROVAR PAGAMENTO"
          buttonType2="button-quinary"
          onClick2={handleApprovePaymentPrompt}
        />
      ) : fulfillment.state === "payment_denied" ? ( //payment_denied
        <FulfillmentPaymentEditor
          handleUpdateFulfillment={handleUpdateFulfillment}
          loading={updatingFulfillmentLoading}
          flagged={fulfillment.flagged}
          textButton1="CANCELAR"
          buttonType1="button-secondary"
          onClick1={handleCancelPrompt}
          textButton2="REAVALIAR PAGAMENTO"
          buttonType2="button-sextary"
          onClick2={handleReevaluatePaymentPrompt}
        />
      ) : fulfillment.state !== "pending" ? (
        <div className="fulfillment-editor-form-container">
          <StateDropdown
            state={fulfillment.state}
            orderState={fulfillment.orderState}
            handleUpdateFulfillment={handleUpdateFulfillment}
          />

          <DistributionCenterDropDown
            distributionCenter={fulfillment.distributionCenter}
            handleUpdateFulfillment={handleUpdateFulfillment}
            disabled={fulfillment.state !== "error"}
          />
          <div className="fulfillment-editor-container">
            <div className="fulfillment-editor">
              <Button
                text={
                  fulfillment.flagged ? "Desinalizar" : "Sinalizar"
                }
                buttonType={
                  fulfillment.flagged
                    ? "button-flagged"
                    : "button-not-flagged"
                }
                onClick={() =>
                  handleUpdateFulfillment({
                    flagged: !fulfillment.flagged
                  })
                }
                loading={updatingFulfillmentLoading}
              />
            </div>

            <div className="fulfillment-editor">
              <Button
                text={
                  !fulfillment.needsAttention
                    ? "Sem Alerta"
                    : "Apagar Alerta"
                }
                buttonType={"button-needs-attention"}
                onClick={() =>
                  handleUpdateFulfillment({
                    needsAttention: false
                  })
                }
                loading={updatingFulfillmentLoading}
                disabled={!fulfillment.needsAttention}
              />
            </div>

            <div className="fulfillment-editor">
              <Button
                text="Atualizar Visita"
                buttonType={"button-tertiary"}
                onClick={() =>
                  handleResendToRouter(
                    fulfillment.number
                  )
                }
                loading={creatingRouterVisitLoading}
                disabled={updatingFulfillmentLoading}
              />
            </div>

            {updatingFulfillmentLoading ? (
              <div className="fulfillment-loading">
                <i className="fa fa-spinner fa-spin" />
              </div>
            ) : (
              <div className="fulfillment-loading" />
            )}
          </div>
        </div>
      ) : (
        <div className="fulfillment-editor-button-container">
          <Button
            text="CANCELAR"
            onClick={handleCancelPrompt}
            buttonType="button-secondary"
            loading={updatingFulfillmentLoading}
          />
          <div className="fulfillment-central-div">
            <DistributionCenterDropDown
              distributionCenter={fulfillment.distributionCenter}
              defaultDistributionCenter="pituba"
              handleUpdateFulfillment={handleUpdateFulfillment}
            />
            <div className="flagged-button-div">
              <Button
                text={
                  fulfillment.flagged ? "Desinalizar" : "Sinalizar"
                }
                buttonType={
                  fulfillment.flagged
                    ? "button-flagged"
                    : "button-not-flagged"
                }
                onClick={() =>
                  handleUpdateFulfillment({
                    flagged: !fulfillment.flagged
                  })
                }
                loading={updatingFulfillmentLoading}
              />
            </div>
          </div>
          <Button
            text="PRODUZIR"
            buttonType={"button-primary"}
            onClick={handleApprovePrompt}
            loading={updatingFulfillmentLoading}
            disabled={!fulfillment.distributionCenter}
          />
        </div>
      )}
      {fulfillment.state === "error" &&
        fulfillment.notice ? (
        <div className="fulfillment-editor-error-reason">
          Razão do estado de erro: {fulfillment.notice}
        </div>
      ) : null}
    </div>
  );
}

export default FulfillmentEditor;
