// @flow
import React from "react";
// Components
import GoogleMapReact from "google-map-react";
import MapMarker from "./MapMarker";
// Types
import type { Fulfillment } from "../../types";
// Styles
import "./FulfillmentSortMap.css";

import runtimeEnv from "@avantsoft/js-runtime-env";
const env = runtimeEnv();

type Props = {
  fulfillments: Fulfillment[]
};

class FulfillmentSortMap extends React.PureComponent {
  props: Props;

  render() {
    if (this.props.loading) {
      return null;
    }
    return (
      <div className="fulfillment-sort-map-container">
        <GoogleMapReact
          bootstrapURLKeys={{ key: env.REACT_APP_GCP_API_KEY }}
          defaultCenter={{ lat: -13.007726, lng: -38.4635153 }} // TODO: This should be retrieved from selected distribution center
          defaultZoom={13}
        >
          {this.props.fulfillments.map((fulfillment, index) => (
            <MapMarker
              key={index}
              lat={fulfillment.address.latitude}
              lng={fulfillment.address.longitude}
              text={fulfillment.position}
            />
          ))}
        </GoogleMapReact>
      </div>
    );
  }
}

export default FulfillmentSortMap;
