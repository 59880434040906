// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
// Selectors
import {
  issueSourcesListSelector,
  issueSourceByIdSelector,
  issueSourcesLoadingSelector
} from "../selectors";
// Actions

// Types
import type { Dispatch } from "../../common";
import type { IssueSource } from "../types";

type OwnProps = {
  issueSources: IssueSource[],
  issueSourcesLoading: boolean,
  issueSourceById: (id: number) => IssueSource
};

function issueSourcesConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {
      issueSources: issueSourcesListSelector(state),
      issueSourceById: (id: number) => issueSourceByIdSelector(state, id),
      issueSourcesLoading: issueSourcesLoadingSelector(state)
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {};
  };

  return connect(mapStateToProps, mapDispatchToProps)(
    class IssueSourcesConnector extends Component {
      props: OwnProps;

      render() {
        return (
          <WrappedComponent
            {...this.props}
            issueSources={this.props.issueSources}
            issueSourceById={this.props.issueSourceById}
            issueSourcesLoading={this.props.issueSourcesLoading}
          />
        );
      }
    }
  );
}

export default issueSourcesConnector;
