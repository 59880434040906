// @flow
import React from "react";
import _ from "lodash";
import moment from "moment";
// Components

// Composers
import { connect } from "react-redux";
// Import Actions
import { findFulfillmentVersions } from "../actions";
// Selectors
import {
  fulfillmentVersionsByOrderNumberSelector,
  fulfillmentVersionsLoadingSelector
} from "../selectors";
// Types
import type { FulfillmentVersion } from "../types";
import type { Dispatch } from "../../common";
// Styles
import "./FulfillmentVersions.css";

type Props = {
  fulfillmentId: string,
  // From connector
  fulfillmentVersions: FulfillmentVersion,
  fulfillmentVersionsLoading: boolean,
  handleFindFulfillmentVersions: () => void
};

class FulfillmentVersions extends React.Component {
  constructor(props: Props) {
    super(props);

    // We should dispatch the action to get fulfillment versions
    props.handleFindFulfillmentVersions();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.fulfillmentId !== this.props.fulfillmentId) {
      // We should dispatch the action to get fulfillment versions
      this.props.handleFindFulfillmentVersions();
    }
  }

  render() {
    return (
      <div className="fulfillment-versions-main">
        <h3>Versões do pedido</h3>
        {this.props.fulfillmentVersionsLoading ? <p>LOADING</p> : null}

        {this.props.fulfillmentVersions.map((version, ind) => (
          <div key={ind} className="fulfillment-version-hit-container">
            <div key={ind} className="fulfillment-version-hit">
              <div className="fulfillment-version-date">
                {moment(version.createdAt).fromNow()}:{" "}
                {version.employee && "Funcionário ID: " + version.employee}
              </div>
              <div>
                {_.toPairs(version.changeset).map(([key, value], ind) => (
                  <p key={ind}>
                    {key}: {JSON.stringify(value[0])} -->{" "}
                    {JSON.stringify(value[1])}
                  </p>
                ))}
              </div>
            </div>
            <hr />
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    fulfillmentVersions: fulfillmentVersionsByOrderNumberSelector(
      state,
      props.fulfillmentId
    ),
    fulfillmentVersionsLoading: fulfillmentVersionsLoadingSelector(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    handleFindFulfillmentVersions: () => {
      dispatch(findFulfillmentVersions(stateProps.fulfillmentId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FulfillmentVersions);
