// @flow
import React from "react";
// Components
import { Button } from "../../common";
// Types
import type { Issue } from "../types";
//Style
import "./IssueList.css";

type Props = {
  issues: Issue[],
  removingLineItemIssue: (id: number) => boolean,
  handleDeleteIssue: (id: number) => void
};

class IssueList extends React.Component {
  props: Props;

  render() {
    return (
      <div className="issue-list-main">
        {this.props.issues.map(l => (
          <div key={l.id}>
            <hr />
            <div className="issue-item">
              <div className="issue-text">
                {l.type === "Produto em falta" ? (
                  <p title="Esse erro aparece para o Call Center.">
                    {l.type} &#128222;{" "}
                  </p>
                ) : (
                  <p>{l.type}</p>
                )}
                <p>{`Detalhes: ${l.details ? l.details : "--"}`}</p>
                <p className="issue-solution">{`Solução: ${
                  l.solution ? l.solution : "--"
                }`}</p>
              </div>
              {!l.discardedAt ? (
                <Button
                  text="Deletar"
                  buttonType="button-tertiary"
                  loading={this.props.removingLineItemIssue(l.id)}
                  onClick={() => this.props.handleDeleteIssue(l.id)}
                />
              ) : (
                <Button
                  text="Resolvido"
                  buttonType="button-tertiary"
                  disabled
                />
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default IssueList;
