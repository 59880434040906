// @flow
import React, { Component } from "react";
import "./WeightLimitedIcon.css";

type Props = {
  weightLimited: boolean
};

class WeightLimitedIcon extends Component {
  props: Props;

  render() {
    return this.props.weightLimited ? (
      <div className="weight-limited">
        <i
          className="fa fa-balance-scale"
          title="Não pode exceder peso total."
        />
      </div>
    ) : null;
  }
}

export default WeightLimitedIcon;
