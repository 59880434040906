// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors
import {
    AllDriversSelector
} from '../selectors';
// Actions
import {
    searchDrivers
} from '../actions';
// Types

type OwnProps = {
  // connector
  drivers: Any[],
  getDrivers: () => void,
};

/* 
  COMPONENT BEHAVIOR DESCRIPTION
  If there is at least one address in the address list display the address list.
  If there are no addresses go to the add address
  If user selects to edit an address go to the same page as add new address but prefill
*/

function routeConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {
      drivers: AllDriversSelector(state),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {
      getDrivers: () => {
        dispatch(searchDrivers());
      }
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(
    class UserAddressConnector extends Component {
      constructor(props: OwnProps) {
        super(props);
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            drivers={this.props.drivers}
          />
        );
      }
    },
  );
}

export default routeConnector;