// @flow
import React from "react";
// Components
import { Button } from '../../common';
//Style
import "./ObservationItem.css";

type Props = {
  observation: string,
  loading: boolean,
  onSubmit: (observation: string) => void,
};

type State = {
  observation: string,
};

class ObservationItem extends React.Component {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      observation: props.observation,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // If the observation field has changed server side and we don't have unsaved changes on the observation field
    if (
      this.props.observation !== nextProps.observation &&
      this.props.observation === this.state.observation // No unsaved change
    ) {
      this.setState({ observation: nextProps.observation || '' });
    }
  }

  render() {
    return (
      <div className="observation-item-main">
        <textarea
          placeholder="Observações para a produção (lembre de salvar)"
          className="observation-editor-text"
          onChange={e => this.setState({ observation: e.target.value })}
          value={this.state.observation}
        />
        <div className="observation-editor-button">
          <Button
            text="SALVAR"
            buttonType="button-tertiary"
            loading={this.props.loading}
            onClick={() => this.props.onSubmit(this.state.observation)}
          />
        </div>
      </div>
    );
  }
}

export default ObservationItem;
