const ADDRESS_FORM_FIELDS_CONSTRAINTS = {
  firstName: {
    required: 'Nome é obrigatório',
    maxLength: {
      value: 100,
      message: 'Nome muito longo. Máximo 100 letras',
    },
  },
  lastName: {
    maxLength: {
      value: 100,
      message: 'Sobrenome muito longo. Máximo 100 letras',
    },
  },
  phone: {
    required: 'Telefone é obrigatório',
    validate: {
      validPhone: value => {
        const afterDataHandling = value.replace(/-|\.|\s/g, '');
        return (
          (afterDataHandling.length >= 9 && afterDataHandling.length <= 13) ||
          'Telefone deve ter 9 a 13 números com DDD'
        );
      },
    },
  },
  address1: {
    required: 'Endereço é obrigatório',
    maxLength: {
      value: 100,
      message: 'Endereço muito longo. Máximo 100 letras',
    },
    minLength: {
      value: 5,
      message: 'Endereço muito curto',
    },
  },
  address2: {
    maxLength: {
      value: 100,
      message: 'Endereço muito longo. Máximo 100 letras',
    },
  },
  neighborhood: {
    required: 'Bairro é obrigatório',
    maxLength: {
      value: 30,
      message: 'Nome do bairro muito longo. Máximo 30 letras',
    },
  },
  city: {
    required: 'Cidade é obrigatório',
    maxLength: {
      value: 30,
      message: 'Nome da cidade muito longo. Máximo 30 letras',
    },
  },
  zipcode: {
    required: 'CEP é obrigatório',
    validate: {
      valueLength: value =>
        value.replace(/-|\.|\s/g, '').length == 8 || 'CEP são apenas 8 números',
      expectedStartFromValue: value =>
        value.startsWith('4') || 'Fora da area de cobertura',
    },
  },
  reference: {
    maxLength: {
      value: 30,
      message: 'Referência muito longa. Máximo 30 letras',
    },
  },
  additionalInformation: {},
};

export { ADDRESS_FORM_FIELDS_CONSTRAINTS };
