// @flow
import { createSelector } from "reselect";
// Types
import type { ReduxState } from "../../common";
import type { StoreState } from "../types";

const thisStateSelector = (state: ReduxState): StoreState => {
  return state.issueSources;
};

//////////////////// ISSUE SOURCES /////////////////////////////////
const issueSourcesMapSelector = createSelector(
  thisStateSelector,
  state => state.issueSources
);

export const issueSourcesListSelector = createSelector(
  thisStateSelector,
  issueSourcesMapSelector,
  (state, issueSourceMap) => {
    return state.currentList.map(id => issueSourceMap[id]);
  }
);

export const issueSourceByIdSelector = (state, id) => {
  const issueSourceMap = issueSourcesMapSelector(state);

  return issueSourceMap[id] || null;
};

//////////////////// ERRORS /////////////////////////////////
export const errorsSelector = createSelector(
  thisStateSelector,
  state => state.errors
);

//////////////////// Loading Selectors //////////////////////
export const issueSourcesLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingIssueSources
);
