import {
  authGet,
  authPut,
  authPost,
  authGetSalesXml,
  authGetSalesReceipt,
} from '../../common/api';
import {
  authPut as authPutSales,
  authPost as authPostSales,
  authGet as authGetSales,
} from '../../common/salesApiHelpers';
import humps from 'humps';
import _ from 'lodash';
import { normalize } from 'normalizr';
import * as schema from './schema';
import type { UpdatableFulfillment, UpdateAddress } from '../types';

const Fulfillment = {
  async searchFulfillments(searchParams: string) {
    const response = await authGet(`/orders${searchParams}`);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.fulfillments);
    return normalized;
  },
  async getFulfillment(id: string) {
    const response = await authGet(`/orders/${id}`);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.fulfillment);
    return normalized;
  },
  async getFulfillmentVersions(id: string) {
    // NOTE: This API is NOT normalized!
    const response = await authGet(`/orders/${id}/versions`);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    return data;
  },
  async getTracker(orderNumber: string) {
    const response = await authGetSales(`/v2/orders/${orderNumber}/tracker`);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.tracker);
    return normalized;
  },
  async createRouterVisit(id: string) {
    const params = {};
    const response = await authPostSales(
      `/v2/orders/${id}/visit_resend`,
      params,
    );
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.fulfillment); // TODO: DANGER the response of this API is not a fulfillment object!
    return normalized;
  },
  async editFulfillment(id: string, updatableProperties: UpdatableFulfillment) {
    // Decamelize keys and stringify before sending
    // Remove entries that are null or blank
    let params = _.omitBy(updatableProperties, v => v === null || v === '');
    params = humps.decamelizeKeys(params);

    const response = await authPut(`/orders/${id}`, params);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.fulfillment);
    return normalized;
  },
  async editFulfillmentPosition(id: string, desiredPosition: number) {
    const body = { desired_position: desiredPosition };
    const response = await authPut(`/orders/${id}/sort`, body);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.fulfillments);
    return normalized;
  },
  async editLineItem(
    lineItemId: number,
    updatableProperties: UpdatableLineItem,
  ) {
    // Decamelize keys and stringify before sending
    // Remove entries that are null or blank
    let params = _.omitBy(updatableProperties, v => v === null || v === '');
    params = humps.decamelizeKeys(params);

    const response = await authPut(`/line_items/${lineItemId}`, params);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.lineItem);
    return normalized;
  },
  async createLineItemIssue(
    lineItemId: number,
    issueSourceId: number,
    details: string,
  ) {
    const body = {
      issue_source_id: parseInt(issueSourceId, 10),
      details,
    };

    const response = await authPost(`/line_items/${lineItemId}/issues`, body);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.issue);
    return normalized;
  },
  async resolveLineItemIssue(id: number) {
    const body = {
      handled: true,
    };
    const response = await authPut(`/issues/${id}`, body);

    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.issue);
    return normalized;
  },
  async updateAddress(id: number, updatableProperties: UpdateAddress) {
    let params = _.omitBy(updatableProperties, v => v === undefined);
    params = humps.decamelizeKeys(params);

    const response = await authPutSales(`/v2/addresses/${id}`, params);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.address);
    return normalized;
  },
  async updateObservation(orderNumber: string, observation: string) {
    const body = {
      observation,
    };
    const response = await authPutSales(
      `/api/v1/orders/${orderNumber}/update_observation`,
      body,
    );
    const data = await response.json().then(b => humps.camelizeKeys(b));

    const normalized = normalize(data, schema.fulfillment);
    return normalized;
  },
  async getUserSales(userId: number) {
    const response = await authGetSales(`/v2/users/${userId}`);
    const data = await response.json().then(b => humps.camelizeKeys(b));

    const normalized = normalize(data, schema.user);
    return normalized;
  },

  /////////////// RELATED TO STATE TRANSITIONS ////////////////
  // async approveFulfillment(orderNumber: string) {
  //   const response = await authPut(`/orders/${orderNumber}/approved`);
  //   const data = await response.json().then(b => humps.camelizeKeys(b));
  //   const normalized = normalize(data, schema.fulfillment);
  //   return normalized;
  // },
  ////////////// RELATED TO SALES XML FOR NFE ////////////////
  async getSalesXml(orderNumber: string) {
    const response = await authGetSalesXml(
      `/api/v1/orders/${orderNumber}/receipt`,
    );
    const data = await response.text().then();
    return data;
  },

  async getSalesReceipt(receiptUrl) {
    const response = await authGetSalesReceipt(receiptUrl);
    const data = await response.blob().then();
    return data;
  },
};

export default Fulfillment;
