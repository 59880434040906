// @flow
import React from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import { minutesToHoursAndMinutes } from "../../common";
//Styles
import "./StatisticsProductTable.css";

// Takes in unitForQuantity and returns displayable units
const unitConverter = (unitForQuantity: string) => {
  return unitForQuantity === "g" ? "Kg" : "un";
};

// Converts quantity into locale specific value taking into account quantity
const quantityConverter = (quantity: number, unitForQuantity: string) => {
  if (quantity == null) {
    return "- -";
  }

  return (
    parseFloat(
      unitForQuantity === "g" ? quantity / 1000 : quantity
    ).toLocaleString("pt-br", {
      currency: "BRL"
    }) +
    " " +
    unitConverter(unitForQuantity)
  );
};

let prevSku = "";
let prevUnit = "";
let cont = 0;
let totalSum = 0;
let producedSum = 0;
let estimatedPreparationTimeSum = 0;

const differentSku = (
  sku,
  atualTotal,
  atualProduced,
  atualEstimatedPreparationTime,
  unit
) => {
  cont = 0;
  totalSum = 0;
  producedSum = 0;
  estimatedPreparationTimeSum = 0;
  prevSku = sku;
  prevUnit = unit;
  totalSum += atualTotal;
  producedSum += atualProduced;
  estimatedPreparationTimeSum += Number(atualEstimatedPreparationTime);
};

const equalSku = (
  sku,
  atualTotal,
  atualProduced,
  atualEstimatedPreparationTime,
  unit
) => {
  totalSum += atualTotal;
  producedSum += atualProduced;
  estimatedPreparationTimeSum += Number(atualEstimatedPreparationTime);
  cont++;
  prevSku = sku;
  prevUnit = unit;
};

type Props = {
  data: any,
  onClickRow: (product: {}) => void
};

type State = {
  manufacturer: string,
  product: string
};

class StatisticsProductTable extends React.Component {
  state: State;
  constructor(props) {
    super(props);
    this.state = { manufacturer: "*", product: "" };
  }

  componentDidUpdate(prevProps: Props) {
    if (!_.isEqual(this.props.data, prevProps.data)) {
      this.setState({ manufacturer: "*", product: "" });
    }
  }
  // return sum of sumFunction with kg and un quantities
  getSumOfItemOnStatistics(groupedData, parameterName) {
    return _.toPairs(groupedData).map(entryForUnit => {
      return `${quantityConverter(
        _.reduce(
          entryForUnit[1],
          (result, value) => {
            return (result += value[parameterName] ? value[parameterName] : 0);
          },
          0
        ),
        entryForUnit[0]
      )} ${_.last(Object.values(groupedData)) === entryForUnit[1] ? "" : "+ "}`;
    });
  }

  render() {
    // Here we apply our local filters to the data
    const filteredData = _.filter(
      this.props.data,
      data =>
        (this.state.manufacturer == "*" || // * means all manufacturers should be shown
          data.manufacturer === this.state.manufacturer) &&
        data.name.toLowerCase().includes(this.state.product.toLowerCase())
    );

    const groupedData = _.groupBy(
      filteredData,
      dataPoint => dataPoint.unitForQuantity
    );

    const totalSpentTimeToPrepare = filteredData.reduce(
      (accumulator, currentValue) => {
        return accumulator + Number(currentValue.estimatedPreparationTime);
      },
      0
    );
    const manufacturers = _.uniq(_.map(this.props.data, "manufacturer"));
    const totalFinal = this.getSumOfItemOnStatistics(
      groupedData,
      "totalQuantityOrdered"
    );
    const ProducedFinal = this.getSumOfItemOnStatistics(
      groupedData,
      "totalProducedQuantity"
    );

    return (
      <div className="statistics-product-table-main">
        <div className="internal-statistics-filter-main">
          <div>
            <p>Produto: </p>
            <input
              className="internal-statistics-filter-main-filter-input"
              value={this.state.product}
              onChange={e => {
                this.setState({ product: e.target.value });
              }}
            />
          </div>
          <div>
            <p>Fabricante: </p>
            <select
              className="internal-statistics-filter-main-filter-input"
              value={this.state.manufacturer}
              onChange={e => {
                this.setState({ manufacturer: e.target.value });
              }}
            >
              <option value={"*"}>Todos</option>
              {manufacturers.map((value, index) => (
                <option key={value}>{value}</option>
              ))}
            </select>
            <CSVLink
              data={filteredData.map(o => [
                o.sku,
                o.name,
                o.manufacturer,
                o.totalQuantityOrdered,
                o.totalProducedQuantity,
                o.unitForQuantity
              ])}
              headers={[
                "SKU",
                "Produto",
                "Fabricante",
                "Total Pedido",
                "Total Produzido",
                "Unidade de Medida"
              ]}
              filename={`EstatisticasDeProdutos.csv`}
              enclosingCharacter="\r\n"
            >
              <i className="fa fa-download fa-fw"></i>
            </CSVLink>
          </div>
        </div>
        {filteredData && filteredData[0] ? (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Fabricante</th>
                  <th>SKU</th>
                  <th>Total</th>
                  <th className="statistics-product-table-hidden-on-sm">
                    Produzido
                  </th>
                  <th className="statistics-product-table-hidden-on-sm">
                    Tempo Estimado (hh:mm)
                  </th>
                </tr>
              </thead>

              {filteredData.map((o, i) => (
                <tbody key={i}>
                  {prevSku !== o.sku ? (
                    cont > 0 ? (
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td>
                          <b>{prevSku}</b>
                        </td>
                        <td>
                          <b>{quantityConverter(totalSum, prevUnit)}</b>
                        </td>
                        <td className="statistics-product-table-hidden-on-sm">
                          <b>{quantityConverter(producedSum, prevUnit)}</b>
                        </td>
                        <td className="statistics-product-table-hidden-on-sm">
                          <b>
                            {minutesToHoursAndMinutes(
                              Math.ceil(estimatedPreparationTimeSum)
                            )}
                          </b>
                        </td>
                        {differentSku(
                          o.sku,
                          o.totalQuantityOrdered,
                          o.totalProducedQuantity,
                          o.estimatedPreparationTime,
                          o.unitForQuantity
                        )}
                      </tr>
                    ) : (
                      differentSku(
                        o.sku,
                        o.totalQuantityOrdered,
                        o.totalProducedQuantity,
                        o.estimatedPreparationTime,
                        o.unitForQuantity
                      )
                    )
                  ) : (
                    equalSku(
                      o.sku,
                      o.totalQuantityOrdered,
                      o.totalProducedQuantity,
                      o.estimatedPreparationTime,
                      o.unitForQuantity
                    )
                  )}
                  <tr onClick={() => this.props.onClickRow(o)}>
                    <td>{o.name}</td>
                    <td>{o.manufacturer}</td>
                    <td>{o.sku}</td>
                    <td>
                      {quantityConverter(
                        o.totalQuantityOrdered,
                        o.unitForQuantity
                      )}
                    </td>
                    <td className="statistics-product-table-hidden-on-sm">
                      {quantityConverter(
                        o.totalProducedQuantity,
                        o.unitForQuantity
                      )}
                    </td>
                    <td className="statistics-product-table-hidden-on-sm">
                      {minutesToHoursAndMinutes(
                        Math.ceil(o.estimatedPreparationTime)
                      )}
                    </td>
                  </tr>
                </tbody>
              ))}
              <tfoot>
                <tr>
                  <td>
                    <b>Total:</b>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>{totalFinal}</b>
                  </td>
                  <td className="statistics-product-table-hidden-on-sm">
                    <b>{ProducedFinal}</b>
                  </td>
                  <td className="statistics-product-table-hidden-on-sm">
                    <b>
                      {minutesToHoursAndMinutes(
                        Math.ceil(totalSpentTimeToPrepare)
                      )}
                    </b>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : (
          <div>Sem dados para gerar tabela.</div>
        )}
      </div>
    );
  }
}

export default StatisticsProductTable;
