// @flow
import React from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { translateAasm, truncateString } from "../../common";
// Components
import { Draggable } from "react-beautiful-dnd";
import NaturalDragAnimation from "natural-drag-animation-rbdnd";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import distance from "@turf/distance";
//Style
import "./VisitItem.css";
//Helpers
import {
  quantityConverter,
  distributionCenterGeoLocation,
} from "../../common/commonHelpers";
// Composers
import { connect } from "react-redux";
//Selectors
import * as Selectors from "../selectors";
//Type

const VirtualVisitItem = ({
  index,
  visitId,
  visitById,
  taskById,
  addressById,
  timeWindowById,
  selectedVisitId,
  onVisitClick,
  isRoutingVisits,
}) => {
  const visit = visitById(visitId);
  const timeWindow = timeWindowById(visit.timeWindow);
  const address = addressById(visit.address) ? addressById(visit.address) : {};

  return (
    <Draggable draggableId={`virtual-visit-${visit.id}`} index={index}>
      {(provided, snapshot) => (
        <NaturalDragAnimation
          style={provided.draggableProps.style}
          snapshot={snapshot}
        >
          {(style) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={style}
              onClick={isRoutingVisits ? () => {} : () => onVisitClick()}
            >
              <div
                className={`virtual-visit-card ${
                  selectedVisitId === visit.id ? "visit-card-alert-card" : ""
                }`}
                id={`visit-scroll-${visit.id}`}
              >
                <div
                  className={`visit-card-info-line ${
                    _.isEmpty(address) ||
                    distance(
                      [
                        distributionCenterGeoLocation("pituba").lat,
                        distributionCenterGeoLocation("pituba").lng,
                      ],
                      [address.latitude, address.longitude],
                      { units: "kilometers" }
                    ) > 100
                      ? "visit-item-color-failed"
                      : ""
                  }`}
                >
                  <i className="fa fa-map-signs" aria-hidden="true"></i>
                  <p>{`${
                    _.isEmpty(address)
                      ? "Sem Endereço"
                      : address.neighborhood + " - " + address.city
                  }`}</p>{" "}
                </div>

                <div className="visit-card-info-line">
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                  <p>
                    {`${
                      _.isEmpty(timeWindow)
                        ? "Sem Tempos"
                        : "(" +
                          moment(timeWindow.startTime)
                            .utcOffset("-0300")
                            .format("DD/MM") +
                          ") " +
                          moment(timeWindow.startTime)
                            .utcOffset("-0300")
                            .format("LT") +
                          " a " +
                          "(" +
                          moment(timeWindow.endTime)
                            .utcOffset("-0300")
                            .format("DD/MM") +
                          ") " +
                          moment(timeWindow.endTime)
                            .utcOffset("-0300")
                            .format("LT")
                    }`}{" "}
                  </p>
                </div>

                <div className="visit-card-info-line-additional-info">
                  <span
                    data-tip=""
                    data-for="visit-item-tooltip-info-estimated-time-at-stop"
                  >
                    <ReactTooltip id="visit-item-tooltip-info-estimated-time-at-stop">
                      <p>Tempo Estimado em Visita</p>
                    </ReactTooltip>
                    <i className="fa fa-hourglass-half" aria-hidden="true"></i>
                    <p>{`${
                      Math.floor(visit.estimatedTimeAtStop / 60) + " min"
                    }`}</p>
                  </span>
                  <span
                    data-tip=""
                    data-for="visit-item-tooltip-info-time-to-prepare"
                  >
                    <ReactTooltip id="visit-item-tooltip-info-time-to-prepare">
                      <p>Tempo de Preparação</p>
                    </ReactTooltip>

                    <i className="fa fa-bolt" aria-hidden="true"></i>
                    <p>{`${visit.estimatedPreparationTime + " min"}`}</p>
                  </span>
                  <span data-tip="" data-for="visit-item-tooltip-info-weight">
                    <ReactTooltip id="visit-item-tooltip-info-weight">
                      <p>Peso</p>
                    </ReactTooltip>
                    <i className="fa fa-balance-scale" aria-hidden="true"></i>
                    <p>{quantityConverter(visit.weight, "g")}</p>
                  </span>
                </div>

                <div className="visit-card-info-line-tasks">
                  {visit.tasks &&
                    visit.tasks.map((taskId) => {
                      const task = taskById(taskId);
                      switch (task.type) {
                        case "GenericTask":
                          return (
                            <p key={taskId}>
                              {taskId} - {truncateString(task.instructions, 40)}{" "}
                              {task.failedAt ? (
                                <span className="visit-item-color-failed">
                                  - {task.failedReason}
                                </span>
                              ) : null}
                            </p>
                          );
                        case "DeliverTask":
                          return (
                            <p key={taskId}>
                              <Link
                                key={taskId}
                                to={`/fulfillments/${task.orderNumber}`}
                              >
                                {task.orderNumber} -{" "}
                                {translateAasm(task.fulfillmentState)}
                                {task.fulfillmentPosition &&
                                  ` - Posição ${task.fulfillmentPosition}`}
                              </Link>{" "}
                              {task.failedAt ? (
                                <span className="visit-item-color-failed">
                                  - {task.failedReason}
                                </span>
                              ) : null}
                            </p>
                          );
                        default:
                          return (
                            <p key={taskId}>
                              {taskId}
                              {task.failedAt ? (
                                <span className="visit-item-color-failed">
                                  - {task.failedReason}
                                </span>
                              ) : null}
                            </p>
                          );
                      }
                    })}
                  {_.isEmpty(visit.tasks) && (
                    <p
                      className={`visit-card-info-line-tasks visit-item-color-failed`}
                    >
                      Sem Tarefa
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </NaturalDragAnimation>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => {
  return {
    visitById: (id) => Selectors.visitByIdSelector(state, id),
    taskById: (id) => Selectors.taskByIdSelector(state, id),
    addressById: (id) => Selectors.addressByIdSelector(state, id),
    timeWindowById: (id) => Selectors.timeWindowByIdSelector(state, id),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualVisitItem);
