// @flow
import { handleActions } from "redux-actions";
import { combineReducers } from "redux";
// Actions
import * as FraudAction from "../actions";
// Types
import type { Reducer } from "redux";
import type { Action } from "../../types";

/////////////// User Stats Related

// The user that is currently being displayed
const currentUser: Reducer<string, Action> = handleActions(
  {
    [FraudAction.fetchUser]: {
      next: (state, action) => action.payload.result
    },
    [FraudAction.fetchUserRequest]: (state, action) => null
  },
  null
);

// Users
const userStats: Reducer<string, Action> = handleActions(
  {
    [FraudAction.fetchUser]: {
      next: (state, action) => ({ ...state, ...action.payload.entities.users }),
      throw: (state, action) => state
    }
  },
  {}
);

// Orders
const userOrders: Reducer<string, Action> = handleActions(
  {
    [FraudAction.fetchUser]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.orders
      }),
      throw: (state, action) => state
    }
  },
  {}
);

// User addresses
const userAddresses: Reducer<string, Action> = handleActions(
  {
    [FraudAction.fetchUser]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.addresses
      }),
      throw: (state, action) => state
    }
  },
  {}
);

/////////////// Order Stats Related
const currentOrder: Reducer<string, Action> = handleActions(
  {
    [FraudAction.fetchOrder]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state
    },
    [FraudAction.fetchOrderRequest]: (state, action) => null
  },
  null
);

// Order stats
const orderStats: Reducer<string, Action> = handleActions(
  {
    [FraudAction.fetchOrder]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.orders
      }),
      throw: (state, action) => state
    }
  },
  {}
);

// Order addresses
const orderAddresses: Reducer<string, Action> = handleActions(
  {
    [FraudAction.fetchOrder]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.addresses
      }),
      throw: (state, action) => state
    }
  },
  {}
);

// Order payments
const orderPayments: Reducer<string, Action> = handleActions(
  {
    [FraudAction.fetchOrder]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.payments
      }),
      throw: (state, action) => state
    }
  },
  {}
);

// Loading
const initialLoadingState = {
  gettingUser: false,
  gettingOrder: false
};
const loading = handleActions(
  {
    [FraudAction.fetchUserRequest]: (state, action) => ({
      ...state,
      gettingUser: true
    }),
    [FraudAction.fetchUser]: (state, action) => ({
      ...state,
      gettingUser: false
    }),
    [FraudAction.fetchOrderRequest]: (state, action) => ({
      ...state,
      gettingOrder: true
    }),
    [FraudAction.fetchOrder]: (state, action) => ({
      ...state,
      gettingOrder: false
    })
  },
  initialLoadingState
);

const reducers = combineReducers({
  currentUser,
  userStats,
  userOrders,
  userAddresses,

  currentOrder,
  orderStats,
  orderAddresses,
  orderPayments,

  loading
});

export default reducers;
