// @flow
import { createAction } from "redux-actions";
// Types
import type { Dispatch } from "../../types";
// Apis
import api from "../api";
// Selectors
// Log
import { logException } from "../../common";

// Get orders statistics
export const fetchOrdersStatisticsRequest = createAction(
  "FETCH_ORDERS_STATISTICS_REQUEST"
);
export const fetchOrdersStatistics = createAction("FETCH_ORDERS_STATISTICS");

export function getOrderStatistics(searchParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchOrdersStatisticsRequest());
    try {
      const response = await api.getOrdersStatistics(searchParams);
      dispatch(fetchOrdersStatistics(response));
    } catch (err) {
      logException(err);
      dispatch(fetchOrdersStatistics(err));
    }
  };
}

// Get products statistics
export const fetchProductsStatisticsRequest = createAction(
  "FETCH_PRODUCTS_STATISTICS_REQUEST"
);
export const fetchProductsStatistics = createAction(
  "FETCH_PRODUCTS_STATISTICS"
);

export function getProductsStatistics(searchParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchProductsStatisticsRequest());
    try {
      const response = await api.getProductsStatistics(searchParams);
      dispatch(fetchProductsStatistics(response));
    } catch (err) {
      logException(err);
      dispatch(fetchProductsStatistics(err));
    }
  };
}

// Get preparation statistics
export const fetchPreparationStatisticsRequest = createAction(
  "FETCH_PREPARATION_STATISTICS_REQUEST"
);
export const fetchPreparationStatistics = createAction(
  "FETCH_PREPARATION_STATISTICS"
);

export function getPreparationtatistics(searchParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchPreparationStatisticsRequest());
    try {
      const response = await api.getPreparationStatistics(searchParams);
      dispatch(fetchPreparationStatistics(response));
    } catch (err) {
      logException(err);
      dispatch(fetchPreparationStatistics(err));
    }
  };
}


// Get preparation statistics
export const fetchTaskStateStatisticsRequest = createAction(
  "FETCH_TASK_STATE_STATISTICS_REQUEST"
);
export const fetchTaskStateStatistics = createAction(
  "FETCH_TASK_STATE_STATISTICS"
);

export function getTaskStateStatistics(searchParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchTaskStateStatisticsRequest());
    try {
      const response = await api.getTaskStateStatistics(searchParams);
      dispatch(fetchTaskStateStatistics(response));
    } catch (err) {
      logException(err);
      dispatch(fetchTaskStateStatistics(err));
    }
  }
}

// Get route travel statistics
export const fetchRouteTravelStatisticsRequest = createAction(
  "FETCH_ROUTE_TRAVEL_STATISTICS_REQUEST"
);
export const fetchRouteTravelStatistics = createAction(
  "FETCH_ROUTE_TRAVEL_STATISTICS"
);

export function getRouteTravelStatistics(searchParams: any) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchRouteTravelStatisticsRequest());
    try {
      const response = await api.getRouteTravelStatistics(searchParams);
      dispatch(fetchRouteTravelStatistics(response));
    } catch (err) {
      logException(err);
      dispatch(fetchRouteTravelStatistics(err));
    }
  };
}
