// @flow

export const translateAasm = (state) => {
  switch (state) {
    case "":
      return "Todos";
    case "payment_denied":
      return "Pagamento negado";
    case "payment_evaluating":
      return "Aguardando análise";
    case "pending":
      return "Aguardando liberação";
    case "preparation_queue":
      return "Fila de preparação";
    case "preparation":
      return "Preparação";
    case "error":
      return "Erro";
    case "packaging_queue":
      return "Fila de Empacotamento";
    case "packaging":
      return "Empacotamento";
    case "return_queue":
      return "Fila de devolução";
    case "ready":
      return "Pronto";
    case "disassembly":
      return "Devolução";
    case "storage":
      return "Em estocagem";
    case "canceled":
      return "Cancelado";
    default:
      return state;
  }
};

export const visitStates = (state) => {
  switch (state) {
    case "pending":
      return "Pendente";
    case "in_progress":
      return "Em progresso";
    case "success":
      return "Sucesso";
    case "failed":
      return "Falha";
    default:
      return state;
  }
};

export const routeStates = (state) => {
  switch (state) {
    case "open":
      return "aberto";
    case "pending":
      return "pendente";
    case "in_progress":
      return "em progresso";
    case "complete":
      return "completa";
    default:
      return state;
  }
};
