import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Components
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
// image
import PoweredByGoogleImage from '../images/powered_by_google_default.png';
// Helpers
import { citiesNamesHelper } from '../../common';
// Styles
import './AddressSearch.css';

const checkValidCity = addressLineItems => {
  const findCityInAddressLine = addresLine => {
    return _.find(addresLine, item =>
      _.find(item.types, type => type === 'administrative_area_level_2'),
    );
  };

  return _.includes(
    citiesNamesHelper,
    findCityInAddressLine(addressLineItems) &&
      findCityInAddressLine(addressLineItems).long_name,
  );
};

const formatAddress = (address, addressLatLng) => {
  const formattedAddress = {};

  address.forEach(element => {
    if (_.find(element.types, item => item === 'route')) {
      formattedAddress.address = element.long_name;
    } else if (_.find(element.types, item => item === 'street_number')) {
      formattedAddress.complement = element.long_name;
    } else if (
      _.find(element.types, item => item === 'sublocality') ||
      _.find(element.types, item => item === 'sublocality_level_1')
    ) {
      formattedAddress.neighborhood = element.long_name;
    } else if (
      _.find(element.types, item => item === 'administrative_area_level_1')
    ) {
      formattedAddress.state = element.long_name;
    } else if (
      _.find(element.types, item => item === 'administrative_area_level_2')
    ) {
      formattedAddress.city = element.long_name;
    } else if (_.find(element.types, item => item === 'postal_code')) {
      formattedAddress.cep = element.long_name;
    } else {
      console.log('Unused attribute: ', element);
    }
  });

  formattedAddress.latitude = addressLatLng.lat();
  formattedAddress.longitude = addressLatLng.lng();

  return formattedAddress;
};

const AddressSearch = ({ onSubmit }) => {
  const [address, setAddress] = useState('');

  const handleSelect = selected => {
    setAddress(selected);

    geocodeByAddress(selected).then(res => {
      // If the address is in one of the allowed cities we should pass it up to parent component
      const addressComponents = res[0] && res[0].address_components;
      const addressLatLng = res[0] && res[0].geometry.location;
      if (checkValidCity(addressComponents)) {
        onSubmit(formatAddress(addressComponents, addressLatLng));
      } else {
        console.error(
          'Cidade do endereço selecionado não consta na lista de cidades válidas.',
        );
      }
    });
  };

  return (
    window.google &&
    window.google.maps && (
      <PlacesAutocomplete
        onChange={setAddress}
        value={address}
        onSelect={handleSelect}
        shouldFetchSuggestions={address.length > 2}
        debounce={500}
        searchOptions={{
          country: 'br',
          location: new window.google.maps.LatLng(-12.977749, -38.50163),
          radius: 120000,
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          return (
            <div className="address-search-container">
              <div className="address-search-container-box">
                <input
                  id="input_text"
                  autoComplete="off"
                  {...getInputProps({
                    placeholder: 'Pesquisar Endereço',
                  })}
                />
                <div className="address-search-container-box-icon">
                  <i className="fa fa-search " />
                </div>
              </div>
              {suggestions.length > 0 && (
                <div className="address-search-container-autocomplete-container">
                  {suggestions.map((suggestion, ind) => {
                    const className = suggestion.active
                      ? 'address-serach-autocomplete-suggestion-item-active'
                      : 'address-serach-autocomplete-suggestion-item';
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                        key={ind}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                  <div className="address-search-dropdown-footer">
                    <img src={PoweredByGoogleImage} alt="Powered By Google" />
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
    )
  );
};

AddressSearch.propTypes = {
  onSubmit: PropTypes.func,
};

export default AddressSearch;
