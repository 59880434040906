// @flow
import { createAction } from 'redux-actions';
import { saveAs } from 'file-saver';
// Types
import type { Dispatch } from '../../common';
import type {
  SearchParams,
  UpdatableLineItem,
  UpdatableFulfillment,
  UpdateAddress,
} from '../types';
// Apis
import Api from '../api';
// Selectors

// Logger
import { logException } from '../../common';

// Search fulfillments
export const fetchFulfillmentsRequest = createAction(
  'FETCH_FULFILLMENTS_REQUEST',
);
export const fetchFulfillments = createAction('FETCH_FULFILLMENTS');

export function searchFulfillments(searchParams: SearchParams) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchFulfillmentsRequest());
    try {
      const response = await Api.searchFulfillments(searchParams);
      dispatch(fetchFulfillments(response));
    } catch (err) {
      logException(err);
      dispatch(fetchFulfillments(err));
    }
  };
}

// Get a specific fulfillment
export const fetchFulfillmentRequest = createAction(
  'FETCH_FULFILLMENT_REQUEST',
);
export const fetchFulfillment = createAction('FETCH_FULFILLMENT');

export function findFulfillment(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchFulfillmentRequest());
    try {
      const response = await Api.getFulfillment(id);
      dispatch(fetchFulfillment(response));
    } catch (err) {
      logException(err);
      dispatch(fetchFulfillment(err));
    }
  };
}

// Get the versions of a specific fulfillment
export const fetchFulfillmentVersionsRequest = createAction(
  'FETCH_FULFILLMENT_VERSIONS_REQUEST',
);
export const fetchFulfillmentVersions = createAction(
  'FETCH_FULFILLMENT_VERSIONS',
);

export function findFulfillmentVersions(orderNumber: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchFulfillmentVersionsRequest());
    try {
      const response = await Api.getFulfillmentVersions(orderNumber);
      dispatch(fetchFulfillmentVersions({ versions: response, orderNumber }));
    } catch (err) {
      logException(err);
      dispatch(fetchFulfillmentVersions(err));
    }
  };
}

// Update a specific fulfillment
export const updateFulfillmentRequest = createAction(
  'UPDATE_FULFILLMENT_REQUEST',
);
export const updateFulfillment = createAction('UPDATE_FULFILLMENT');

export function editFulfillment(
  id: string,
  updatableParameters: UpdatableFulfillment,
) {
  return async (dispatch: Dispatch) => {
    dispatch(updateFulfillmentRequest());
    try {
      const response = await Api.editFulfillment(id, updatableParameters);
      dispatch(updateFulfillment(response));
    } catch (err) {
      logException(err);
      dispatch(updateFulfillment(err));
    }
  };
}

// Get a specific tracker
export const fetchTrackerRequest = createAction('FETCH_TRACKER_REQUEST');
export const fetchTracker = createAction('FETCH_TRACKER');

export function findTracker(orderNumber: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchTrackerRequest());
    try {
      const response = await Api.getTracker(orderNumber);
      dispatch(fetchTracker(response));
    } catch (err) {
      logException(err);
      dispatch(fetchTracker(err));
    }
  };
}

// Send a specific fulfillment to the router to create or update a visit
export const postRouterVisitRequest = createAction('POST_ROUTER_VISIT_REQUEST');
export const postRouterVisit = createAction('POST_ROUTER_VISIT');

export function createRouterVisit(orderNumber: string) {
  return async (dispatch: Dispatch) => {
    dispatch(postRouterVisitRequest());
    try {
      const response = await Api.createRouterVisit(orderNumber);
      dispatch(postRouterVisit(response));
    } catch (err) {
      logException(err);
      dispatch(postRouterVisit(err));
    }
  };
}

// Update position of a specific fulfillment
export const updateFulfillmentPositionRequest = createAction(
  'UPDATE_FULFILLMENT_POSITION_REQUEST',
);
export const updateFulfillmentPosition = createAction(
  'UPDATE_FULFILLMENT_POSITION',
);

export function editFulfillmentPosition(id: string, desiredPosition: number) {
  return async (dispatch: Dispatch) => {
    dispatch(updateFulfillmentPositionRequest());
    try {
      const response = await Api.editFulfillmentPosition(id, desiredPosition);
      dispatch(updateFulfillmentPosition(response));
    } catch (err) {
      logException(err);
      dispatch(updateFulfillmentPosition(err));
    }
  };
}

// Update a line item
export const updateLineItemRequest = createAction('UPDATE_LINE_ITEM_REQUEST');
export const updateLineItem = createAction('UPDATE_LINE_ITEM');

export function editLineItem(
  lineItemId: number,
  updatableProperties: UpdatableLineItem,
) {
  return async (dispatch: Dispatch) => {
    dispatch(updateLineItemRequest({ lineItemId }));
    try {
      const response = await Api.editLineItem(lineItemId, updatableProperties);
      dispatch(updateLineItem(response));
    } catch (err) {
      logException(err);
      dispatch(updateLineItem(err));
    }
  };
}

// Create Line Item Issue
export const addLineItemIssueRequest = createAction(
  'ADD_LINE_ITEM_ISSUE_REQUEST',
);
export const addLineItemIssue = createAction('ADD_LINE_ITEM_ISSUE');

export function createLineItemIssue(
  lineItemId: number,
  issueSourceId: number,
  details: string,
) {
  return async (dispatch: Dispatch) => {
    dispatch(addLineItemIssueRequest({ lineItemId }));
    try {
      const response = await Api.createLineItemIssue(
        lineItemId,
        issueSourceId,
        details,
      );
      dispatch(addLineItemIssue({ ...response, lineItemId }));
    } catch (err) {
      logException(err);
      dispatch(addLineItemIssue(err));
    }
  };
}

// Delete issue
export const setAsResolvedLineItemIssueRequest = createAction(
  'SET_AS_RESOLVED_LINE_ITEM_ISSUE_REQUEST',
);
export const setAsResolvedLineItemIssue = createAction(
  'SET_AS_RESOLVED_LINE_ITEM_ISSUE',
);

export function resolveLineItemIssue(issueId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(setAsResolvedLineItemIssueRequest({ issueId }));
    try {
      const response = await Api.resolveLineItemIssue(issueId);
      dispatch(setAsResolvedLineItemIssue({ ...response, issueId }));
    } catch (err) {
      logException(err);
      dispatch(setAsResolvedLineItemIssue(err));
    }
  };
}

// Updates the address of one order
export const updateAddressRequest = createAction('UPDATE_ADDRESS_REQUEST');
export const updateAddress = createAction('UPDATE_ADDRESS');

export function editAddress(
  orderNumber: string,
  id: number,
  updatableProperties: UpdatableAddress,
) {
  return async (dispatch: Dispatch) => {
    dispatch(updateAddressRequest());
    try {
      const response = await Api.updateAddress(id, updatableProperties);
      dispatch(updateAddress({ ...response, orderNumber }));
    } catch (err) {
      logException(err);
      dispatch(updateAddress(err));
    }
  };
}

// Updates the observation field of an order
export const updateOrderObservationRequest = createAction(
  'UPDATED_ORDER_OBSERVATION_REQUEST',
);
export const updateOrderObservation = createAction('UPDATED_ORDER_OBSERVATION');

export function editOrderObservation(orderNumber: string, observation: string) {
  return async (dispatch: Dispatch) => {
    dispatch(updateOrderObservationRequest());
    try {
      const response = await Api.updateObservation(orderNumber, observation);
      dispatch(updateOrderObservation(response));
    } catch (err) {
      logException(err);
      dispatch(updateOrderObservation(err));
    }
  };
}

// Get a specific user
export const fetchUserSalesRequest = createAction('FETCH_USER_SALES_REQUEST');
export const fetchUserSales = createAction('FETCH_USER_SALES');

export function findUser(userId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchUserSalesRequest());
    try {
      const response = await Api.getUserSales(userId);
      dispatch(fetchUserSales(response));
    } catch (err) {
      logException(err);
      dispatch(fetchUserSales(err));
    }
  };
}

////////////////////// RELATED TO STATE TRANSITION //////////////
// Transition a fulfillment from pending to preparation_queue
// export const approveFulfillmentRequest = createAction(
//   "APPROVE_FULFILLMENT_REQUEST"
// );
// export const approveFulfillment = createAction("APPROVE_FULFILLMENT");

// export function updateApproveFulfillment(id: string) {
//   return async (dispatch: Dispatch) => {
//     dispatch(approveFulfillmentRequest());
//     try {
//       const response = await Api.approveFulfillment(id);
//       dispatch(approveFulfillment(response));
//     } catch (err) {
//       logException(err);
//       dispatch(approveFulfillment(err));
//     }
//   };
// }

////////////////// SALES XML ///////////////////////////
// Downloads the XML for sales
export const fetchSalesXmlRequest = createAction('FETCH_SALES_XML_REQUEST');
export const fetchSalesXml = createAction('FETCH_SALES_XML');

export function downloadSalesXmlRequest(orderNumber: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchSalesXmlRequest());
    try {
      // If we succeed we should download the file
      const response = await Api.getSalesXml(orderNumber);

      const blob = new Blob([response], { type: 'text/xml' });
      saveAs(blob, `xml_${orderNumber}.xml`);

      dispatch(fetchSalesXml());
    } catch (err) {
      logException(err);
      dispatch(fetchSalesXml(err));
    }
  };
}

export const fetchSalesReceiptRequest = createAction(
  'FETCH_SALES_RECEIPT_REQUEST',
);
export const fetchSalesReceipt = createAction('FETCH_SALES_RECEIPT');

export function downloadSalesReceiptRequest(
  orderNumber: string,
  receiptUrl: string,
) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchSalesReceiptRequest());
    try {
      // If we succeed we should download the file
      const response = await Api.getSalesReceipt(receiptUrl);

      saveAs(response, `${orderNumber}.pdf`);

      dispatch(fetchSalesReceipt());
    } catch (err) {
      logException(err);
      dispatch(fetchSalesReceipt(err));
    }
  };
}
