// @flow
import { schema } from "normalizr";
import { camelizeKeys } from "humps";

export const driver = new schema.Entity(
  "drivers",
  {},
  { processStrategy: (entity) => camelizeKeys(entity) }
);
export const vehicle = new schema.Entity(
  "vehicles",
  {},
  { processStrategy: (entity) => camelizeKeys(entity) }
);
export const location = new schema.Entity(
  "locations",
  {},
  { processStrategy: (entity) => camelizeKeys(entity) }
);
export const address = new schema.Entity(
  "addresses",
  {},
  { processStrategy: (entity) => camelizeKeys(entity) }
);
export const timeWindow = new schema.Entity(
  "timeWindows",
  {},
  { processStrategy: (entity) => camelizeKeys(entity) }
);
export const shippingRate = new schema.Entity(
  "shippingRates",
  {},
  { processStrategy: (entity) => camelizeKeys(entity) }
);
export const task = new schema.Entity(
  "tasks",
  {},
  { processStrategy: (entity) => camelizeKeys(entity) }
);
export const visit = new schema.Entity(
  "visits",
  { tasks: [task], address, timeWindow },
  { processStrategy: (entity) => camelizeKeys(entity) }
);
export const route = new schema.Entity(
  "routes",
  { visits: [visit], driver, vehicle },
  { processStrategy: (entity) => camelizeKeys(entity) }
);
export const virtualRoute = new schema.Entity(
  "virtualRoutes",
  {},
  { processStrategy: (entity) => camelizeKeys(entity) }
);

export const drivers = new schema.Array(driver);
export const vehicles = new schema.Array(vehicle);
export const locations = new schema.Array(location);
export const routes = new schema.Array(route);
export const visits = new schema.Array(visit);
export const virtualRoutes = new schema.Array(virtualRoute);
