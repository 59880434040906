// @flow
import { createSelector } from "reselect";
import _ from "lodash";
// Types
import type { ReduxState } from "../../types";
import type { StoreState } from "../types";

const thisStateSelector = (state: ReduxState): StoreState => {
  return state.fraud;
};

// Get User Map
const userMapSelector = createSelector(
  thisStateSelector,
  state => state.userStats
);

// Get Order Map
const orderMapSelector = createSelector(
  thisStateSelector,
  state => state.orderStats
);

export const userSelector = createSelector(
  thisStateSelector,
  userMapSelector,
  (state, userMap) => {
    return userMap[state.currentUser] || {};
  }
);

// Returns the canceled orders for the current user
export const userCanceledOrdersSelector = createSelector(
  thisStateSelector,
  userSelector,
  (state, user) => {
    if (_.isEmpty(user)) return [];
    return _.filter(user.orders.map(id => state.userOrders[id]), {
      status: "canceled"
    });
  }
);

// Returns the completed orders for the current user
export const userCompletedOrdersSelector = createSelector(
  thisStateSelector,
  userSelector,
  (state, user) => {
    if (_.isEmpty(user)) return [];
    return _.filter(user.orders.map(id => state.userOrders[id]), {
      status: "complete"
    });
  }
);

// Returns the prices for the completed orders of the current user
export const userCompletedOrdersPriceSelector = createSelector(
  thisStateSelector,
  userCompletedOrdersSelector,
  (state, orders) => {
    return orders.map(o => o.value);
  }
);

export const orderSelector = createSelector(
  thisStateSelector,
  orderMapSelector,
  (state, orderMap) => {
    return orderMap[state.currentOrder];
  }
);

// Returns the payments for the current order
export const userOrderPaymentsSelector = createSelector(
  thisStateSelector,
  orderSelector,
  (state, order) => {
    if (_.isEmpty(order)) return [];

    return order.payments.map(id => state.orderPayments[id]);
  }
);

//////////////////// Loading Selectors //////////////////////

export const gettingUserLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingUser
);

export const gettingOrderLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingOrder
);
