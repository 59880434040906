import React, { useEffect } from "react";
import _ from "lodash";
// Components
import { Droppable } from "react-beautiful-dnd";
import VirtualVisitItem from "./VirtualVisitItem";
import { scroller } from "react-scroll";
import ReactTooltip from "react-tooltip";
import { VehicleTypes } from "../constants/VehicleTypes";
// Composers
import { connect } from "react-redux";
//Actions
import * as Actions from "../actions";
//Selectors
import * as Selectors from "../selectors";
//Style
import "./RouteItem.css";
//Helpers
import { quantityConverter } from "../../common/commonHelpers";

const VirtualRouteItem = ({
  virtualRoute,
  color,
  visitById,
  vehicleById,
  selectedVisitId,
  handleRemoveVirtualRoute,
  handleVisitClick,
}) => {
  useEffect(() => {
    if (virtualRoute.visits.findIndex((id) => id === selectedVisitId) > -1) {
      scroller.scrollTo(`visit-scroll-${selectedVisitId}`, {
        duration: 500,
        smooth: "easeInOutQuart",
        containerId: "scrollable-route-list",
        offset: -150,
        isDynamic: true,
      });
    }
  }, [selectedVisitId, virtualRoute]);

  const sortedVisits = _.sortBy(
    virtualRoute.visits.map((visitId) => visitById(visitId)),
    ["position"]
  );

  const vehicle = vehicleById(virtualRoute.vehicleId);

  return (
    <>
      <Droppable droppableId={`virtual-route-list-${virtualRoute.id}`}>
        {(provided) => (
          <div className="route-item" ref={provided.innerRef}>
            <button
              onClick={() => handleRemoveVirtualRoute(virtualRoute.id)}
              className="route-item-edit"
              data-for={`route-page-tooltip-edit-alert-for-route-${virtualRoute.id}`}
              data-tip=""
            >
              <ReactTooltip
                id={`route-page-tooltip-edit-alert-for-route-${virtualRoute.id}`}
              >
                <p>Remover Rota</p>
              </ReactTooltip>

              <i className="fa fa-times" aria-hidden="true"></i>
            </button>

            <div className="route-item-info-line">
              <i className="fa fa-tag" aria-hidden="true"></i>
              <div
                style={{
                  width: "100px",
                  height: "10px",
                  marginLeft: "10px",
                  backgroundColor: `${color}`,
                }}
              />
              <div
                className="route-item-info-line padding"
                data-tip=""
                data-for="route-item-tooltip-info-vehicle"
              >
                <i
                  className={`fa fa-${
                    vehicle.vehicleType &&
                    VehicleTypes[vehicle.vehicleType.slug]
                      ? vehicle.vehicleType.slug
                      : "car"
                  }`}
                  aria-hidden="true"
                ></i>
                <p className="route-item-info-line-p-content">
                  {vehicle.licensePlate}
                </p>
                <ReactTooltip id="route-item-tooltip-info-vehicle">
                  <p>Placa do Veículo</p>
                </ReactTooltip>
              </div>
            </div>
            <div className="route-item-info-line route-item-info-line-space-around">
              <div
                className="route-item-info-line"
                data-tip=""
                data-for="route-item-tooltip-info-estimated-time-at-stop"
              >
                <i className="fa fa-hourglass-half" aria-hidden="true"></i>
                <p className="route-item-info-line-p-content">{`${Math.floor(
                  sortedVisits.reduce(
                    (r, visit) => r + visit.estimatedTimeAtStop,
                    0
                  ) / 60
                )} min`}</p>
                <ReactTooltip id="route-item-tooltip-info-estimated-time-at-stop">
                  <p>Tempo Estimado em Visitas</p>
                </ReactTooltip>
              </div>
              <div
                className="route-item-info-line"
                data-tip=""
                data-for="route-item-tooltip-info-time-to-prepare"
              >
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <p className="route-item-info-line-p-content">{`${sortedVisits.reduce(
                  (r, visit) => r + visit.estimatedPreparationTime,
                  0
                )} min`}</p>
                <ReactTooltip id="route-item-tooltip-info-time-to-prepare">
                  <p>Tempo de Preparação Total</p>
                </ReactTooltip>
              </div>
              <div
                className="route-item-info-line"
                data-tip=""
                data-for="route-item-tooltip-important-weight"
              >
                <i className="fa fa-balance-scale" aria-hidden="true"></i>
                <p className="route-item-info-line-p-content">
                  {quantityConverter(
                    sortedVisits.reduce((r, visit) => r + visit.weight, 0),
                    "g"
                  )}
                </p>
                <ReactTooltip id="route-item-tooltip-important-weight">
                  <p>Peso</p>
                </ReactTooltip>
              </div>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
              {sortedVisits.map((visit, index) => (
                <VirtualVisitItem
                  key={visit.id}
                  index={index}
                  visitId={visit.id}
                  selectedVisitId={selectedVisitId}
                  onVisitClick={() => handleVisitClick([visit.id])}
                />
              ))}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </>
  );
};

const mapStateToProps = (state, props: Props) => {
  return {
    numberOfRoutes: Selectors.routeListSelector(state).length,
    routeLoading: Selectors.routesLoadingSelector(state),
    visitById: (id) => Selectors.visitByIdSelector(state, id),
    vehicleById: (id) => Selectors.vehicleByIdSelector(state, id),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    handleRemoveVirtualRoute: (virtualRouteId) => {
      dispatch(Actions.removeVirtualRoute({ virtualRouteId: virtualRouteId }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualRouteItem);
