import React from "react";

const VisitFailedMapIcon = ({ color }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      viewBox="0 0 640.923 640.923"
      style={{
        enableBackground: "new 0 0 640.923 640.923",
        position: "absolute",
        transform: "translate(-15px, -30px)",
        backgroundOrigin: "initial"
      }}
    >
      <g>
        <g>
          <g>
            <path
              style={{ fill: `${color}` }}
              d="M307.008,146.65h27.073l-3.742,77.604h-19.55L307.008,146.65z M320.53,232.764
				c-9.057,0-15.378,6.36-15.378,15.603c0,8.871,6.136,15.535,15.066,15.535h0.303c9.272,0,15.261-6.673,15.261-15.535
				C335.625,239.125,329.636,232.764,320.53,232.764z M522.381,201.92c0,111.517-201.92,439.003-201.92,439.003
				s-201.92-327.476-201.92-439.003C118.541,90.433,208.954,0,320.461,0C431.949,0.01,522.381,90.433,522.381,201.92z
				 M476.637,201.92c0-86.153-70.052-156.215-156.176-156.215c-86.144,0-156.235,70.062-156.235,156.215
				c0,86.095,70.091,156.235,156.235,156.235C406.585,358.155,476.637,288.024,476.637,201.92z M439.765,289.646
				c-2.804,4.66-7.845,7.484-13.268,7.484H214.436c-5.598,0-10.767-2.98-13.502-7.865c-2.755-4.856-2.697-10.816,0.176-15.603
				l106.378-188.75c2.814-4.719,7.875-7.582,13.356-7.582l0.371,0.02c5.628,0.098,10.747,3.322,13.336,8.295l105.694,188.769
				C442.774,279.212,442.569,285.005,439.765,289.646z M426.507,281.625L320.833,92.856L214.436,281.625
				C214.436,281.625,426.507,281.625,426.507,281.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default VisitFailedMapIcon;
