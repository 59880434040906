import React, { Component } from "react";
// Styles
import "./MapMarker.css";

export default class MapMarker extends Component {
  static defaultProps = {};

  render() {
    return <div className="map-marker">{this.props.text}</div>;
  }
}
