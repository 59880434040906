import React from "react";

const DistributionCenterMapIcon = ({ color }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      viewBox="0 0 490 490"
      style={{
        enableBackground: "new 0 0 490 490",
        position: "absolute",
        transform: "translate(-15px, -30px)",
        backgroundOrigin: "initial"
      }}
    >
      <g>
        <g>
          <path
            d="M245,0C150.5,0,73.5,76.6,73.5,171.5C73.5,266,245,490,245,490s171.5-224,171.5-318.4C416.5,76.6,339.5,0,245,0z
			 M245,274.5c-56.8,0-103-46.3-103-103s46.3-103,103-103s103,46.3,103,103S301.8,274.5,245,274.5z"
          />
          <path
            d="M308,182.8h-17.5v-49.4c0-5.4-4.3-9.7-9.7-9.7c-5.4,0-9.7,4.3-9.7,9.7v49.4h-15.6v-65.3c0-5.4-4.3-9.7-9.7-9.7
			c-5.4,0-9.7,4.3-9.7,9.7v65.3h-15.6v-36.5c0-5.4-4.3-9.7-9.7-9.7s-9.7,4.3-9.7,9.7v36.5h-17.5c-5.4,0-9.7,4.3-9.7,9.7
			s4.3,9.7,9.7,9.7h125.6c5.4,0,9.7-4.3,9.7-9.7C317.7,187.1,313.4,182.8,308,182.8z"
          />
        </g>
      </g>
    </svg>
  );
};

export default DistributionCenterMapIcon;
