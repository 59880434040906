// @flow
import React from "react";
//Styles
import "./LinePlot.css";

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid
} from "recharts";

type Props = {
  data: {}[],
  color: string[],
  showToolTipText: boolean,
  yFormatter: (tickItem: any) => void,
  xFormatter: (tickItem: any) => void
};

class LinePlot extends React.Component {
  props: Props;

  render() {
    let xName = "";
    let yName = [];

    // If there is no data to display
    if (this.props.data.length === 0) {
      return (
        <div className="line-plot-no-data">
          <p>Sem dados para gerar gráfico. </p>
        </div>
      );
    }

    // This allows for multiple series to be plotted on the same graph
    // Assumes first property of object is the name of X-axis data
    // And all subsequent properties are the multiple y-series
    if (this.props.data) {
      let count = 0;
      for (let key in this.props.data[0]) {
        if (this.props.data[0].hasOwnProperty(key)) {
          if (count === 0) {
            xName = key;
          } else {
            yName.push([key, this.props.color[count - 1]]);
          }

          count += 1;
        }
      }
    }

    let toolTipText = "";
    if (!this.props.showToolTipText) {
      toolTipText = "hide-tool-tip-text";
    }

    // The size of the LinePlot is determined by the outer parent container, not in this class
    return (
      <div className={`line-chart-main ${toolTipText}`}>
        <ResponsiveContainer>
          <LineChart
            data={this.props.data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={xName}
              tick={{ textAnchor: "end", angle: -20 }}
              tickFormatter={this.props.xFormatter}
            />
            <YAxis
              domain={[0, "dataMax"]}
              tickFormatter={this.props.yFormatter}
            />
            <Tooltip
              // Remove the name from the payload so it does not show up in tooltip
              formatter={value => this.props.yFormatter(value)}
              labelFormatter={this.props.xFormatter}
            />

            {yName.map((o, i) => (
              <Line
                key={i}
                type="monotone"
                dataKey={o[0]}
                stroke={o[1]}
                dot={false}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default LinePlot;
