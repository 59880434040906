// @flow
import React from 'react';
import './PrintSaleReceiptIcon.css';

const PrintSaleReceiptIcon = props => {
  return props.shouldPrintSaleReceipt ? (
    <div className="print-sale-receipt">
      <i
        className="fa fa-print"
        title="A nota fiscal do cliente deve ser impressa."
      />
    </div>
  ) : null;
};

export default PrintSaleReceiptIcon;
