import React from 'react';
import { InputFieldLabel, Button } from '../../common';
import './ReviewAddressForm.css';
import { useForm } from 'react-hook-form';

function ReviewAddressForm({
  hasPendingReview,
  loading: addressLoading,
  onRequestNewReview,
  isExistentAddress,
}) {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      review_observation: '',
    },
  });

  function handleRequestReview() {
    const { review_observation } = getValues();
    onRequestNewReview(review_observation);
  }

  return (
    <div className="review-item-address-wrapper">
      <div className="review-item-address-header">
        <p className={'visit-item-main-editor-subtitle'}>Revisão de endereço</p>
      </div>

      {hasPendingReview ? (
        <p className="review-item-body-text">
          Uma soliticação de revisão já está em andamento
        </p>
      ) : (
        <form onSubmit={handleSubmit(handleRequestReview)}>
          <div className="address-form-line address-form-line--labelled">
            <InputFieldLabel htmlFor="review_observation">
              Observações sobre o endereço
            </InputFieldLabel>

            <textarea
              className="review-item-address-textarea"
              id="review_observation"
              type="text"
              placeholder="Endereço contém poucas informações..."
              required
              {...register('review_observation', { required: true })}
            />
          </div>

          <Button
            type="submit"
            buttonType="button-primary review-item-address-button-submit"
            disabled={addressLoading || !isValid || !isExistentAddress}
            loading={addressLoading}
            title="Enviar solicitação"
            text="Solicitar revisão"
          ></Button>
        </form>
      )}
    </div>
  );
}

export { ReviewAddressForm };
