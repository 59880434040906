import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// Styles
import "./PeriodicGetter.css";

const PeriodicGetter = ({
  loading,
  countdownStartValue,
  handleGet,
  invisible,
}) => {
  const defaultCountdownStartValue = 60;
  const [count, setCount] = useState(
    countdownStartValue || defaultCountdownStartValue
  );
  let timer = null;

  const handleTimeout = () => {
    if (count === 0) {
      handleGet();
      setCount(countdownStartValue || defaultCountdownStartValue);
    } else {
      setCount(count - 1);
    }
  };

  // We should make the first request as soon as this component is first mounted
  useEffect(() => {
    handleGet();
  }, []);

  // While loading we should have no timer. Otherwise, decrement counter every 1 second and fire request at 0. Restart.
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    if (!loading) {
      timer = setTimeout(() => handleTimeout(), 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [loading, count]);

  return (
    <>
      {invisible ? (
        ""
      ) : (
        <div className="periodic-getter-phrase">
          {loading ? `Atualizando...` : `Atualizando em ${count} s`}
        </div>
      )}
    </>
  );
};

PeriodicGetter.propTypes = {
  loading: PropTypes.bool,
  countdownStartValue: PropTypes.number,
  handleGet: PropTypes.func.isRequired,
};

export default PeriodicGetter;
